import * as PIXI from 'pixi.js';
import { ApplicationStore } from '~/flux/stores';
import { ObjectPool } from '~/Tools';

const TILE_WIDTH = 15;
const TILE_HEIGHT = TILE_WIDTH * 1.61803398875; // golden ratio
const STYLE = {
  fontName: 'BitmapCourier',
  fontSize: 64,
};

let txt;
let _txtPool;
ApplicationStore.once(ApplicationStore.ASSETS_LOADED, () => {
  _txtPool = new ObjectPool(
    // create func
    () => {
      txt = new PIXI.BitmapText('.', { ...STYLE });
      txt.tint = 0x574427;
      txt.anchor.x = txt.anchor.y = 0.5;
      txt.interactive = false;
      txt.interactiveChildren = false;
      txt.visible = true;
      txt.alpha = 1;
      return txt;
    },

    // reset func
    (txt) => {
      gsap.killTweensOf(txt);
      txt.parent?.removeChild(txt);
      txt.x = 0;
      txt.y = 0;
      txt.visible = true;
      txt.alpha = 1;
      txt.text = '.';
      txt.scale = { x: 1, y: 1 };
      txt.tint = 0x574427;
      txt.anchor.x = txt.anchor.y = 0.5;
      txt.interactive = false;
      txt.interactiveChildren = false;
      txt.buttonMode = false;
      txt.mouseover = txt.mouseout = txt.touch = null;
      txt.hitArea = null;
    },

    // starting num
    350
  );
});

const CavernsTileSprite = function (gamePiece) {
  PIXI.Container.call(this);

  this.gamePiece = gamePiece;

  this.tileImg = _txtPool.get();
  this.txtPool = _txtPool;
  this.addChildAt(this.tileImg, 0);

  const getStageX = (board_x) =>
    Math.round(board_x * TILE_WIDTH * 1.5 + window.innerWidth * 0.019);
  const getStageY = (board_y) =>
    Math.round(board_y * TILE_HEIGHT * 0.75 + DT_CANVAS_GLOBALS.spacing * 3);

  this.snapToBoardPosition = (board_x, board_y) => {
    this.x = getStageX(board_x);
    this.y = getStageY(board_y);
  };

  this.tweenToBoardPosition = (board_x, board_y, duration) => {
    gsap.killTweensOf(this);
    TweenMax.to(this, duration, {
      x: getStageX(board_x),
      y: getStageY(board_y),
      ease: Linear.easeNone,
    });
  };

  this.dispose = () => {
    if (this.disposed) {
      return;
    }
    gsap.killTweensOf(this);
    gamePiece = null;

    if (this.lootImg) {
      _txtPool.put(this.lootImg);
      this.lootImg = null;
    }

    _txtPool.put(this.tileImg);

    this.removeChildren();
    this.parent?.removeChild(this);

    typeof this.disposeAsUnitSprite == 'function' && this.disposeAsUnitSprite();

    this.disposed = true;
  };
};
CavernsTileSprite.prototype = Object.create(PIXI.Container.prototype);
CavernsTileSprite.prototype.constructor = CavernsTileSprite;
export default CavernsTileSprite;
