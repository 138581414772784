import * as PIXI from 'pixi.js';
import { GlowFilter } from 'pixi-filters';
import { Colors } from '~/constants';
import { TutorialActions } from '~/flux/actions';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const _glow_filter = new GlowFilter({
  distance: 30,
  outerStrength: 8,
  color: 0x222222,
});

const TutorialBox = function (
  message_text,
  arrowDirection = 'none',
  maxWidth = 350,
  onOkClick
) {
  PIXI.Container.call(this);

  let _ok_button;
  let _blink_interval;
  let _shopKeeper;

  this.dispose = () => {
    const overlay_canvas = document.getElementById('overlay_canvas');
    overlay_canvas.style.pointerEvents = 'none';
    DT_CANVAS_GLOBALS.overlay_stage.interactive =
      DT_CANVAS_GLOBALS.overlay_stage.interactiveChildren = false;

    clearInterval(_blink_interval);
    if (_ok_button) {
      _ok_button.tap = _ok_button.click = null;
    }
    _shopKeeper?.destroy();
    this.destroy();
  };

  this.body = new PIXI.Text(message_text, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(18),
    fill: Colors.BRIGHT_YELLOW,
    wordWrap: true,
    wordWrapWidth: maxWidth,
  });

  this._width = this.body.width + 17;
  this._height = this.body.height + 17;

  const _back_fill = CanvasTools.addBackFill(this, Colors.MP_BLUE);
  _back_fill.filters = [_glow_filter];
  CanvasTools.addBorder(
    this,
    'chat_border_horiz.png',
    'chat_border_vert.png',
    'chat_border_horiz.png',
    'chat_border_vert.png',
    0
  );

  this.body.x = Math.round(this._width / 2 - this.body.width / 2);
  this.body.y = Math.round(this._height / 2 - this.body.height / 2);
  this.addChild(this.body);

  if (arrowDirection === 'none') {
    this.pivot = {
      x: Math.round(this._width / 2),
      y: Math.round(this._height / 2),
    };
  } else {
    const arrowImg = new PIXI.Text('', {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(16),
      fill: Colors.BRIGHT_YELLOW,
      fontStyle: 'bold',
    });

    switch (arrowDirection) {
      case 'up':
        arrowImg.text = '^\n' + '|\n' + '|\n' + '|';
        arrowImg.x = Math.round(this._width / 2 - arrowImg.width / 2);
        arrowImg.y = Math.round(-arrowImg.height);
        this.pivot = {
          x: Math.round(this._width / 2),
          y: Math.round(arrowImg.y),
        };
        break;

      case 'down':
        arrowImg.text = '|\n' + '|\n' + '|\n' + 'v\n';
        arrowImg.x = Math.round(this._width / 2 - arrowImg.width / 2);
        arrowImg.y = Math.round(this._height);
        this.pivot = {
          x: Math.round(this._width / 2),
          y: Math.round(arrowImg.y + arrowImg.height),
        };
        break;

      case 'left':
        arrowImg.text = '<---';
        arrowImg.x = Math.round(-arrowImg.width);
        arrowImg.y = Math.round(this._height / 2 - arrowImg.height / 2);
        this.pivot = {
          x: Math.round(arrowImg.x - arrowImg.width),
          y: Math.round(this._height / 2),
        };
        break;

      case 'right':
        arrowImg.text = '--->';
        arrowImg.x = Math.round(this._width);
        arrowImg.y = Math.round(this._height / 2 - arrowImg.height / 2);
        this.pivot = {
          x: Math.round(arrowImg.x + arrowImg.width),
          y: Math.round(this._height / 2),
        };
        break;
    }

    // arrow image blinks
    _blink_interval = setInterval(() => {
      arrowImg.visible = !arrowImg.visible;
    }, 700);

    this.addChild(arrowImg);
  }

  // potentially add OK button
  if (onOkClick) {
    // make the overlay stage interactive when there is tutorial box with an OK button
    const overlay_canvas = document.getElementById('overlay_canvas');
    overlay_canvas.style.pointerEvents = 'auto';
    DT_CANVAS_GLOBALS.overlay_stage.interactive =
      DT_CANVAS_GLOBALS.overlay_stage.interactiveChildren = true;

    // create the button
    _ok_button = new PIXI.Text(`[${text('ui.OK')}]`, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(20),
      fill: 0xffffff,
    });
    _ok_button.x = 27;
    _ok_button.y = Math.round(this._height + 4);
    this.addChild(_ok_button);

    // on click, clean up and invoke the callback
    _ok_button.interactive = _ok_button.buttonMode = true;
    _ok_button.tap = _ok_button.click = () => {
      overlay_canvas.style.pointerEvents = 'none';
      DT_CANVAS_GLOBALS.overlay_stage.interactive =
        DT_CANVAS_GLOBALS.overlay_stage.interactiveChildren = false;
      TutorialActions.destroyTutorialBox();
      TutorialActions.destroyTutorialSpotlight();
      onOkClick();
    };
  }

  if (arrowDirection !== 'up') {
    // render the Shopkeeper portrait
    _shopKeeper = new PIXI.Sprite();
    _shopKeeper.texture =
      PIXI.utils.TextureCache['shop/shopkeeper_portrait.png'];
    _shopKeeper.height = 120;
    _shopKeeper.scale.x = _shopKeeper.scale.y;
    _shopKeeper.x = this._width - _shopKeeper.width - 10;
    _shopKeeper.y = -123;
    this.addChildAt(_shopKeeper, 0);

    _shopKeeper.filters = [_glow_filter];
  }
};
TutorialBox.prototype = Object.create(PIXI.Container.prototype);
TutorialBox.prototype.constructor = TutorialBox;
export default TutorialBox;
