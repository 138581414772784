import * as PIXI from 'pixi.js';
import Modal from '~/components/common/Modal';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';
import BattleActions from '~/flux/actions/BattleActions';
import Config from '~/constants/Config';

const ResignBattleDialog = function (hero) {
  Modal.call(this, window.innerWidth * 0.65, window.innerHeight * 0.25);

  this.disposeChildClass = () => {
    if (_yesBtn) {
      _yesBtn.tap = _yesBtn.click = null;
      _yesBtn = null;
    }

    if (_noBtn) {
      _noBtn.tap = _noBtn.click = null;
      _noBtn = null;
    }
  };

  this.setTitle(text('battle.resign_battle_question'));

  var _yesBtn = new PIXI.Container();
  _yesBtn.width = this._width * 0.15;
  _yesBtn.height = _yesBtn._width / 2;
  CanvasTools.addBackFill(_yesBtn, Colors.ALMOST_BLACK);
  CanvasTools.addBorder(
    _yesBtn,
    'chat_border_horiz.png',
    'chat_border_vert.png',
    'chat_border_horiz.png',
    'chat_border_vert.png',
    0
  );
  var fontSize = (16 * (window.innerWidth / 1000)).toString() + 'px';
  var yesTxt = new PIXI.Text(text('ui.yes'), {
    fontFamily: 'Courier New',
    fontSize,
    fontWeight: 'bold',
    fill: Colors.GREEN,
  });
  yesTxt.x = Math.round(_yesBtn._width / 2 - yesTxt.width / 2);
  yesTxt.y = Math.round(_yesBtn._height / 2 - yesTxt.height / 2);
  _yesBtn.addChild(yesTxt);
  _yesBtn.x = Math.round(this._width / 2 - _yesBtn._width - this.PADDING);
  _yesBtn.y = Math.round(this._height - _yesBtn._height - this.PADDING);
  this.addChild(_yesBtn);
  _yesBtn.interactive = _yesBtn.buttonMode = true;
  _yesBtn.tap = _yesBtn.click = () => {
    BattleActions.killBattle();
    this.transitionOut(this.dispose);
  };

  var _noBtn = new PIXI.Container();
  _noBtn.width = this._width * 0.15;
  _noBtn.height = _noBtn._width / 2;
  CanvasTools.addBackFill(_noBtn, Colors.ALMOST_BLACK);
  CanvasTools.addBorder(
    _noBtn,
    'chat_border_horiz.png',
    'chat_border_vert.png',
    'chat_border_horiz.png',
    'chat_border_vert.png',
    0
  );
  var noTxt = new PIXI.Text(text('ui.no'), {
    fontFamily: 'Courier New',
    fontSize,
    fontWeight: 'bold',
    fill: Colors.RED,
  });
  noTxt.x = Math.round(_noBtn._width / 2 - noTxt.width / 2);
  noTxt.y = Math.round(_noBtn._height / 2 - noTxt.height / 2);
  _noBtn.addChild(noTxt);
  _noBtn.x = Math.round(this._width / 2 + this.PADDING);
  _noBtn.y = _yesBtn.y;
  this.addChild(_noBtn);
  _noBtn.interactive = _noBtn.buttonMode = true;
  _noBtn.tap = _noBtn.click = () => {
    this.transitionOut(this.dispose);
  };
};
ResignBattleDialog.prototype = Object.create(Modal.prototype);
ResignBattleDialog.prototype.constructor = ResignBattleDialog;
export default ResignBattleDialog;
