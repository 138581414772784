<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black"
      >
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.leaderboard') }}
        </DialogTitle>

        <div class="w-full py-6 px-6 flex flex-col">
          <!-- game mode selections -->
          <div class="w-full flex justify-center">
            <a
              :class="{
                'cursor-pointer': !state.loading,
                'text-neutral-500':
                  state.selected_game_mode !== GAME_MODE_pvpLive,
                'text-green-500':
                  state.selected_game_mode === GAME_MODE_pvpLive,
              }"
              @click="onGameModeClick(GAME_MODE_pvpLive)"
            >
              {{ text('ui.ARENA') }}
            </a>
            <div class="mx-4">|</div>
            <a
              :class="{
                'cursor-pointer': !state.loading,
                'text-neutral-500':
                  state.selected_game_mode !== GAME_MODE_caverns,
                'text-green-500':
                  state.selected_game_mode === GAME_MODE_caverns,
              }"
              @click="onGameModeClick(GAME_MODE_caverns)"
            >
              {{ text('ui.CAVERNS') }}
            </a>
          </div>
          <!-- game submode selections -->
          <div class="w-full flex justify-center">
            <a
              v-if="state.selected_game_mode === GAME_MODE_pvpLive"
              v-for="submode of ARENA_SUBMODES"
              :class="{
                'mx-2 text-xs': true,
                'cursor-pointer': !state.loading,
                'text-neutral-500': state.selected_game_submode !== submode,
                'text-green-500': state.selected_game_submode === submode,
              }"
              @click="onGameSubmodeClick(submode)"
            >
              {{ text(`ui.game_submodes.${submode}`) }}
            </a>
            <a
              v-else-if="state.selected_game_mode === GAME_MODE_caverns"
              v-for="submode of CAVERNS_SUBMODES"
              :class="{
                'mx-2 text-xs': true,
                'cursor-pointer': !state.loading,
                'text-neutral-500': state.selected_game_submode !== submode,
                'text-green-500': state.selected_game_submode === submode,
              }"
              @click="onGameSubmodeClick(submode)"
            >
              {{ text(`ui.game_submodes.${submode}`) }}
            </a>
          </div>
          <hr class="my-3 border border-zinc-700" />
          <div
            v-if="state.loading"
            class="w-full h-[70vh] flex justify-center items-center"
          >
            <DTSpinner />
          </div>
          <div
            v-else
            class="h-[70vh] overflow-y-auto"
          >
            <div
              v-if="state.selected_game_mode === GAME_MODE_pvpLive"
              class="w-full"
            >
              <div class="w-full flex">
                <div class="w-[60px]">
                  <!-- Rank -->
                </div>
                <div class="w-[220px]">
                  {{ text('ui.name') }}
                </div>
                <div>
                  {{ text('ui.rating') }}
                </div>
              </div>
              <ArenaLeaderboardEntry
                v-for="(entry, i) of state.leaderboard_entries"
                :displayName="entry.displayName"
                :arena_submode_data="
                  entry.competition_profiles[state.selected_game_submode]
                "
                :rank="i + 1"
              />
            </div>
            <div
              v-if="state.selected_game_mode === GAME_MODE_caverns"
              class="w-full"
            >
              <div class="w-full flex">
                <div class="w-[60px]">
                  <!-- Rank -->
                </div>
                <div class="w-[220px]">{{ text('ui.name') }}</div>
                <div>{{ text('ui.depth') }}</div>
              </div>
              <CavernsLeaderboardEntry
                v-for="(entry, i) of state.leaderboard_entries"
                :displayName="entry.displayName"
                :caverns_submode_data="
                  entry.caverns_data[state.selected_game_submode]
                "
                :rank="i + 1"
              />
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import { onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import Game from 'dt-common/constants/Game';
import { LeaderboardActions } from '~/flux/actions';
import { LeaderboardStore, UIStore } from '~/flux/stores';
import text from '~/text';
import { DTSpinner } from '~/view/components/common/DOM';
import ArenaLeaderboardEntry from './ArenaLeaderboardEntry.vue';
import CavernsLeaderboardEntry from './CavernsLeaderboardEntry.vue';

const {
  GAME_MODES: { GAME_MODE_pvpLive, GAME_MODE_caverns },
  GAME_SUBMODES,
} = Game;
const ARENA_SUBMODES = [
  GAME_SUBMODES.GAME_SUBMODE_pvp1v1,
  GAME_SUBMODES.GAME_SUBMODE_pvp2v2,
  GAME_SUBMODES.GAME_SUBMODE_pvp3v3,
  GAME_SUBMODES.GAME_SUBMODE_pvp5v5,
  GAME_SUBMODES.GAME_SUBMODE_pvp7v7,
];
const CAVERNS_SUBMODES = [
  GAME_SUBMODES.GAME_SUBMODE_caverns1man,
  GAME_SUBMODES.GAME_SUBMODE_caverns2man,
  GAME_SUBMODES.GAME_SUBMODE_caverns3man,
  GAME_SUBMODES.GAME_SUBMODE_caverns5man,
  GAME_SUBMODES.GAME_SUBMODE_caverns7man,
];

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  leaderboard_entries: [],
  loading: true,
  selected_game_mode:
    UIStore.getAll().current_game_mode === GAME_MODE_caverns
      ? GAME_MODE_caverns
      : GAME_MODE_pvpLive,
  selected_game_submode: ARENA_SUBMODES.concat(CAVERNS_SUBMODES).includes(
    UIStore.getAll().current_game_submode
  )
    ? UIStore.getAll().current_game_submode
    : UIStore.getAll().current_game_mode === GAME_MODE_caverns
      ? GAME_SUBMODES.GAME_SUBMODE_caverns1man
      : GAME_SUBMODES.GAME_SUBMODE_pvp1v1,
});

onBeforeMount(() => {
  LeaderboardStore.on(LeaderboardStore.GOT_LEADERBOARD, onGotLeaderboard);
  LeaderboardActions.getLeaderboard({
    game_mode: state.selected_game_mode,
    game_submode: state.selected_game_submode,
  });
});

onBeforeUnmount(() => {
  LeaderboardStore.removeListener(
    LeaderboardStore.GOT_LEADERBOARD,
    onGotLeaderboard
  );
  state.loading = false;
});

function onGameModeClick(game_mode) {
  if (state.loading) {
    return;
  }

  if (state.selected_game_mode !== game_mode) {
    if (state.selected_game_mode === GAME_MODE_pvpLive) {
      const submode_index = ARENA_SUBMODES.findIndex(
        (ele) => ele === state.selected_game_submode
      );
      state.selected_game_submode = CAVERNS_SUBMODES[submode_index];
    } else if (state.selected_game_mode === GAME_MODE_caverns) {
      const submode_index = CAVERNS_SUBMODES.findIndex(
        (ele) => ele === state.selected_game_submode
      );
      state.selected_game_submode = ARENA_SUBMODES[submode_index];
    } else {
      throw new Error(
        `invalid state.selected_game_mode: ${state.selected_game_mode}`
      );
    }
    state.selected_game_mode = game_mode;

    state.loading = true;
    LeaderboardActions.getLeaderboard({
      game_mode: state.selected_game_mode,
      game_submode: state.selected_game_submode,
    });
  }
}

function onGameSubmodeClick(game_submode) {
  if (state.loading) {
    return;
  }

  if (state.selected_game_submode !== game_submode) {
    state.selected_game_submode = game_submode;

    state.loading = true;
    LeaderboardActions.getLeaderboard({
      game_mode: state.selected_game_mode,
      game_submode: state.selected_game_submode,
    });
  }
}

function onGotLeaderboard(data) {
  state.leaderboard_entries = data.entries;
  state.loading = false;
}
</script>
