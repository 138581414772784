export default {
  tip_for_beginners: 'TIP FOR BEGINNERS',
  coming_soon: 'COMING SOON',
  ARENA_LOBBY: {
    title: 'Live PvP Arena',
    p1: "It's time to test your mettle in real-time, player-controlled tactical combat.",
    p2: 'Press the "Fight" button to enter the queue and be matched with an opponent near your rating.',
    coming_soon_1: 'Inspect opponent squad & equipment.',
    coming_soon_2: 'Challenge specific players by name',
    coming_soon_3:
      'Earn rewards for killing enemy heroes even if you lost the match.',
  },
  CAVERNS: {
    title: 'The Caverns of ChAoS1',
    p1: 'This is the place to get your grind on. Try to get as deep as possible without wiping.',
    p2: "The quality of XP pots is determined by your max depth per submode. Their duration is based on how long it's been since last claimed.",
    p3: "Don't try to 1-man caverns early on. You will get hurt.",
    legend: {
      title: 'LEGEND',
      '>': 'Down',
      '<': 'Up',
      '/': 'Door',
      Ω: 'Portal - save your current depth & return here later',
      equipment_drops: 'Equipment',
      pile_of_loot: 'Pile of loot',
    },
    loot_is_auto_gathered:
      'Loot is auto-gathered when you click a door, stairs, or return to surface.',
    game_runs_in_bg:
      'HINT: You can change tabs or minimize, and the game will continue to run in the background.',
  },
  EDIT_HERO: {
    title: 'Hero Editing Explained ...',
    equipment: {
      title: 'EQUIPMENT',
      p1: "Hero builds can only be equipped from that hero's inventory.",
      p2: 'To use an XP potion, drag it to the slot at top-center of the screen',
    },
    ai_settings: {
      title: 'AI SETTINGS',
      dps_close:
        'Deals damage with close-range abilities like slash, roundhouse, burning hands, lightning, and of course standard melee attacks.',
      dps_ranged:
        'Deals damage with ranged attacks such as Arrow Shot, Razor Shot, Void Spike, and Infect Mind. Will employ AoE abilities like Molotov Cocktail & Multi-shot against clusters of enemies.',
      interference:
        'Applies crowd-control effects like slow, fear, and enraged to as many enemies as possible.',
      tank: 'Tries to get between enemies and squishy allies. Makes heavy use of Taunt.',
      support: 'Heals, buffs, and summons.',
    },
    affinities:
      'Affinities will give your hero bonus damage and/or damage resistence against specific hero classes or monster types.',
    beginner_tip:
      "Don't forget to equip the abilities you want your hero to use :)",
  },
  INN: {
    title: 'Welcome to the Inn!',
    p1: "Here you'll compete in asynchronous ai-controlled battles. You've already been assigned to a 24-hour tournament, with up to 99 other players all competing for the top ranks!",
    p2: "You'll gain peanuts per second (PPS) based on your rank.",
    p3: "Defeat other players' squads to swap ranks with them, and also steal 10% of their current peanut stash.",
    p4: "If you gain enough peanuts within the 24-hour time limit, you'll be promoted to the next tier. Otherwise maintain your tier, or possibly even be relegated down 1 tier - oh noes!",
    p5: 'Unlock 3 heroes right away so you can compete in multiple Inn submodes simultaneously.',
  },
  HERO_BUILDS: {
    title: 'About the Armory',
    p1: 'Here you can unlock new hero classes, and manage the builds created from each hero class.',
    p2: 'Up to 7 builds per class are allowed, and you can employ different builds for every single game mode/submode.',
    p3: 'For example, you might have a healing-specialized Ranger for Arena 3v3; and an archery-specialized Ranger for Inn 5v5. The possibilities are endless!',
    p4: 'Unlock 3 heroes right away and send them into the Caverns to level up a bit. 1-man game modes at low levels are pretty rough ...',
    unlock_costs: 'Hero unlock costs are:',
  },
  SHOP: {
    title: 'About the Shop',
    p1: 'Pretty straightforward. Buy stuff, sell stuff.',
    p2: 'The shelves are restocked every 30 minutes.',
  },
  TEMPLE: {
    title: 'Shhhhh ~',
    p1a: 'The pillar of light shows your alignment.',
    p1b: 'The orbs at the center of each altar show your favor with that god.',
    p2a: 'Item accuracy is determined by favor.',
    p2b: 'Item quality is determined by gold sacrifice.',
    p2c: 'Prefix quality is determined by favor.',
    p2d: 'Suffix quality is determined by alignment and gold sacrifice.',
    p3a: 'Prayer time is determined by gold sacrifice, favor, and prayer candles.',
    p3b: 'Favor increase is determined by gold sacrifice and alignment.',
    p3c: 'Alignment change is determined by gold sacrifice.',
    p4: 'Achieve 90% favor with all three gods to level up the temple.',
  },
};
