import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';
import text from '~/text';

const wordWrapWidth = CanvasTools.TOOLTIP_WORD_WRAP_WIDTH;

const SecondaryAttributeTooltip = function (attribute) {
  try {
    PIXI.Container.call(this);

    const { handle, value } = attribute;

    // generate title text, e.g. "Melee Damage - 67"
    let value_text;
    switch (attribute.handle) {
      case 'meleeDamage':
      case 'armorClass':
      case 'max_hp':
      case 'max_mp':
      case 'max_ap':
        value_text = value.toFixed(0);
        break;
      default:
        value_text = value.toFixed(2);
        break;
    }
    if (attribute.handle === 'dodgeChance') {
      value_text += '%';
    }
    const title_text = text(`attributes.${handle}.name`) + ' - ' + value_text;

    // add the title sprite
    const _title = new PIXI.Text(title_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(18),
      fontWeight: 'bold',
      fill: Colors.BRIGHT_YELLOW,
      wordWrap: true,
      wordWrapWidth,
    });
    this.addChild(_title);

    // add the blurb sprite
    const _blurb = new PIXI.Text(text(`attributes.${handle}.blurb`), {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      fontStyle: 'italic',
      fill: 0xbababa,
      wordWrap: true,
      wordWrapWidth,
    });
    _blurb.y = Math.round(
      _title.y + _title.height + DT_CANVAS_GLOBALS.spacing / 2
    );
    this.addChild(_blurb);

    // horizontal line under the blurb
    const hl = new PIXI.Graphics();
    hl.beginFill(Colors.BRIGHT_YELLOW, 0.2);
    hl.drawRect(0, 0, this.width, 2);
    hl.y = Math.round(_blurb.y + _blurb.height + 12);
    this.addChild(hl);

    // text showing how the secondary attribute is calculated
    const calc_text = text(`attributes.${handle}.calc`);
    if (calc_text) {
      const _calc_field = new PIXI.Text(calc_text, {
        fontFamily: 'Courier New',
        fontSize: CanvasTools.dynamicFontSizeString(14),
        lineHeight: window.innerWidth > 800 ? 13 : 9,
        fill: 0xffffff,
        padding: 2,
      });
      _calc_field.y = Math.round(hl.y + 14);
      this.addChild(_calc_field);
    }

    this.dispose = () => {
      this.removeChildren();
    };
  } catch (err) {
    logError(err, {
      module: 'components/tooltips/SecondaryAttributeTooltip',
      func: 'constructor',
      attribute,
    });
  }
};
SecondaryAttributeTooltip.prototype = Object.create(PIXI.Container.prototype);
SecondaryAttributeTooltip.prototype.constructor = SecondaryAttributeTooltip;
export default SecondaryAttributeTooltip;
