import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';

const wordWrapWidth = CanvasTools.TOOLTIP_WORD_WRAP_WIDTH;

const UITooltip = function ({ title_text, body_text }) {
  PIXI.Container.call(this);

  // add the title sprite
  let _title_view;
  if (title_text) {
    _title_view = new PIXI.Text(title_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(18),
      fontWeight: 'bold',
      fill: Colors.BRIGHT_YELLOW,
      wordWrap: true,
      wordWrapWidth,
    });
    this.addChild(_title_view);
  }

  // add the blurb sprite
  let _body_view;
  if (body_text) {
    _body_view = new PIXI.Text(body_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      fontStyle: 'italic',
      fill: 0xbababa,
      wordWrap: true,
      wordWrapWidth,
    });
    const _title_bottom = _title_view
      ? _title_view.y + _title_view.height + DT_CANVAS_GLOBALS.spacing / 2
      : 0;
    _body_view.y = Math.round(_title_bottom);
    this.addChild(_body_view);
  }

  this.dispose = () => {
    this.removeChildren();
    this.parent?.removeChild(this);
  };
};
UITooltip.prototype = Object.create(PIXI.Container.prototype);
UITooltip.prototype.constructor = UITooltip;
export default UITooltip;
