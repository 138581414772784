import { EventEmitter } from 'events';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import {
  getFingerprint,
  setOption as setFingerprintOption,
} from '@thumbmarkjs/thumbmarkjs';
import { v4 as uuidv4 } from 'uuid';
import waitFor from 'dt-common/isomorphic-helpers/waitFor';
import { Config } from '~/constants';
import AccountDispatcher from '~/flux/dispatchers/AccountDispatcher';
import text from '~/text';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';

let browser_id;
// exclude frequently changing browser fingerprint params
setFingerprintOption('exclude', [
  'plugins',
  'system.applePayVersion',
  'system.browser.version',
  'system.useragent',
]);
(async () => {
  browser_id = await getFingerprint();
})();

let _id_token;
let _socket;
let _userId;

const AccountStore = Object.assign({}, EventEmitter.prototype, {
  AUTHENTICATED: 'AUTHENTICATED',
  CRAZY_GAMES_AUTH_NOT_LOGGED_IN: 'CRAZY_GAMES_AUTH_NOT_LOGGED_IN',
  HIDE_KONG_REGISTRATION_BTN: 'HIDE_KONG_REGISTRATION_BTN',
  KONGREGATE_AUTH_LOGGED_IN: 'KONGREGATE_AUTH_LOGGED_IN',
  KONGREGATE_AUTH_NOT_LOGGED_IN: 'KONGREGATE_AUTH_NOT_LOGGED_IN',
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  FIREBASE_AUTH_NOT_LOGGED_IN: 'FIREBASE_AUTH_NOT_LOGGED_IN',

  getAll: () => {
    return {
      // someday maybe pixie dust or even a 3rd currency here, i.e. you could earn it with one player & spend it on another?
    };
  },
});
export default AccountStore;

const initFirebase = async () => {
  // init firebase core
  if (!firebase.apps.length) {
    const { FIREBASE_API_KEY, FIREBASE_PROJECT_ID, FIREBASE_APP_ID } =
      process.env;

    await firebase.initializeApp({
      apiKey: FIREBASE_API_KEY,
      projectId: FIREBASE_PROJECT_ID,
      authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
      appId: FIREBASE_APP_ID,
      cors: true,
    });
  }

  if (process.env.SEND_GAME_ANALYTICS) {
    // init firebase analytics
    const { DT_TIER = 'localdev' } = process.env;
    firebase.analytics().setUserProperties({
      platform: DT_TIER === 'prod' ? Config.PLATFORM : DT_TIER,
    });
  } else {
    // dummy calls for localdev
    firebase.analytics = () => ({
      logEvent: () => {},
    });
  }
};
initFirebase();

AccountDispatcher.register(
  registerDispatchHandlers({
    [AccountDispatcher.AUTHENTICATE]: authenticate,
    [AccountDispatcher.DO_GUEST_LOGIN]: doGuestLogin,
    [AccountDispatcher.END_SESSION]: endSession,
    [AccountDispatcher.LOG_OUT]: logOut,
  })
);

async function authenticate() {
  try {
    if (Config.DEMO_MODE) {
      // Do guest login with a new browser_id for each demo mode session, so that a new user is always created
      AccountStore.emit(AccountStore.AUTHENTICATED, {
        browser_id: uuidv4(),
      });
    } else if (window.steamContext) {
      const { steamId, steam_game_language } =
        await window.steamContext.getSteamPlayerData();
      AccountStore.emit(AccountStore.AUTHENTICATED, {
        steam_accountId: steamId.accountId,
        steamId64: steamId.steamId64,
        steam_game_language,
      });
    } else if (Config.PLATFORM === 'kongregate') {
      doKongregateAuth();
    } else if (Config.PLATFORM === 'yandex') {
      await waitFor(() => !!window.ysdk);

      // On Yandex players may or may not be logged in.
      // If not logged in, identify them by a unique browser_id
      const player = await ysdk.getPlayer();
      if (player.getMode() === 'lite' || !player?._personalInfo?.uniqueID) {
        // Do guest login.
        AccountStore.emit(AccountStore.AUTHENTICATED, {
          browser_id,
        });
      } else {
        AccountStore.emit(AccountStore.AUTHENTICATED, {
          yandex_accountId: player._personalInfo.uniqueID,
          browser_id, // sent because the case may be starting out as a guest then logging into Yandex and playing.
          // So we'll add the yandex ID to the guest account.
        });
      }
      return;
    } else if (Config.PLATFORM === 'crazygames') {
      await waitFor(() => window?.CrazyGames?.SDK?.is_dt_initialized);

      const available = CrazyGames.SDK.user.isUserAccountAvailable;
      if (!available)
        // do guest login
        AccountStore.emit(AccountStore.AUTHENTICATED, {
          browser_id,
        });
      else {
        const user = await CrazyGames.SDK.user.getUser();
        if (user) onCrazyGamesLoggedIn();
        else {
          CrazyGames.SDK.user.addAuthListener(onCrazyGamesLoggedIn);
          AccountStore.emit(AccountStore.CRAZY_GAMES_AUTH_NOT_LOGGED_IN);
        }
      }
    } else {
      doFirebaseAuth();
    }
  } catch (err) {
    logError(err, {
      module: 'AccountStore',
      func: 'authenticate',
    });
  }
}

async function onCrazyGamesLoggedIn() {
  try {
    const token = await CrazyGames.SDK.user.getUserToken();
    AccountStore.emit(AccountStore.AUTHENTICATED, {
      crazygames_token: token,
      browser_id, // sent because the case may be starting out as a guest then logging into CrazyGames and playing.
      // So we'll add the CrazyGames userId to the guest account.
    });
  } catch (err) {
    logError(err, {
      module: 'AccountStore',
      func: 'onCrazyGamesLoggedIn',
    });
  }
}

async function doGuestLogin() {
  AccountStore.emit(AccountStore.AUTHENTICATED, {
    browser_id,
  });
}

async function doFirebaseAuth() {
  try {
    firebase.auth().onAuthStateChanged((firebase_user) => {
      if (!firebase_user) {
        if (_id_token) {
          // the user manually signed out
          return;
        }

        // no cached firebase auth
        AccountStore.emit(AccountStore.FIREBASE_AUTH_NOT_LOGGED_IN);
      } else {
        updateIdToken(firebase_user);
        firebase.auth().onIdTokenChanged(updateIdToken);
      }
    });
  } catch (err) {
    logError(err, {
      module: 'AccountStore',
      func: 'doFirebaseAuth',
    });
  }
}

const updateIdToken = async (firebase_user) => {
  if (!firebase_user) {
    return;
  }

  // store firebase id_token for auth headers
  const firebase_token = await firebase_user.getIdToken();
  if (firebase_token !== _id_token) {
    const is_token_refresh = !!_id_token;
    _id_token = firebase_token;
    AccountStore.emit(AccountStore.AUTHENTICATED, {
      firebase_token,
      is_token_refresh,
      browser_id,
    });
  }
};

async function doKongregateAuth() {
  try {
    await waitFor(() => !!window.kongregateAPI);

    // Load the API
    kongregateAPI.loadAPI(() => {
      // Set the global kongregate API object
      window.kongregate = kongregateAPI.getAPI();

      if (kongregate?.services?.isGuest()) {
        AccountStore.emit(AccountStore.KONGREGATE_AUTH_NOT_LOGGED_IN);

        kongregate?.services?.addEventListener('login', () => {
          connectToKongregate();
          AccountStore.emit(AccountStore.KONGREGATE_AUTH_LOGGED_IN);
        });
      } else {
        connectToKongregate();
      }
    });
  } catch (err) {
    logError(err, {
      module: 'AccountStore',
      func: 'doKongregateAuth',
    });
  }
}

function connectToKongregate() {
  AccountStore.emit(AccountStore.AUTHENTICATED, {
    kong_token: kongregate?.services?.getGameAuthToken(),
    kong_userId: kongregate?.services?.getUserId(),
    browser_id,
  });
}

awaitSocket(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_AccountStore_listeners) {
      _socket.on('accountDetails', onAccountDetails);

      socket.on('connect_error', (err) => {
        console.error(`connect_error message: ${err.message}`);
        if (err.message === 'token_error') {
          authenticate();
        }
      });

      _socket.has_AccountStore_listeners = true;
    }

    if (!_userId) {
      $addMessageLogMessage(text('ui.authenticated'), 0x00ff00);
      _socket.emit('getAccountDetails');
    }
  } catch (err) {
    logError(err, {
      module: 'AccountStore',
      func: 'onSocketConnected',
    });
  }
}

async function onAccountDetails(data) {
  try {
    _userId = data._id;

    if (process.env.SEND_GAME_ANALYTICS) {
      const fa = firebase.analytics();
      fa.setUserId(_userId);
      fa.logEvent('login');
    }
  } catch (err) {
    logError(err, {
      module: 'AccountStore',
      func: 'onAccountDetails',
      data,
    });
  }
}

function endSession() {
  // TODO(@rob-wfs): this needs to user the user ID
  _socket.emit('endSession', { _userId });
}

function logOut() {
  // TODO(@rob-wfs): backend needs to handle the user ID
  _socket.emit('endSession', { _userId });

  _userId = null;

  AccountStore.emit(AccountStore.LOGGED_OUT);
}
