import Colors from '~/constants/Colors';
import onLootImgMouseover from './onLootImgMouseover';

const LootSpawnRendering = {
  render: function (data, gameBoard) {
    const tileSprite = gameBoard.getAllPieceSprites()[data.tileId];
    if (!tileSprite) {
      return;
    }

    // render the loot image
    if (!tileSprite.lootImg) tileSprite.lootImg = tileSprite.txtPool.get();
    tileSprite.lootImg.scale = { x: 0.5, y: 0.5 };
    tileSprite.addChild(tileSprite.lootImg);
    tileSprite.tileImg.visible = false;

    // determine text symbol and tint
    const { loot } = data.tile;
    if (typeof loot?.equipment === 'object')
      loot.equipment = Object.values(loot.equipment);
    if (
      (loot.gold && loot.pd) ||
      (loot.gold && loot.equipment) ||
      (loot.pd && loot.equipment) ||
      loot?.equipment?.length > 1
    ) {
      // pile of loot
      tileSprite.lootImg.text = '&';
      tileSprite.lootImg.tint = loot.equipment
        ? loot.equipment[0].tint
        : loot.pd
          ? Colors.PIXIE_DUST
          : Colors.GOLD;
    } else if (loot.gold) {
      tileSprite.lootImg.text = '$';
      tileSprite.lootImg.tint = Colors.GOLD;
    } else if (loot.pd) {
      tileSprite.lootImg.text = '*';
      tileSprite.lootImg.tint = Colors.PIXIE_DUST;
    } else if (loot.equipment) {
      const item = loot.equipment[0];
      tileSprite.lootImg.text = getEquipmentSymbol(item);
      tileSprite.lootImg.tint = item.tint;
    } else throw new Error('Invalid loot');

    // add mouseover functionality
    tileSprite.lootImg.interactive = true;
    tileSprite.lootImg.interactiveChildren = true;
    tileSprite.lootImg.mouseover = tileSprite.lootImg.touch =
      onLootImgMouseover;
  },
};
export default LootSpawnRendering;

const getEquipmentSymbol = (item) => {
  switch (item.slot) {
    case 'boots':
      return '7';
    case 'helmet':
      return '^';
    case 'off_hand':
      switch (item.type) {
        case 'book':
          return '"';
        case 'bow':
          return '}';
        case 'shield':
          return '0';
        case 'trinket':
          return '%';
      }
      break;
    case 'torsoe':
      return ']';
    case 'weapon':
      switch (item.type) {
        case 'dagger':
          return '`';
        case 'hammer':
          return '?';
        case 'staff':
          return '/';
        case 'sword':
          return '(';
      }
      break;
    case 'ring':
      return '~';
  }

  return ';';
};
