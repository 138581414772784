import Game from 'dt-common/constants/Game';
import heroes from './heroes';
const { GAME_MODES, GAME_SUBMODES } = Game;

const ui = {
  // initialization messages
  connecting_to_kong: 'Подключение к Kongregate...',
  connected_to_kong: 'Подключение к Kongregate установлено!',
  unable_to_connect_to_kong:
    'Не удается подключиться к Kongregate. Попробуйте еще раз!',
  getting_fb_status: 'Получение статуса входа в Facebook...',
  not_logged_into_fb: 'Вход в Facebook не выполнен. Авторизация устройства...',
  unable_to_connect_to_fb:
    'Не удается подключиться к Facebook. Авторизация устройства...',
  unable_to_init_fb_API:
    'Не удается инициализировать Facebook API. Авторизация устройства...',
  connected_to_fb: 'Подключение к Facebook установлено!',
  found_browser_id_cookie: 'Обнаружен действующий ID браузера...',
  no_browser_id_cookie: 'ID браузера не найден — создание нового аккаунта...',
  authenticated: 'Авторизация завершена!',
  ad_blocker_msg: {
    a: 'Обнаружен блокировщик рекламы!',
    b: 'Игра может работать с ошибками.',
    c: 'В Команда Подземелья НИКОГДА не будет рекламы. Фу!',
    d: 'Пожалуйста, внесите dungeonteam.com в список исключений в настройках блокировщика рекламы :)',
  },

  safari_warning:
    'К сожалению, Команда Подземелья недоступна в Safari. Пожалуйста, воспользуйтесь другим браузером, например Chrome.',

  kicked_off_msg:
    'Соединение с игрой разорвано. Перенаправляем вас на форум...',

  // Welcome Modal
  loading: 'Загрузка',
  welcome_title: 'Добро пожаловать в...',
  PLAY: 'ИГРАТЬ',
  announcements: 'Объявления',
  devlog: 'Блог разработчиков',
  featured_video: 'Избранные видеоролики',
  patch_notes: 'Заметки о патчах',
  how_to_submit_video: 'Отправить свое видео!',
  help_lost_game_data: 'ПОМОГИТЕ! Потерялись игровые данные!',
  show_changelog: 'Отобразить журнал изменений',
  hide_changelog: 'Скрыть',

  // Demo Mode
  demo_mode: {
    title: 'Демо-режим',
    info_1: 'Прогресс не будет сохраняться между игровыми сессиями.',
    info_2: 'Герои начинают с 50-го уровня.',
    info_3: 'Храм начинается с 10-го уровня, и молитвы даются мгновенно.',
    info_4: 'Начните с 20 000 Pixie Dust (премиальная валюта).',
    appeal_1:
      '>>> Играйте в полную версию игры бесплатно на https://dungeonteam.com',
    appeal_2:
      '...или в Steam по адресу https://store.steampowered.com/app/1509060/Dungeon_Team/',
  },

  welcome_msg: 'Добро пожаловать в Команда Подземелья!',
  join_forum_msg: 'Заходите на форум: http://dungeonteam.com/forum',
  join_discord_msg: 'Наш сервер в Discord: https://discord.gg/ysgHx3ErwJ',
  chat_connected_msg: 'Вы подключены к глобальному чату.',
  no_log_entries: 'Непрочитанные записи журнала отсутствуют.',
  description: 'Описание',
  get_inn_log_entry_description: (log_entry) =>
    `${ui.game_submodes[log_entry.game_submode]} tier ${log_entry.old_tier} tournament completed.`,

  // Login Modals
  login_with_email: 'Войти с помощью электронной почты',
  email_label: 'Электронная почта:',
  password_label: 'Пароль:',
  log_in: 'Авторизоваться',
  log_out: 'Выйти',
  reset_password: 'сбросить пароль',
  create_new_account: 'Создать новую учетную запись',
  or_login_with_yandex: 'Или войдите через Яндекс (рекомендуется)',
  login_with: 'Войти через',
  play_as_guest: 'Играть как гость',

  // hero builds
  hero_build_name: 'Название билда',
  swap_hero_builds: 'Обменяться билдами',

  // header
  player_name: 'Имя игрока',
  new_player: 'Новый игрок',
  what_should_we_call_you: 'Как к вам обращаться?',
  player_name_placeholder: 'Укажите имя...',
  settings: 'Настройки',
  battle_log: 'Журнал боя',
  inn_tourney_log: 'Журнал турнира в Таверне',
  leaderboards: 'Турнирная таблица',
  get_connected: 'Подключиться',
  change_display_name: 'Сменить отображаемое имя',

  // footer
  need_3_heroes: '3 персонажа',
  need_inn_tier_2: 'таверна 2 Ранг',
  need_level_5: 'Персонаж уровня 5',
  need_5k_gold: '5000 золота',
  currently_equipped: 'Экипировано',

  // leaderboard
  leaderboard: 'Турнирная таблица',
  global: 'Мир',
  use_location: 'Использовать местоположение',
  update_location: 'Обновить местоположение',
  name: 'Имя',
  rating: 'Рейтинг',
  depth: 'Глубина',
  w_l: 'МР',
  team: 'Команда',

  // Social Modal
  social_modal_title: 'Подключиться...',

  price: 'Цена',
  pixie_dust: 'Пыльца пикси',
  pixie_dust_short: 'ПП',
  pixie_dust_description:
    'Используется для разблокировки героев, слотов для сумок и молитвенных слотов. Также используется для покупки красителей для снаряжения, молитвенных свечей и многого другого!',
  pay: 'оплатить',
  pay_now: (cost) => `Заплатите ${cost} сейчас`,
  pay_via_steam: (cost) => `Оплатить ${cost} через Steam`,
  payment_security_blurb:
    'Платежная информация вводится и обрабатывается безопасно с помощью ',
  steam_review_hook: 'оставьте отзыв о Команда Подземелья в ',
  steam_review_appeal:
    'Даже если вы не планируете играть в клиенте Steam, ваш отзыв поможет игре охватить больше людей!',

  OK: 'OK',
  yes: 'ДА',
  no: 'НЕТ',
  delete: 'Удалить',
  cancel: 'Отмена',
  close: 'Закрывать',
  this_color: 'Этот цвет. Поехали.',

  login: 'Войти',
  logout: 'Выйти',
  login_modal_title: 'Подключите аккаунт и играйте с любого устройства!',
  facebook_connect_prompt: 'Подключиться к Facebook!',
  facebook_connect_explanation: [
    'Так вы сможете...',
    'Получать доступ к игровым данным с любого устройства.',
    'Сохранить игровые данные, даже если потерялись куки.',
  ],
  connected_to_facebook: 'Подключение к Facebook установлено.',

  MENU: 'МЕНЮ',
  ARMORY: 'АРСЕНАЛ',
  ARENA: 'АРЕНА',
  ARENA_LOBBY: 'АРЕНА',
  CAVERNS: 'ПЕЩЕРЫ',
  BATTLE_DEBRIEF: 'РАЗБОР БОЯ',
  SHOP: 'МАГАЗИН',
  INN: 'ТАВЕРНА',
  HERO_BUILDS: 'АРСЕНАЛ',
  TEMPLE: 'СВЯТИЛИЩЕ',

  // game modes/submodes
  game_modes: {
    GAME_MODE_pvpAuto: 'Таверна',
    GAME_MODE_pvpLive: 'Арена',
    GAME_MODE_caverns: 'Пещеры',
  },
  game_submodes: {
    GAME_SUBMODE_pvp1v1: '1v1',
    GAME_SUBMODE_pvp2v2: '2v2',
    GAME_SUBMODE_pvp3v3: '3v3',
    GAME_SUBMODE_pvp5v5: '5v5',
    GAME_SUBMODE_pvp7v7: '7v7',
    GAME_SUBMODE_pvp11v11: '11v11',
    GAME_SUBMODE_caverns1man: '1 участник',
    GAME_SUBMODE_caverns2man: '2 участника',
    GAME_SUBMODE_caverns3man: '3 участника',
    GAME_SUBMODE_caverns5man: '5 участников',
    GAME_SUBMODE_caverns7man: '7 участников',
    GAME_SUBMODE_caverns11man: '11 участников',
  },

  question_proceed: 'Продолжить?',

  my_heroes: 'Мои персонажи',
  summon_a_hero: 'Вызов персонажа',

  inventory: 'Инвентарь',
  use: 'Использовать',
  equip: 'Экипировать',
  unequip: 'Снять',
  move: 'Переместить',
  select_item_to_dye: 'Выберите предмет для покраски.',

  free: 'Бесплатно',
  gold: 'Золото',
  gold_brief: 'з',
  purchase_pixie_dust: 'Купить пыльцу пикси',
  get_free_pixie_dust: 'Бесплатная пыльца пикси :)',
  get_newsletter: 'Подписаться на рассылку:',
  enter_email: 'введите адрес',
  enter_password: 'введите пароль',
  submit_newsletter_btn: 'Отправить',
  newsletter_signup_success:
    'Подписка прошла успешно — будем держать вас в курсе!',
  like_us_on_fb: 'Поставьте нам лайк в Facebook',
  follow_us_on_twitter: 'Следите за нами в X',
  plus_100_pd: '+100 ПП!',
  rate_us: 'Помогите нам с рейтингом!',
  thank_you: 'Спасибо!',

  mailing_list_bonus_item_msg: {
    1: 'Привет, спасибо, что подписались на рассылку Команда Подземелья!',
    2: 'В качестве награды...',
    3: 'Возьмите одно Благословенное Кольцо Новичка!',
    4: '(Проверьте инвентарь вашего первого героя.)',
  },

  // edit hero screen
  equipment: {
    full: 'Снаряжение',
    brief: 'Снар.',
  },
  abilities: {
    full: 'Умения',
    brief: 'Ум.',
  },
  attributes: {
    full: 'Характеристики',
    brief: 'Хар.',
  },
  ai_settings: {
    full: 'Настройки ИИ',
    brief: 'ИИ',
  },
  global_inventory: 'Ваш инвентарь',
  bag_slot_unlock_title: 'Разблокировать место',
  bag_slot_unlock_prompt: (unlock_cost) =>
    `Разблокировать место за пыльцу пикси: ${unlock_cost}?`,
  affinities: 'Сродства',
  unlocks_at_level: (level) => `Открывается на ${level} уровне.`,

  // Edit Attributes
  primary_attributes: 'Основные способности',
  secondary_attributes: 'Дополнительные способности',
  reset_attributes: 'Сбросить способности',

  // hero unlock modal
  hero_unlock_title: 'Новый герой рвется в бой...',
  hero_unlock_question: {
    p1: 'Открыть персонажа ',
    /*hero name*/
    p2: ' за пыльцу пикси: ',
    /*unlock cost*/
    p3: '?',
  },

  // ability upgrade modal
  ability_upgrade_title: {
    p1: 'Сила персонажа ',
    /*hero name*/
    p2: ' растет...',
  },
  ability_upgrade_question: {
    p1: 'Потратить 1 очко способностей, чтобы прокачать ',
    /*ability name*/
    p2: '?',
  },

  locked: 'Закрыто',
  reset_abilities: 'Сбросить способности',
  tabula_rasa: 'Tabula rasa...',
  abilities_reset_question: 'Сбросить способности этого героя?',
  attributes_reset_question: 'Сбросить атрибуты этого героя?',

  // Edit AI Settings
  role_priorities: 'Приоритеты ролей',
  ai_roles: {
    tank: 'Отвлечение',
    dps_close: 'Ур/с, ближний бой',
    dps_ranged: 'Ур/с, дальний бой',
    interference: 'Контроль',
    support: 'Поддержка',
  },

  // Inn
  rank: 'Рейтинг',
  new_rank: 'Новый рейтинг',
  peanuts: 'Мелочь',
  peanuts_per_second: 'Скорость получения мелочи',
  peanuts_burgled: 'Украдено мелочи',
  time_left: 'Осталось времени',
  burgle: 'Украсть',
  attack_loadout: 'Атаковать формацию',
  done: 'Готово',
  peanuts_to_steal: 'Мелочь для кражи',
  inn_tier_titles: {
    1: 'Ранг 1 - Новички',
    2: 'Ранг 2 - Как бы новички',
    3: 'Ранг 3 - Не новички',
    4: 'Ранг 4 - Игроки',
    5: 'Ранг 5 - Воители',
    6: 'Ранг 6 - Ветераны',
    7: 'Ранг 7 - Эксперты',
    8: 'Ранг 8 - Элита',
    9: 'Ранг 9 - Мастера',
    10: 'Ранг 10 - Гроссмейстеры',
  },
  challenges_left: 'Осталось вызовов на бой',
  inn_rank_1_congrats:
    'Поздравляем! Вы забрались на самый верх турнирной таблицы.',
  everyone_embattled: 'Все доступные противники повержены! Ожидайте...',
  inn_logs_title: 'Журналы турнира в Таверне',
  tier_up: 'Ранг ярусами!',
  tier_down: 'Ранг вниз :(',
  tier_maintained: 'Ранг сохранен ~',
  inn: {
    passive_rank_up: (game_submode, new_rank) =>
      `Ранг турнира Inn повысился! Новый ${ui.game_submodes[game_submode]} ранг: ${new_rank}`,
    passive_tourney_resolved: (game_submode) =>
      `Турнир ${ui.game_submodes[game_submode]} Таверна завершен!`,
    check_for_rewards: 'Проверьте наличие наград!',
    tier_up_msg: (new_tier) =>
      `Вы достигли ${ui.inn_tier_titles[new_tier]} Таверны!`,
    tier_down_msg: (new_tier) =>
      `Ваш ранг понижен до ${ui.inn_tier_titles[new_tier]} Таверны.`,
  },

  // Arena Lobby
  match_type: 'Тип матча',
  match_types: {
    arena1v1: '1v1',
    arena2v2: '2v2',
    arena3v3: '3v3',
    arena5v5: '5v5',
    arena7v7: '7v7',
  },
  vs: 'Против',
  fight: 'В бой!',
  fight_AI: 'Бой с ИИ',
  fight_live: 'Бой с людьми',
  victory: 'ПОБЕДА!',
  defeat: 'ПОРАЖЕНИЕ',
  rating_change: 'Рейтинг изменился',
  new_rating: 'Новый рейтинг',
  leaderboard_rank: 'Мировой рейтинг',
  rewards: 'Награды',
  claim: 'Забрать',
  claim_all: 'Забрать все',
  battle_log: 'Журнал боя',
  ended_at: 'Время завершения',
  opponent: 'Оппонент',
  opponents: 'Оппоненты',
  result: 'Результат',
  online_status: 'Статус',
  online: 'В сети',
  offline: 'Не в сети',
  in_battle: 'В бою',
  won: 'Побед',
  lost: 'Поражений',
  avg_opponent: 'Средний рейтинг оппонентов',
  live_challenge: 'Вызов на онлайн-бой!',
  quick_matchup: 'Быстрый поединок',
  online_matchup: 'Поединок в режиме онлайн',
  challenge_by_name: 'Вызов на бой по имени',
  challenge_player: 'Вызвать игрока на бой',
  whom_do_you_challenge: 'Кого вызовете на бой?',
  challenge_issued: 'Перчатка брошена! Ждем...',
  roster: 'Таблица',
  go_back: 'Назад',
  change: 'Изменить',
  defense: 'Защита',
  edit: 'Редактировать',
  engage: 'Задействовать',
  rename: 'Переименовать',
  rename_hero_modal_title: (hero_handle) =>
    `Как назвать билд персонажа ${heroes[hero_handle].name}?`,

  // Shop
  shop: {
    out_of_stock_msg:
      "Извини, сейчас товара нет.\n\nI'Но я могу купить твой хлам.",
  },
  items_to_sell: 'Товары на продажу',
  buy: 'Купить',
  sell: 'Продать',
  keep: 'Оставить',
  sell_all: 'Продать все',
  haggle: 'Торг',
  lets_deal: () => {
    const candidates = [
      'Давай поторгуем.',
      'Для нас что-нибудь есть?',
      'Хороший выдался день?',
      'Хех, выглядит неплохо.',
      'А, это снова ты...',
    ];
    return candidates[Math.floor(Math.random() * candidates.length)];
  },

  // Altar
  altar_summary: {
    pt_1: 'Пожертвовать ',
    pt_2: ' богу ',
    pt_3: ', и попросить ',
  },
  gods: {
    chaos: 'ХаОСа1',
    balance: 'Гармонии',
    order: 'Порядка',
  },
  weapon_dagger: 'Кинжал',
  weapon_staff: 'Посох',
  weapon_sword: 'Меч',
  weapon_hammer: 'Молот',
  boots_light: 'Легкие ботинки',
  torsoe_light: 'Легкую броню',
  helmet_light: 'Легкий шлем',
  boots_med: 'Средние ботинки',
  torsoe_med: 'Среднюю броню',
  helmet_med: 'Средний шлем',
  boots_heavy: 'Тяжелые сапоги',
  torsoe_heavy: 'Тяжелую броню',
  helmet_heavy: 'Тяжелый шлем',
  off_hand_book: 'Книгу',
  off_hand_bow: 'Лук',
  off_hand_shield: 'Щит',
  off_hand_trinket: 'Брелок',
  ring_ring: 'Кольцо',
  prayer_slot_unlock_title: 'Да улыбнутся тебе боги..',
  prayer_slot_unlock_question: {
    p1: 'Открыть ячейку игрока за пыльцу пикси: ',
    // (amount)
    p2: '?',
  },
  no_prayer_candles: 'У вас нет церковных свеч!',
  devote_a_candle: 'Поставить свечу...',
  whom_shall_we_pray_for: 'Кому помолимся?',
  endeared_you_to_god: 'получено расположение бога',

  // message log
  connected_to_server: 'Соединение с сервером установлено!',
  disconnected_from_server: 'Соединение с сервером разорвано...',
  insufficient_pixie_dust: 'Не хватает пыльцы пикси!',
  no_inventory_space: 'Не хватает места в инвентаре!',
  unlock_a_hero_first: 'Сначала откройте персонажа!',
  no_heroes_in_squad: 'Сначала добавьте персонажа в отряд!',
  invalid_emote_command: 'Неверная команда эмоции',
  invalid_gender_input: [
    'Неверный ввод пола.',
    'ФОРМАТ: /мест.: имен. пад./род. пад./притяж.',
    '(макс. для персонажа — 7 атрибутов)',
    '',
    'ПРИМЕР: /пол: она/ее/ее',
  ],
  unable_to_get_location: 'Не удается определить ваше местоположение.',
  on_pd_credit: 'Вам начислено',
  no_player_by_that_name: 'Игрока с таким именем не существует.',
  is_offline: 'не в сети.',
  is_in_battle: 'сейчас в бою.',
  enemies_in_play: 'В помещении еще остались враги!',
  leveled_up: 'переходит на уровень',
  not_enough_gold: 'Не хватает золота!',
  equip_some_abilities_first: 'Сначала экипируйте какие-нибудь способности!',
  battle_debrief_error: 'Ошибка получения разбора боя.',
  health_check_failed: 'Подключение к бою сброшено; восстанавливаем доступ...',
  battle_connection_recovered: 'Доступ к бою восстановлен!',
  engine_recovery_failed: 'Не удалось восстановить доступ к бою. Матч отменен.',
  warnings: {
    hero_equipment_unassignment_warning: ({ item_name, hero_name }) =>
      `Если убрать предмет ${item_name} из инвентаря персонажа ${hero_name}, он исчезнет из следующих комплектов снаряжения:`,
    no_assign_equipment_from_global:
      'Перед тем как включить предмет в комплект снаряжения, вам нужно переместить его в инвентарь персонажа.',
    no_unequip_item_to_global: 'Убрать из инвентаря персонажа.',
    warning: 'Внимание',
  },
  server_error: 'Ошибка сервера',

  // options menu
  settings_title: 'Настройки',
  profanity_filter: 'Фильтр ненормативной лексики',
  music: 'Музыка',
  sound_effects: 'Звуковые эффекты',
  save: 'Сохранить',
  credits: 'Команда разработчиков',
  lead_developer: 'Ведущий разработчик',
  graphic_designer: 'Визуальный дизайнер',
  composer: 'Композитор',
  qa_lead: 'Начальник отдела качества',
  business_development: 'Отдел коммерческого развития',
  project_manager: 'Менеджер проекта',
  terms_of_service: 'Условия обслуживания',
  privacy_policy: 'политика конфиденциальности',
  customer_support_email:
    'Если вам нужна помощь с вашей учетной записью, отправьте электронное письмо по адресу support@wakefield-studios.com',

  // chat
  players_online: 'Игроков в сети',
  send: 'Отправить',
  chat_text_color_title: 'Цвет текста в чате',
  choose_chat_text_color:
    'Выберите цвет текста в чате... Вот так это будет выглядеть:',
  chat_text_color_example: 'Всем привет!',
  text_color_change_price_warning: 'Это стоит 1000 ПП...',
  chat_color_change_success: 'Готово! Теперь текст в чате другого цвета :)',
  chat_input_placeholder: 'Скажите что-то...',
  chat_lobby: 'Сад',

  // miracle dye dialog
  miracle_dye_dialog_title: 'Используем волшебную краску...',
  what_color_dye: 'Какую расцветку желаете?',

  // make gold modal
  make_gold: 'Создать золото',
  abracadabra: 'Абракадабра!',

  // caverns
  caverns_squad: 'Отряд Пещер',
  caverns_welcome:
    'Ваш отряд находится у неприметной расщелины в скале. Это вход в Пещеры Хаоса...',
  deepest_level: 'Самый глубокий уровень',
  descend: 'Спуститься',
  descend_caverns_at_1: 'Вход на уровне 1',
  descend_caverns_at_portal_level: (level) => `Вход на уровне Ω (${level})`,
  while_elsewhere: 'Пока вас не было...',
  fighting_around_caverns: 'Зачистив устье пещеры, ваш отряд получает...',
  enter_at_level: 'Вход на уровне',
  return_to_armory: 'Вернуться в арсенал',
  surface: 'Вернуться на поверхность',
  returned_to_surface: 'Вы вернулись на поверхность!',
  descent_has_yielded: 'Спуск в Пещеры принес вашему отряду...',
  gold_gains: 'Золото',
  level_up: 'Новый уровень!',
  depth: 'Глубина',
  portal_level: 'Уровень портала',
  inventory_space: 'Место в сумках',
  auto_explore: 'Авто-исследование',
  target_depth: 'Целевая глубина',
};
export default ui;
