import { EventEmitter } from 'events';
import Game from 'dt-common/constants/Game';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import { PlayerDispatcher, UIDispatcher } from '~/flux/dispatchers';
import { Screens } from '~/constants';
import { registerDispatchHandlers } from '~/Tools';

const { GAME_MODES, GAME_SUBMODES } = Game;

// the stuff we serve
let current_ui_screen = 'splash';
let current_game_mode =
  localStorage.getItem('dt_game_mode') || GAME_MODES.GAME_MODE_pvpAuto;
let current_game_submode =
  localStorage.getItem('dt_game_submode') || GAME_SUBMODES.GAME_SUBMODE_pvp1v1;
let focused_hero_handle;
let focused_hero_build = {
  hero_handle: null,
  build_id: null,
};
let showing_modal = false;

const UIStore = Object.assign({}, EventEmitter.prototype, {
  GAME_MODE_SELECTION: 'GAME_MODE_SELECTION',
  GAME_SUBMODE_SELECTION: 'GAME_SUBMODE_SELECTION',
  HERO_FOCUSED: 'HERO_FOCUSED',
  MODAL_CLOSED: 'MODAL_CLOSED',
  SHOW_MODAL: 'SHOW_MODAL',
  UI_NAV: 'UI_NAV',

  getAll() {
    return {
      current_ui_screen,
      current_game_mode,
      current_game_submode,
      focused_hero_handle,
      focused_hero_build,
      showing_modal,
    };
  },
});
export default UIStore;

PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
UIDispatcher.register(
  registerDispatchHandlers({
    [UIDispatcher.SHOW_MODAL]: onShowModal,
    [UIDispatcher.MODAL_CLOSED]: onModalClosed,
    [UIDispatcher.SELECT_GAME_MODE]: onGameModeSelected,
    [UIDispatcher.SELECT_GAME_SUBMODE]: onGameSubmodeSelected,
    [UIDispatcher.SET_FOCUSED_HERO]: setFocusedHero,
    [UIDispatcher.SET_FOCUSED_HERO_BUILD]: setFocusedHeroBuild,
    [UIDispatcher.UI_NAV]: onUINav,
  })
);

function onPlayerLoggedIn(action) {
  // assert that player has enough unlocked heroes for cached submode selection
  const { hero_roster } = action.player.gameState;
  const num_unlocked_heroes = Object.values(hero_roster).filter(
    (h) => h.level > 0
  ).length;
  const num_heroes_in_selected_submode =
    getNumHeroesInSubmode(current_game_submode);
  if (num_unlocked_heroes < num_heroes_in_selected_submode) {
    onGameSubmodeSelected({
      gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_pvp1v1,
    });
  }
}

function setFocusedHero({ hero_handle }) {
  focused_hero_handle = hero_handle;
  UIStore.emit(UIStore.HERO_FOCUSED, hero_handle);
}

function onGameModeSelected({ gameModeKey }) {
  try {
    if (!Object.values(GAME_MODES).includes(gameModeKey)) {
      throw new Error(`Invalid gameModeKey: ${gameModeKey}`);
    }
    // submode may need to be updated based on new game mode
    if (gameModeKey.includes('pvp') && current_game_mode.includes('caverns')) {
      switch (current_game_submode) {
        case GAME_SUBMODES.GAME_SUBMODE_caverns1man:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_pvp1v1,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_caverns2man:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_pvp2v2,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_caverns3man:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_pvp3v3,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_caverns51man:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_pvp5v5,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_caverns7man:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_pvp7v7,
          });
          break;
      }
    } else if (
      gameModeKey.includes('caverns') &&
      current_game_mode.includes('pvp')
    ) {
      switch (current_game_submode) {
        case GAME_SUBMODES.GAME_SUBMODE_pvp1v1:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_caverns1man,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_pvp2v2:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_caverns2man,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_pvp3v3:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_caverns3man,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_pvp5v5:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_caverns5man,
          });
          break;
        case GAME_SUBMODES.GAME_SUBMODE_pvp7v7:
          onGameSubmodeSelected({
            gameSubmodeKey: GAME_SUBMODES.GAME_SUBMODE_caverns7man,
          });
          break;
      }
    }

    current_game_mode = gameModeKey;
    UIStore.emit(UIStore.GAME_MODE_SELECTION, { gameModeKey });
    localStorage.setItem('dt_game_mode', gameModeKey);
  } catch (err) {
    logError(err);
  }
}

function onGameSubmodeSelected({ gameSubmodeKey }) {
  try {
    if (!Object.values(GAME_SUBMODES).includes(gameSubmodeKey)) {
      throw new Error(`Invalid gameSubmodeKey: ${gameModeKey}`);
    }
    current_game_submode = gameSubmodeKey;
    UIStore.emit(UIStore.GAME_SUBMODE_SELECTION, { gameSubmodeKey });
    localStorage.setItem('dt_game_submode', gameSubmodeKey);
  } catch (err) {
    logError(err);
  }
}

function onUINav(action) {
  current_ui_screen = action.screen_id;
  UIStore.emit(UIStore.UI_NAV, current_ui_screen, action.force);

  switch (current_ui_screen) {
    case Screens.INN:
      onGameModeSelected({ gameModeKey: GAME_MODES.GAME_MODE_pvpAuto });
      break;

    case Screens.ARENA_LOBBY:
      onGameModeSelected({ gameModeKey: GAME_MODES.GAME_MODE_pvpLive });
      break;
  }
}

function setFocusedHeroBuild(action) {
  focused_hero_handle = action.hero_handle;
  focused_hero_build = {
    hero_handle: action.hero_handle,
    build_id: action.build_id,
  };
  UIStore.emit(UIStore.HERO_FOCUSED, focused_hero_handle);
}

function onShowModal() {
  showing_modal = true;
  UIStore.emit(UIStore.SHOW_MODAL);
}

function onModalClosed() {
  showing_modal = false;
  UIStore.emit(UIStore.MODAL_CLOSED);
}
