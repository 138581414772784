import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import AbilityTooltip from './AbilityTooltip';
import ItemTooltip from './ItemTooltip';
import PrimaryAttributeTooltip from './PrimaryAttributeTooltip';
import SecondaryAttributeTooltip from './SecondaryAttributeTooltip';
import UITooltip from './UITooltip';

const Tooltip = function (type, data, tt_caller = null, options = {}) {
  try {
    PIXI.Container.call(this);

    this.dispose = () => {
      TweenMax.to(this, 0.17, {
        alpha: 0,
        onComplete: () => {
          if (_content_sprite) {
            _content_sprite.dispose();
          }
          this.destroy();
        },
      });
    };

    if (DT_CANVAS_GLOBALS.tooltips_disabled) {
      return;
    }

    let _content_sprite;

    // generate the tooltip content
    switch (type) {
      case 'ability':
        _content_sprite = new AbilityTooltip(data);
        break;

      case 'item':
        _content_sprite = new ItemTooltip(data);
        break;

      case 'primary_attribute':
        _content_sprite = new PrimaryAttributeTooltip(data);
        break;

      case 'secondary_attribute':
        _content_sprite = new SecondaryAttributeTooltip(data);
        break;

      case 'ui':
        _content_sprite = new UITooltip(data);
        break;

      default:
        throw new Error('invalid tooltip type');
    }

    // add right & bottom padding
    this._width = _content_sprite.width + DT_CANVAS_GLOBALS.spacing * 2;
    this._height = _content_sprite.height + DT_CANVAS_GLOBALS.spacing * 2;

    // make backdrop & border
    CanvasTools.addBackFill(this, 0x070707, 0.985);
    CanvasTools.addBorder(
      this,
      'chat_border_horiz.png',
      'chat_border_vert.png',
      'chat_border_horiz.png',
      'chat_border_vert.png',
      0
    );

    // position & add the content sprite
    _content_sprite.x = _content_sprite.y = Math.round(
      DT_CANVAS_GLOBALS.spacing
    );
    this.addChild(_content_sprite);

    // tooltip positioning relative to current mouse position
    const mouse_pos =
      DT_CANVAS_GLOBALS._pixi_app.renderer.plugins.interaction.mouse.global;
    const TOOLTIP_MOUSE_MARGIN = 60;
    const TOOLTIP_SCREEN_MARGIN = 10;
    // for x-position, try to place it to the right of the mouse.
    this.x = mouse_pos.x + TOOLTIP_MOUSE_MARGIN;
    // if that would cause it to run off the screen, place it to the left
    if (this.x + this._width + TOOLTIP_SCREEN_MARGIN > window.innerWidth) {
      this.x = mouse_pos.x - this._width - TOOLTIP_MOUSE_MARGIN;
    }
    // for y-position, try to place the top just above the cursor.
    this.y = mouse_pos.y - TOOLTIP_MOUSE_MARGIN;
    // correct y-position if it run off the bottom of the screen
    if (this.y + this._height > window.innerHeight - TOOLTIP_SCREEN_MARGIN) {
      this.y = window.innerHeight - TOOLTIP_SCREEN_MARGIN - this._height;
    } else if (this.y < TOOLTIP_MOUSE_MARGIN) {
      // or the top
      this.y = TOOLTIP_MOUSE_MARGIN;
    }

    this.x = Math.round(this.x);
    this.y = Math.round(this.y);

    DT_CANVAS_GLOBALS.overlay_stage.addChild(this);
  } catch (err) {
    logError(
      err,
      Object.assign(
        {
          module: 'components/tooltips/Tooltip',
          func: 'constructor',
          type,
        },
        data,
        options
      )
    );
  }
};
Tooltip.prototype = Object.create(PIXI.Container.prototype);
Tooltip.prototype.constructor = Tooltip;
export default Tooltip;
