<template>
  <Dialog
    class="relative"
    :open="true"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col"
      >
        <div class="w-full p-6 flex flex-col items-center">
          <div
            class="border border-slate-400 rounded-sm bg-slate-700 hover:bg-slate-600 hover:text-yellow-300 px-6 py-3 flex items-center cursor-pointer"
            @click="logInWithKongregate"
          >
            <span>{{ text('ui.login_with') }}</span>
            <img
              class="ml-2"
              :src="`${DOM_IMG_PATH}/kongregate_logo_200x43.jpg`"
              alt="Kongregate"
            />
          </div>
          <div
            class="mt-8 flex underline cursor-pointer hover:text-yellow-300"
            @click="doGuestLogin"
          >
            <span>{{ text('ui.play_as_guest') }}</span>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel } from '@headlessui/vue';
import waitFor from 'dt-common/isomorphic-helpers/waitFor';
import { AccountActions } from '~/flux/actions';
import { AccountStore } from '~/flux/stores';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';

const { ASSETS_PATH = 'assets' } = process.env;
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

AccountStore.on(AccountStore.KONGREGATE_AUTH_LOGGED_IN, () => props.onClose());

async function logInWithKongregate() {
  await waitFor(() => !!window?.kongregate?.services?.showRegistrationBox);
  kongregate.services.showRegistrationBox();
}

function doGuestLogin() {
  AccountActions.doGuestLogin();
  props.onClose();
}
</script>
