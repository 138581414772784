export default {
  // standard
  melee_attack: {
    name: 'Атака ближнего боя',
    blurb: 'Обычная атака ближнего боя.',
    calc: {
      apCost: '45 - Лв/4',
      damage: '50 + Си*2 + Лв + Об',
    },
  },
  move: {
    name: 'Перейти',
    blurb: 'Перейти на соседнюю ячейку.',
    calc: {
      apCost: '20',
    },
  },

  // Alchemy
  burning_hands: {
    name: 'Пылающие руки',
    blurb: 'Руки заклинателя охватывает пламя, пожирающее плоть врагов.',
    calc: {
      apCost: '40 - Уровень',
      mpCost: '5 + Уровень*4',
      damage: '65 + Уровень*( Об/2 + Ма/4 + Лв/6 )',
    },
  },
  fumigate: {
    name: 'Дезинфекция',
    blurb: 'Выпустить облако зловонного тумана, отравляющего всех, кто рядом.',
    calc: {
      mpCost: '50 - Уровень',
      mpCost: '40 + Уровень*4',
      potency: '10 + Уровень*( Об/6 + Ма/10 )',
    },
  },
  ice_nine: {
    name: 'Лед-девять',
    blurb:
      'Швырнуть флакон с летучей жидкостью — когда он разбивается, из-за химической реакции враги по цепочке получают состояние замедления.',
    calc: {
      mpCost: '20 + Уровень*2',
      charges: 'Уровень',
      duration: '2 + Уровень*( Об/80 )',
    },
  },
  lightning: {
    name: 'Молния',
    blurb:
      'Поразить цель электрическим разрядом, который может по цепочке передаваться тем, кто рядом. Урон уменьшается вдвое при каждом переходе. Броня не защищает от Молнии.',
    calc: {
      apCost: '40 - Уровень',
      mpCost: '30 + Уровень*3',
      damage: '100 + Уровень*( Об/7 + Ма/7 )',
    },
  },
  molotov_cocktail: {
    name: 'Коктейль Молотова',
    blurb:
      'Швырнуть в цель горящую бутылку. Все в области взрыва получат урон (Урон уменьшается на 25% за расстояние от взрыва).',
    calc: {
      range: '3 + Уровень/2',
      apCost: '70 - Уровень*2',
      mpCost: '20 + Уровень*4',
      damage: '40 + Уровень*( Об/2 + Лв/6 )',
    },
  },
  poison_weapon: {
    name: 'Отравленное оружие',
    blurb:
      'Пока есть заряды, атаки оружием отравляют врагов. Можно использовать на союзниках.',
    calc: {
      apCost: '30 - Уровень',
      mpCost: '5 + Уровень*4',
      potency: '20 + Уровень*( Об/5 )',
      charges: 'Уровень + Лв/4',
    },
  },
  road_to_nowhere: {
    name: 'Дорога в никуда',
    blurb:
      'Швырнуть флакон с психоактивным веществом. При разбивании испускает пар, вызывающий жуткие галлюцинации — и цель бежит с поля боя.',
    calc: {
      mpCost: ' 20 + Уровень',
      duration: '1 + Уровень*( Об/150 + Вс/200 )',
    },
  },

  // Armor Use
  armor_spikes: {
    name: 'Шипастая броня',
    blurb:
      'ПАССИВ: Если персонаж получает урон в ближнем бою, часть урона получает и нападающий.',
    calc: {
      potency: '3% за уровень',
    },
  },
  bash: {
    name: 'Слэм',
    blurb:
      'Со всей силы толкнуть плечом ближайшего врага, травмируя его и оглушая.',
    calc: {
      apCost: '50 - Уровень*2',
      damage: 'Уровень*( Си/6 + Вн/10 )',
      duration: '1 + Уровень*( Си/200 + Лв/300 )',
    },
  },
  layers_of_protection: {
    name: 'Защитный слой',
    blurb: 'ПАССИВ: Повышает класс брони',
    calc: {
      potency: 'Уровень*5',
    },
  },
  missile_defense: {
    name: 'Противовоздушная оборона',
    blurb: 'ПАССИВ: Повышается вероятность заблокировать атаки дальнего боя.',
    calc: {
      potency: '5% за уровень',
    },
  },
  reflect_magic: {
    name: 'Отражение магии',
    blurb:
      'ПАССИВ: Вероятность отразить опасную магическую атаку обратно в заклинателя.',
    calc: {
      potency: '5% за уровень',
    },
  },
  shield_block: {
    name: 'Блок щитом',
    blurb:
      'Ненадолго блокирует почти все виды атак ближнего боя. Необходимо иметь экипированный щит.',
    calc: {
      apCost: '35 - Уровень',
      duration: '0.5 + Уровень*( Си/250 )',
    },
  },
  suture: {
    name: 'Наложение швов',
    blurb: 'Останавливает кровотечение и восстанавливает немного ОЗ.',
    calc: {
      apCost: '65 - Уровень*2',
      potency: '20 + Уровень*( Об + Лв )',
      healing: '10 + Уровень*( Об/8 + Ма/10 )',
    },
  },

  // Bow Use
  arrow_shot: {
    name: 'Выстрел из лука',
    blurb: 'Обычный выстрел из лука.',
    calc: {
      range: '4 + Уровень дальности',
      apCost: '50 - Уровень*2',
      damage: '65 + Уровень*( Вс/8 + Лв/10 + Об/12 )',
    },
  },
  blunt_arrow: {
    name: 'Тупоконечная стрела',
    blurb: 'Ненадолго оглушает цель.',
    calc: {
      range: '4 + Уровень дальности',
      apCost: '80 - Уровень*2',
      damage: 'Уровень*( Вс/6 + Лв/10 )',
      duration: '0.5 + Уровень*( Вс/250 + Лв/350 )',
    },
  },
  chain_pull: {
    name: 'Стрела с цепью',
    blurb:
      'Атака стрелой с крепящейся к ней цепью. Может перетягивать врагов в другое место.',
    calc: {
      range: '4 + Уровень дальности',
      apCost: '70 - Уровень*2',
      damage: 'Уровень*( Вс/6 + Лв/10 )',
      potency: '1 + Уровень*( Си/80 )',
    },
  },
  leg_shot: {
    name: 'Выстрел в ногу',
    blurb: 'Цель получает урон и состояние замедления.',
    calc: {
      range: '4 + Уровень дальности',
      apCost: '50 - Уровень*2',
      damage: 'Уровень*( Вс/6 + Лв/10 )',
      duration: '1 + Уровень*( Вс/150 + Лв/250 )',
    },
  },
  multi_shot: {
    name: 'Мультивыстрел',
    blurb: 'Стрелы летят не только в цель, но и в ближайших врагов.',
    calc: {
      range: '4 + Уровень дальности',
      apCost: '70 - Уровень*2',
      charges: '2 + Уровень/2',
      damage: '50 + Уровень*( Вс/12 + Лв/14 + Об/16 )',
    },
  },
  range: {
    name: 'Дальность',
    blurb:
      'ПАССИВ: С каждым новым уровнем дальность всех выстрелов из лука увеличивается на 1. (Внимание: из лука не получится выстрелить в ближнем бою.)',
    calc: {
      potency: 'Уровень * 1',
    },
  },
  razor_shot: {
    name: 'Режущая стрела',
    blurb:
      'Стрела с приспособленным для нанесения ран широким наконечником. Цель получает урон и состояние кровотечения.',
    calc: {
      range: '4 + Уровень дальности',
      apCost: '50 - Уровень*2',
      damage: 'Уровень*( Лв/10 )',
      potency: '15 + Уровень*( Вс/40 + Лв/40 )',
    },
  },

  // Conjuration
  mana_node: {
    name: 'Узел маны',
    blurb: 'ПАССИВ: Бонус к мане и притяжению магии.',
    calc: {
      maBonus: 'Уровень * 2%',
      mgBonus: 'Уровень / 3 ',
    },
  },
  summon_dwarf: {
    name: 'Призыв дворфа',
    blurb:
      'Призыв дворфа-защитника, чьи характеристики соответствуют уровню обучения.',
    calc: {
      mpCost: '150 + Уровень*25;',
      potency: 'Уровень/2',
      duration: '40 + Уровень*5',
    },
  },
  summon_elf: {
    name: 'Призыв эльфа',
    blurb:
      'Призыв эльфа-лучника, чьи характеристики соответствуют уровню обучения.',
    calc: {
      mpCost: '150 + Уровень*25;',
      potency: 'Уровень/2',
      duration: '40 + Уровень*5',
    },
  },
  summon_faerie: {
    name: 'Призыв феи',
    blurb:
      'Призыв феи-целительницы, чьи характеристики соответствуют уровню обучения.',
    calc: {
      mpCost: '150 + Уровень*25;',
      potency: 'Уровень/2',
      duration: '40 + Уровень*5',
    },
  },
  summon_option: {
    name: 'Призыв опциона',
    blurb:
      'Опцион кружит вокруг союзника, каждые 3 секунды нанося урон ближайшему врагу. Он способен один раз поглотить урон от любой атаки по площади.',
    calc: {
      mpCost: '10 + Уровень*3',
      damage: '65 + Уровень*( Об/10 + Ма/10  )',
      charges: '3 + Уровень',
    },
  },
  teleport: {
    name: 'Телепортация',
    blurb:
      'Мгновенное перемещение в нужную точку — при путешествии во времени персонаж теряет немного ОЗ.',
    calc: {
      range: '2 + Уровень/2',
      hpCost: '12% макс. - 1% за уровень',
      mpCost: '15 * Расстояние',
    },
  },
  void_spike: {
    name: 'Шипы бездны',
    blurb:
      'Резко искажает гравитацию в нужной точке, нанося повреждения всем ближайшим физическим телам.',
    calc: {
      range: '4 + Уровень/2',
      apCost: '35 - Уровень',
      mpCost: '5 + Уровень*4',
      damage: '65 + Уровень*( Ма/8 + Об/10 + Вс/12 )',
    },
  },

  // Illusion
  angel_mask: {
    name: 'Маска ангела',
    blurb: 'ПАССИВ: Ближайшие союзники получают вдохновение и бонус к воле.',
    calc: {
      potency: '2 +  Уровень',
    },
  },
  blurred_form: {
    name: 'Расплывчатость',
    blurb:
      'ПАССИВ: Повышение вероятности уклониться от атак, а также стойкости тела и духа.',
    calc: {
      potency: '1% за уровень',
    },
  },
  devil_mask: {
    name: 'Маска дьявола',
    blurb: 'Распугивает ближайших врагов.',
    calc: {
      mpCost: '30 + Уровень*5',
      duration: '0.5 + Уровень*( Ма/333 + Об/333 )',
    },
  },
  faux_self: {
    name: 'Двойники!',
    blurb:
      'Создание двух иллюзий по образу и подобию заклинателя. Каждый из двойников получает по 10% характеристик хозяина за уровень, но урон они наносить не способны.',
    calc: {
      mpCost: '40 + ( Уровень*5 )',
      potency: 'Уровень/2',
      duration: '50 + Уровень*3',
    },
  },
  infect_mind: {
    name: 'Заражение разума',
    blurb:
      'Разум жертвы переполняют иллюзии страшных мук — у нее открывается кровотечение.',
    calc: {
      range: '2 + Уровень',
      mpCost: '20 + Уровень*5',
      potency: '15 + Уровень*( Об/40 + Ма/40 )',
    },
  },
  psyche_out: {
    name: 'Нервный срыв',
    blurb:
      'Цель и некоторые ближайшие враги выходят из себя и яростно атакуют случайные объекты.',
    calc: {
      range: '3 + Уровень/3',
      mpCost: '20 + Уровень*2',
      duration: '0.5 + Уровень*( Вл/250 + Вс/350 )',
    },
  },
  sleight_of_hand: {
    name: 'Ловкость рук',
    blurb: 'Зачарование цели, из-за чего она теряет магическую силу.',
    calc: {
      range: '2 + Уровень',
      apCost: '40 - Уровень*2',
      mpCost: '20 + Уровень*2',
      potency: '100 + Уровень*( Ма/2 + Лв/4 )',
    },
  },

  // Melee Combat
  armor_break: {
    name: 'Пробивание брони',
    blurb:
      'Класс брони врага понижается — эффект может суммироваться до тех пор, пока общий показатель КБ не станет равен 0.',
    calc: {
      apCost: '45 - Уровень*2',
      potency: '10 + Уровень*( Си/12 + Лв/15 )',
    },
  },
  mortal_strike: {
    name: 'Добивание',
    blurb:
      'Цель должна быть замедлена или оглушена. При убийстве врага восстанавливаются показатели ОД.',
    calc: {
      apCost: '90 - Уровень',
      damage: '120 + Уровень*( Си/2 + Лв/3 + Вл/4 )',
    },
  },
  roundhouse: {
    name: 'Удар наотмашь',
    blurb:
      'Все, кто рядом, получают урон. Все, кто в пределах двух ближайших ячеек, получают половину урона.',
    calc: {
      apCost: '70 - Уровень',
      damage: '60 + Уровень*( Лв/3 + Си/4 )',
    },
  },
  sky_hammer: {
    name: 'Небесный молот',
    blurb:
      'Вознеситесь ввысь и обрушьтесь на землю. Все ближайшие враги будут отброшены назад.',
    calc: {
      apCost: '80 - Уровень',
      damage: '65 + Уровень*( Си/8 + Лв/10 )',
      potency: '1 + Уровень/3',
    },
  },
  slam: {
    name: 'Мощный удар',
    blurb: 'Цель получает урон и отступает.',
    calc: {
      apCost: '45 - Уровень*2',
      damage: 'Уровень*( Си/6 + Лв/10 )',
      potency: '1 + Уровень*( Си/120 )',
    },
  },
  slash: {
    name: 'Режущий удар',
    blurb: 'Цель получает урон и состояние кровотечения.',
    calc: {
      apCost: '65 - Уровень*2',
      damage: 'Уровень*( Си/8 )',
      potency: '20 + Уровень*( Лв/24 + Об/50 )',
    },
  },
  taunt: {
    name: 'Насмешка',
    blurb: 'Цель и некоторые ближайшие противники впадают в ярость.',
    calc: {
      apCost: '35 - Уровень',
      mpCost: '5 + Уровень',
      duration: '1 + Уровень*( Вл/225 + Вс/275 )',
    },
  },

  // Movement
  adrenaline: {
    name: 'Адреналин',
    blurb: 'Магическая сила преобразуется в очки действия.',
    calc: {
      mpCost: '150 - Уровень*3',
      potency: '10 + Уровень*( Си/12 )',
    },
  },
  freedom: {
    name: 'Свобода',
    blurb: 'ПАССИВ: Повышает стойкость тела.',
    calc: {
      potency: 'Уровень*3%',
    },
  },
  leap: {
    name: 'Прыжок',
    blurb:
      'Можно без веры (хотя и она не повредит). Позволяет перепрыгивать через юниты.',
    calc: {
      range: '2 + Уровень/2',
      apCost: 'Расстояние*( макс.ОД/макс.дальность )',
    },
  },
  preparation: {
    name: 'Подготовка',
    blurb: 'ПАССИВ: Бонус к максимуму ОД.',
    calc: {
      potency: 'Уровень*2',
    },
  },
  quickness: {
    name: 'Проворство',
    blurb: 'ПАССИВ: Бонус к скорости.',
    calc: {
      potency: 'Уровень / 3',
    },
  },
  stance_dancing: {
    name: 'Танец стоек',
    blurb:
      'Защитная стойка снижает как получаемый (-3% за уровень), так и наносимый (+3% за уровень) урон. Атакующая стойка повышает наносимый (100% + 3% за уровень) и получаемый (150% - 3% за уровень) урон',
    calc: {
      apCost: '70',
      potency: '3% за уровень',
    },
  },
  tussle: {
    name: 'Драка',
    blurb: 'Швырнуть врага и нанести ему урон. Противники меняются местами.',
    calc: {
      apCost: '80 - Уровень*3 - Лв/4',
      damage: '30 + Уровень*(Си/6 + Лв/8 + Об/12',
    },
  },

  // Spirit
  bless: {
    name: 'Благословение',
    blurb:
      'За время действия цель получает немного ОЗ каждую секунду, но очки ее способностей снижаются (2% за уровень).',
    calc: {
      mpCost: '20 + Уровень * 3',
      healing: '1 + Уровень*( Вл/60 + Ма/120 )',
      potency: 'Уровень*2%',
    },
  },
  healing_prayer: {
    name: 'Молитва исцеления',
    blurb: 'Союзник восстанавливает немного ОЗ.',
    calc: {
      mpCost: 'Уровень*10',
      potency: '40 + Уровень*( Вл/2 )',
    },
  },
  holy_force: {
    name: 'Натиск богов',
    blurb:
      'Божественное вмешательство сметает ближайших врагов и исцеляет ближайших союзников.',
    calc: {
      apCost: '40 - Уровень',
      mpCost: '40 + 2*( Уровень/3 )',
      healing: '20 + Уровень*( Вл/24 + Ма/60 )',
      potency: '1 + Уровень/10',
    },
  },
  iron_will: {
    name: 'Железная воля',
    blurb: 'ПАССИВ: Повышает стойкость духа.',
    calc: {
      potency: 'Уровень*3%',
    },
  },
  mystic_focus: {
    name: 'Сила в магии',
    blurb: 'Очки действия преобразуются в магическую силу.',
    calc: {
      apCost: '80 - Уровень',
      potency: '50 + Уровень*( Вл/5 )',
    },
  },
  sanctuary: {
    name: 'Убежище',
    blurb:
      'Каждую секунду эффекта союзники на затронутой площади восстанавливают немного ОЗ.',
    calc: {
      range: '1 + Уровень/2',
      mpCost: '40 + Уровень*6',
      healing: 'Уровень*( Вл/10 )',
    },
  },
  send_mana: {
    name: 'Поделиться маной',
    blurb: 'Цель получает немного магической силы.',
    calc: {
      range: '1 + Уровень/2',
      apCost: '45',
      mpCost: 'Макс. MС * (30-Уровень)/100',
      potency: 'Макс. MС * (10+Уровень)/100',
    },
  },
};
