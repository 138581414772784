import * as PIXI from 'pixi.js';
import { EventEmitter } from 'events';
import ShopActions from '~/flux/actions/ShopActions';
import ShopStore from '~/flux/stores/ShopStore';
import GameItemIcon from '~/components/common/GameItemIcon';
import EquipmentDisplay from '~/components/common/EquipmentDisplay';
import CanvasTools from '~/view/CanvasTools';
import { Colors, Config } from '~/constants';
import text from '~/text';

const ShopInventoryShelves = function () {
  PIXI.Container.call(this);

  this.event_emitter = new EventEmitter();

  this.dispose = () => {
    this.event_emitter.removeAllListeners();
    for (const icon of _icons) {
      icon?.dispose();
    }
    _icons = null;
    destroyBuyButton();
    this.destroy();
  };

  let _shopInventory = ShopStore.getAll().shopInventory;
  let _itemToBuy;
  let _icons = [];
  let _buyBtn;
  const myWidth = window.innerWidth * 0.42;

  const hideBuyButton = (this.hidePopups = () => {
    if (_buyBtn) {
      TweenMax.to(_buyBtn.scale, 0.15, {
        x: 0,
        y: 0,
        onComplete: destroyBuyButton,
        onCompleteParams: [_buyBtn],
      });
    }
  });

  const destroyBuyButton = () => {
    if (_buyBtn) {
      TweenMax.killTweensOf(_buyBtn);
      _buyBtn.tap = _buyBtn.click = null;
      if (_buyBtn.parent) {
        _buyBtn.parent.removeChild(_buyBtn);
      }
      _buyBtn = null;
    }
  };

  const makeBuyButton = (event) => {
    destroyBuyButton();

    var btn = (_buyBtn = new PIXI.Sprite());
    btn.texture = PIXI.utils.TextureCache['armory/equip_arrow.png'];
    btn.anchor.x = btn.anchor.y = 0.5;

    btn.width = 78;
    btn.scale.y = btn.scale.x;

    // flip it horizontally, so it points right
    btn.scale.x *= -1;

    var unequipText = new PIXI.Text(text('ui.buy'), {
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      fontSize: '36px',
      fill: 0x00ff00,
      dropShadow: true,
      dropShadowDistance: 4,
      align: 'center',
    });
    unequipText.anchor.x = unequipText.anchor.y = 0.5;
    btn.addChild(unequipText);

    // apparently we have to flip this too
    unequipText.scale.x *= -1;

    TweenMax.from(btn.scale, 0.2, { x: 0, y: 0 });

    const item_icon = event.target;
    item_icon.addChild(btn);

    // make sure our game item icon is ontop of other icons
    item_icon.parent.addChild(item_icon);

    btn.interactive = btn.buttonMode = true;
    btn.tap = btn.click = () => {
      event.stopPropagation();

      CanvasTools.makeFaerieSpinners(item_icon, 8);

      hideBuyButton();
      ShopActions.confirmItemPurchase(_itemToBuy);

      this.event_emitter.emit('ITEM_PURCHASE_CONFIRMED');
    };

    _itemToBuy = item_icon.getItem();
  };

  const onItemIconClick = (event) => {
    makeBuyButton(event);
  };

  // init
  const shelf = new PIXI.Graphics();
  shelf.beginFill(0xffffff);
  shelf.drawRect(0, 0, myWidth, 3);
  shelf.endFill();
  shelf.x = -window.innerWidth * 0.008;
  shelf.y = window.innerHeight * 0.05;
  this.addChild(shelf);
  // init GameItemIcons
  for (let i = 0; i < _shopInventory.length; ++i) {
    if (!_shopInventory[i]) {
      continue;
    }

    var icon = new GameItemIcon(_shopInventory[i], {
      frame: false,
      showBlackwash: false,
    });
    icon.setScale(55);
    icon.x = ((i + 0.82) * myWidth) / (_shopInventory.length + 1);
    icon.y =
      6.75 * DT_CANVAS_GLOBALS.spacing -
      icon.img.height / 3 -
      55 * (1 - icon.img.scale.y) * (1 - icon.img.anchor.y);

    // do some custom nudging to make the items look like they're sitting on the shelf
    const item = icon.getItem();
    const { slot, type } = item;
    const quality_index = EquipmentDisplay.getQualityIndex(item);
    if (slot === 'helmet') {
      switch (type) {
        case 'light':
          switch (quality_index) {
            case 1:
              icon.y += 18;
              break;

            case 3:
              icon.y += 16;
              break;

            default:
              icon.y += 12;
          }
          break;

        case 'med':
          switch (quality_index) {
            case 0:
              icon.y += 12;
              break;

            case 3:
              icon.y -= 1;
              break;

            case 4:
              icon.y += 7;
              break;
          }
          break;

        case 'heavy':
          switch (quality_index) {
            case 2:
              icon.y += 4;
              break;

            default:
              icon.y += 5;
          }
          break;
      }
    } else if (slot === 'torsoe') {
      if (type === 'light' && quality_index === 0) {
        icon.y += 8;
      } else {
        icon.y += 3;
      }
    } else if (slot === 'boots') {
      switch (type) {
        case 'light':
          icon.y += 7;
          break;

        default:
          icon.y += 3;
      }
    } else if (slot === 'weapon') {
      switch (type) {
        case 'sword':
          icon.y += 28;
          break;

        case 'hammer':
          icon.y += 26;
          break;

        case 'staff':
          icon.y += 6;
          break;

        case 'dagger':
          icon.y += 15;
          break;
      }
    } else if (slot === 'off_hand') {
      switch (type) {
        case 'shield':
          icon.y += 3;
          break;

        case 'bow':
          icon.y += 13;
          break;

        case 'trinket':
          icon.y += 16;
          break;

        case 'book':
          switch (quality_index) {
            case 1:
              icon.y += 7;
              break;

            case 4:
              icon.y += 14;
              break;

            default:
              icon.y += 10;
          }
      }
    } else if (slot === 'ring') {
      icon.y += 12;
    } else {
      switch (type) {
        case 'hero_xp_boost':
          icon.y += 4;
          break;

        case 'prayer_candle':
          icon.y += 5;
          break;

        case 'equipment_dye':
        case 'miracle_dye':
          icon.y += 3;
          break;
      }
    }

    icon.tap = icon.click = onItemIconClick;

    this.addChild(icon);
    _icons.push(icon);

    const price_tag = new PIXI.Container();
    const priceTag_bg = new PIXI.Sprite();
    if (item.gold_price) {
      priceTag_bg.tint = Colors.GOLD;
    } else if (item.pd_price) {
      priceTag_bg.tint = Colors.PIXIE_DUST;
    }
    priceTag_bg.texture = PIXI.utils.TextureCache['shop/price_tag.png'];
    priceTag_bg.width = Math.min(60, window.innerWidth * 0.04);
    priceTag_bg.scale.y = priceTag_bg.scale.x;
    price_tag.addChild(priceTag_bg);

    const priceText = new PIXI.Text(
      Intl.NumberFormat(Config.LOCALE, {
        maximumFractionDigits: 0,
        notation: 'standard',
      }).format(item.gold_price || item.pd_price),
      {
        fontFamily: 'Courier New',
        fontSize: 12,
        fontStyle: 'bold',
        fill: 0x000000,
      }
    );

    priceText.width = Math.min(priceText.width, priceTag_bg.width * 0.9);
    priceText.scale.y = priceText.scale.x;

    priceText.x = price_tag.width / 2 - priceText.width / 2;
    priceText.y = price_tag.height / 2 - priceText.height / 2;
    price_tag.addChild(priceText);

    price_tag.x = icon.x - price_tag.width / 2;
    price_tag.y = window.innerHeight * 0.052;
    this.addChildAt(price_tag, 0);
  }

  this.event_emitter.on('ITEM_PURCHASE_CONFIRMED', () => {
    for (const icon of _icons) {
      icon?.getTooltip()?.dispose();
    }
  });
};
ShopInventoryShelves.prototype = Object.create(PIXI.Container.prototype);
ShopInventoryShelves.prototype.constructor = ShopInventoryShelves;
export default ShopInventoryShelves;
