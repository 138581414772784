import Audio from '~/Audio';
import Colors from '~/constants/Colors';
import { CavernsActions } from '~/flux/actions';
import { FluxGetters } from '~/flux/stores';
import FloatingTextEffect from '../../effects/FloatingTextEffect';

export default (event) => {
  const tileSprite = event.target.parent;
  if (!tileSprite) {
    return;
  }
  const tile = tileSprite.gamePiece;
  if (!tile.loot) {
    return;
  }

  tileSprite.tileImg.visible = true;

  // TODO: support partial looting when inventory gets full
  // const remaining_space = FluxGetters.getRemainingCavernsRunInventorySpace();
  // const can_totally_loot = remaining_space > (tile.loot.equipment || []).length;
  // if (can_totally_loot) {
  TweenMax.to(tileSprite.lootImg, 0.6, {
    alpha: 0,
    y: -30,
    ease: Quad.easeOut,
    onComplete: () => {
      tileSprite.lootImg && tileSprite.txtPool.put(tileSprite.lootImg);
      tileSprite.lootImg = null;
    },
  });
  // } else {
  //   MessageLog.addMessage(text('ui.no_inventory_space'), Colors.RED);
  // }

  if (tile.loot.pd) {
    Audio.play('crystal_twinkle');
    makeLootText(tileSprite, tile.loot.pd.toString(), Colors.PIXIE_DUST);
  }
  if (tile.loot.gold) {
    // stagger if there's also PD in the loot
    const stagger = !!tile.loot.pd;

    setTimeout(
      (tileSprite, gold_txt) => {
        Audio.play('coin_rattle');
        const textEffect = makeLootText(tileSprite, gold_txt, Colors.GOLD);
        if (stagger) {
          textEffect.x += 11;
          textEffect.y -= 7;
        }
      },
      stagger ? 180 : 0,
      tileSprite,
      tile.loot.gold.toString()
    );
  }
  if (tile.loot.equipment) {
    const equipment =
      typeof tile.loot.equipment === 'object'
        ? Object.values(tile.loot.equipment)
        : tile.loot.equipment;
    equipment.length && Audio.play('equipment_storage');
  }

  CavernsActions.lootTile(tile.uid);
  tile.loot = null;
};

function makeLootText(tileSprite, text, color) {
  const textEffect = new FloatingTextEffect(text, 15, color);
  textEffect.y = -15;
  textEffect.x = 10;
  textEffect.scale.x = Math.min(1.7, 1 / tileSprite.scale.x);
  textEffect.scale.y = Math.min(1.7, 1 / tileSprite.scale.y);
  textEffect.init();
  tileSprite.addChild(textEffect);
  return textEffect;
}
