import { Dispatcher } from 'flux';

const CavernsDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'CAVERNS_DISPATCHER',

  ADD_HERO_TO_CAVERNS_TEAM_SLOT: 'ADD_HERO_TO_CAVERNS_TEAM_SLOT',
  CAVERNS_SCREEN_INIT: 'CAVERNS_SCREEN_INIT',
  CLAIM_PASSIVE_YIELDS: 'CLAIM_PASSIVE_YIELDS',
  DISCARD_LOOT_ITEM: 'DISCARD_LOOT_ITEM',
  DOOR_SPRITE_CLICK: 'DOOR_SPRITE_CLICK',
  INIT_NEW_CAVERNS_MAP: 'INIT_NEW_CAVERNS_MAP',
  LOOT_TILE: 'LOOT_TILE',
  RETURN_TO_SURFACE: 'RETURN_TO_SURFACE',
  SET_PORTAL_LEVEL_TO_CURRENT_DEPTH: 'SET_PORTAL_LEVEL_TO_CURRENT_DEPTH',
  START_CAVERNS_BATTLE_ENGINE: 'START_CAVERNS_BATTLE_ENGINE',

  handleCavernsAction: function (action) {
    this.dispatch({
      source: CavernsDispatcher.NAME,
      action,
    });
  },
});
export default CavernsDispatcher;
