export default {
  level: {
    full: 'Level',
    brief: 'L',
  },

  learning: 'Learning',
  perception: 'Perception',
  mana: 'Mana',
  toughness: 'Toughness',
  strength: 'Strength',
  willpower: 'Willpower',
  dexterity: 'Dexterity',

  regeneration: 'Regeneration',
  speed: 'Speed',
  magicalGravity: 'Magical Gravity',
  damage: 'Damage',
  armorClass: 'Armor Class',
  dodgeChance: 'Dodge Chance',
  physicalResil: 'Physical Resilience',
  mentalResil: 'Mental Resilience',
  meleeDamage: 'Melee Damage',

  hit_points: 'Hit Points',
  action_points: 'Action Points',
  magic_power: 'Magic Power',
  HP: 'HP',
  AP: 'AP',
  MP: 'MP',
  XP: 'XP',

  range: 'Range',
  hp_cost: 'HP Cost',
  ap_cost: 'AP Cost',
  mp_cost: 'MP Cost',
  charges: 'Charges',
  magic_damage: 'Magic Damage',
  physical_damage: 'Physical Damage',
  melee_damage: 'Melee Damage',
  bow_damage: 'Bow Damage',
  healing: 'Healing',
  potency: 'Potency',
  duration: 'Duration',
  effect_range: 'Effect Range',
  ma_bonus: 'Mana Bonus',
  mg_bonus: 'M.G. Bonus',

  ability_points: {
    full: 'Ability Points',
    brief: 'Abil. Pts',
  },
  att_points: {
    full: 'Attribute Points',
    brief: 'Att. Pts',
  },

  xp_bonus: 'XP Bonus',
  target_time_reduc: 'Potency',
  prayer_time_mod: 'Prayer Time Mod',
  gold_price: 'Gold Price',
  pd_price: 'Pixie Dust Price',
  hours: 'Hours',
  days: 'Days',
  time_remaining: 'Time Remaining',
  alignment: 'Alignment',
};
