<template>
  <div
    v-if="Config.PLATFORM === 'steam_purchase_redirect'"
    class="w-full h-full flex justify-center items-center"
  >
    <DTButton
      type="success"
      class="px-6 py-2"
      @click="closeSteamPurchasePopup"
    >
      {{ text('ui.close') }}
    </DTButton>
  </div>
  <div
    v-else
    id="game_root"
    class="relative w-full h-full"
    :style="`background-color: ${ui_background_color}`"
  >
    <canvas
      id="game_canvas"
      class="absolute z-0 top-0 left-0 w-full h-full"
    ></canvas>
    <div
      id="ui_root"
      class="z-10 w-full h-full flex flex-col justify-between bg-transparent pointer-events-none"
      :key="state.selected_language_key"
    >
      <component
        :is="game_screens[state.current_screen_key] || 'div'"
        id="game_screen_root"
        class="grow w-full z-10"
      />
      <Footer_DOM
        v-if="state.show_ui"
        class="z-20 flex-none h-[27vh]"
      />
    </div>
    <Modals
      class="z-20"
      :selected_language_key="state.selected_language_key"
    />
    <canvas
      id="overlay_canvas"
      class="absolute z-30 top-0 left-0 w-full h-full pointer-events-none"
    ></canvas>
    <div
      v-if="state.show_skip_tutorial_btn"
      id="skip_tutorial_btn"
      class="absolute right-6 bottom-[28%] z-40 text-sm text-white cursor-pointer"
      @click="onSkipTutorialClick"
    >
      [{{ text('tutorial.skip_tutorial') }}]
    </div>
  </div>
</template>

<script setup lang="ts">
import * as PIXI from 'pixi.js';
import { computed, onMounted, reactive, watch } from 'vue';
import Game from 'dt-common/constants/Game';
import { Colors, Config, Screens } from '~/constants';
import {
  ApplicationActions,
  SettingsActions,
  TutorialActions,
  UIActions,
} from '~/flux/actions';
import {
  ApplicationStore,
  GameStateStore,
  TutorialStore,
  UIStore,
} from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import Footer_canvas from '~/view/components/footer/Footer_canvas';
import Footer_DOM from '~/view/components/footer/Footer_DOM/Footer_DOM.vue';
import Header_canvas from '~/view/components/header/Header_canvas';
import MessageLog_canvas from '~/view/components/MessageLog_canvas';
import Modals from '~/view/components/modals/index.vue';
import game_screens from '~/view/game-screens';
import { DTButton } from '~/view/components/common/DOM';

// some special initializatinos for Yandex
if (Config.PLATFORM === 'yandex') {
  // disable right-click context menu
  document.addEventListener('contextmenu', (e) => e.preventDefault());

  // on Yandex no text anywhere should be selectable
  const style = document.createElement('style');
  style.textContent = `
    body {
      user-select: none !important;
      -webkit-user-select: none !important; /* webkit (safari, chrome) browsers */
      -moz-user-select: none !important; /* mozilla browsers */
      -khtml-user-select: none !important; /* webkit (konqueror) browsers */
      -ms-user-select: none !important; /* IE10+ */
  }
  `;
  document.head.appendChild(style);

  // disable Firefox's default drag-and-drop behavior
  window.addEventListener('dragstart', (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  });

  // use Yandex SDK language detection, unless the user has manually set the language
  if (!localStorage.getItem('dt_locale'))
    ApplicationStore.once(ApplicationStore.YANDEX_SDK_READY, () => {
      const yandex_locale = ysdk?.environment?.i18n?.lang;
      if (yandex_locale !== Config.LOCALE)
        SettingsActions.changeBrowserLanguage(yandex_locale);
    });
}
// commented out for now, because the steamworks API always returns 'english'
//  else if (Config.PLATFORM === 'steam') {
//   // steam language detection
//   (async () => {
//     const { steam_game_language } =
//       await window.steamContext.getSteamPlayerData();
//     console.log({ steam_game_language });
//     const mapped_steam_language =
//       Config.STEAM_LANGUAGE_MAP[steam_game_language];
//     if (Config.AVAILABLE_LANGUAGES.includes(mapped_steam_language)) {
//       SettingsActions.changeBrowserLanguage(mapped_steam_language, false);
//     }
//   })();
// }

function closeSteamPurchasePopup() {
  window.close();
}

const { ASSETS_PATH = 'assets' }: { ASSETS_PATH: string } = process.env;
const SPRITESHEETS_PATH: string = `${ASSETS_PATH}/img/spritesheets`;

let _assets_loaded = false;
let _canvas_footer;
let _canvas_header;
let _listeners_added;
let _pixi_app;
let _pixi_overlay_app;
let _stage_border;

const state = reactive({
  current_screen_key: null,
  selected_language_key: 0, // so we can re-render the ui_root when language changes
  show_skip_tutorial_btn: false,
  show_ui: false,
});

const ui_background_color = computed(() => {
  switch (state.current_screen_key) {
    case Screens.INN:
    case Screens.ARENA_LOBBY:
      switch (UIStore.getAll().current_game_mode) {
        case Game.GAME_MODES.GAME_MODE_pvpAuto:
          return '#011a04';
        case Game.GAME_MODES.GAME_MODE_pvpLive:
          return '#290507';
        default:
          return '#000';
      }
    default:
      return '#000';
  }
});

watch(
  () => state.show_ui,
  (val, old_val) => {
    if (val && !old_val) {
      makeCanvasHeader();
      makeCanvasFooter();
    }
  }
);

onMounted(() => {
  if (!_pixi_app) {
    _pixi_app = CanvasTools.makePixiRenderer({
      canvas_id: 'game_canvas',
    });
    _pixi_overlay_app = CanvasTools.makePixiRenderer({
      canvas_id: 'overlay_canvas',
    });
    _pixi_app.stage.interactive = true;
    _pixi_overlay_app.stage.interactive = false;
    _pixi_overlay_app.stage.interactiveChildren = false;

    // TODO(@robertlombardo): bad, bad ugly globals
    window.DT_CANVAS_GLOBALS = {
      stage: _pixi_app.stage,
      overlay_stage: _pixi_overlay_app.stage,
      spacing: Math.round(window.innerHeight * 0.01),
      _pixi_app,
    };

    // prevent canvas interactions from messing with chat (DOM) focus state
    _pixi_app.stage.mousedown = _pixi_app.stage.touchstart = () => {
      const chat_input = document.getElementById('chat_input');
      const chat_body_scroll = document.getElementById('chat_body_scroll');
      chat_input && chat_input.blur();
      chat_body_scroll && chat_body_scroll.focus();
    };

    if (!_assets_loaded) {
      _pixi_app.loader
        .add([
          `${SPRITESHEETS_PATH}/ss1.json`,
          `${SPRITESHEETS_PATH}/ss2.json`,
          `${SPRITESHEETS_PATH}/ss3.json`,
          `${ASSETS_PATH}/courier_new_bmfont.xml`,
        ])
        .load(onAssetsLoaded);
    }
  }

  MessageLog_canvas.init();

  if (!_listeners_added) {
    // resize handling
    window.addEventListener('resize', Tools.debounce(handleResize, 300));
    window.addEventListener(
      'orientationchange',
      Tools.debounce(handleResize, 500)
    );
    window.addEventListener('language_change', () => {
      ++state.selected_language_key;
      if (state.show_ui) {
        makeCanvasHeader(false);
        _canvas_header.changeScreenLabel();
        makeCanvasFooter({ doTransition: false });
      }
    });

    // flux store listeners
    GameStateStore.on(GameStateStore.GOT_GAME_STATE, onGotGameState);
    TutorialStore.on(TutorialStore.TUTORIAL_BOX_CREATED, () => {
      state.show_skip_tutorial_btn = true;
    });
    TutorialStore.on(TutorialStore.TUTORIAL_BOX_DESTROYED, () => {
      state.show_skip_tutorial_btn = false;
    });
    UIStore.on(UIStore.UI_NAV, onUINav);

    _listeners_added = true;
  }

  UIActions.showModal({ modal_key: 'WelcomeModal' });
});

window.onbeforeunload = () => {
  _pixi_app?.destroy(false, {
    children: true,
    texture: true,
    baseTexture: true,
  });
  _pixi_overlay_app?.destroy(false, {
    children: true,
    texture: true,
    baseTexture: true,
  });
};

function onAssetsLoaded() {
  if (!_assets_loaded) ApplicationActions.onAssetsLoaded();

  _assets_loaded = true;

  makeStageBorder();

  if (state.show_ui) {
    makeCanvasHeader();
    makeCanvasFooter();
  }
}

function makeStageBorder(do_transition = true) {
  if (!_assets_loaded) {
    return;
  }

  destroyStageBorder();

  _stage_border = new PIXI.Container();
  _stage_border._width = window.innerWidth;
  _stage_border._height = window.innerHeight;

  CanvasTools.addBorder(
    _stage_border,
    'window_border_horiz.png',
    'window_border_vert.png',
    'window_border_horiz.png',
    'window_border_vert.png',
    DT_CANVAS_GLOBALS.spacing
  );

  // black out perimeter
  var gfx = new PIXI.Graphics();
  gfx.beginFill(Colors.ALMOST_BLACK);
  gfx.drawRect(0, 0, window.innerWidth, DT_CANVAS_GLOBALS.spacing); // top
  gfx.drawRect(
    window.innerWidth,
    DT_CANVAS_GLOBALS.spacing,
    -DT_CANVAS_GLOBALS.spacing,
    window.innerHeight
  ); // right
  gfx.drawRect(
    0,
    window.innerHeight,
    window.innerWidth,
    -DT_CANVAS_GLOBALS.spacing
  ); // bottom
  gfx.drawRect(0, 0, DT_CANVAS_GLOBALS.spacing, window.innerHeight); // left
  _stage_border.addChild(gfx);

  _stage_border.pivot.x = _stage_border._width / 2;
  _stage_border.pivot.y = _stage_border._height / 2;
  _stage_border.x = window.innerWidth / 2;
  _stage_border.y = window.innerHeight / 2;

  if (do_transition) {
    TweenMax.from(_stage_border.scale, 0.9, { x: 1.5, y: 1.5 });
  }
  _pixi_app.stage.addChild(_stage_border);
}
function destroyStageBorder() {
  if (_stage_border) {
    _stage_border.removeChildren();
    _pixi_app.stage.removeChild(_stage_border);
    _stage_border = null;
  }
}

function onGotGameState() {
  const { num_unlocked_heroes } = GameStateStore.getAll();
  if (!state.show_ui && num_unlocked_heroes >= 1) {
    state.show_ui = true;
  }
  if (num_unlocked_heroes === 0) {
    UIActions.uiNav({ screen_id: Screens.ONBOARD_FIRST_HERO_UNLOCK });
  } else if (!state.done_inital_hero_builds_nav) {
    UIActions.uiNav({ screen_id: Screens.HERO_BUILDS });
    state.done_inital_hero_builds_nav = true;
  }
}

function onUINav(new_uiScreenTag, force) {
  if (new_uiScreenTag === Screens.ONBOARD_FIRST_HERO_UNLOCK) {
    state.done_inital_hero_builds_nav = true;
  } else {
    state.show_ui = true;
  }

  // expose the UI after our first (tutorial) battle is complete
  if (!state.show_ui && new_uiScreenTag === Screens.BATTLE_DEBRIEF) {
    state.show_ui = true;
  }

  // update the header
  if (state.show_ui && !_canvas_header) {
    makeCanvasHeader();
  }
  if (state.current_screen_key !== new_uiScreenTag || force) {
    state.current_screen_key = new_uiScreenTag;
    if (_canvas_header) {
      _canvas_header.changeScreenLabel();
    }
  }
}

function makeCanvasHeader(doTransition = true) {
  destroyCanvasHeader();

  _canvas_header = new Header_canvas();
  if (doTransition) {
    _canvas_header.transitionIn();
  }
  _pixi_app.stage.addChild(_canvas_header);
}
function destroyCanvasHeader() {
  if (_canvas_header) {
    _pixi_app.stage.removeChild(_canvas_header);
    _canvas_header.dispose();
    _canvas_header = null;
  }
}

function makeCanvasFooter(options = { doTransition: true }) {
  destroyCanvasFooter();

  _canvas_footer = new Footer_canvas(options);
  _canvas_footer.y = Math.round(
    window.innerHeight * 0.74 - DT_CANVAS_GLOBALS.spacing
  );
  if (options.doTransition) {
    _canvas_footer.transitionIn();
  }
  _pixi_app.stage.addChild(_canvas_footer);
}
function destroyCanvasFooter() {
  if (_canvas_footer) {
    _pixi_app.stage.removeChild(_canvas_footer);
    _canvas_footer.dispose();
    _canvas_footer = null;
  }
}

function handleResize() {
  DT_CANVAS_GLOBALS.spacing = Math.round(window.innerHeight * 0.01);

  if (_assets_loaded) {
    makeStageBorder(false);
    if (state.show_ui) {
      makeCanvasFooter({ doTransition: false, isResize: true });
      makeCanvasHeader(false);
      _canvas_header.changeScreenLabel();
    }
  }
}

function onSkipTutorialClick() {
  TutorialActions.destroyTutorialBox();
  TutorialActions.destroyTutorialSpotlight();
  TutorialActions.skipTutorial();
  state.show_skip_tutorial_btn = false;
}
</script>
