export default {
  level: {
    full: 'Уровень',
    brief: 'УР',
  },

  learning: 'Обучение',
  perception: 'Восприятие',
  mana: 'Мана',
  toughness: 'Выносливость',
  strength: 'Сила',
  willpower: 'Воля',
  dexterity: 'Ловкость',

  regeneration: 'Регенерация',
  speed: 'Скорость',
  magicalGravity: 'Притяжение магии',
  damage: 'Урон',
  armorClass: 'Класс брони',
  dodgeChance: 'Уклонение',
  physicalResil: 'Стойкость тела',
  mentalResil: 'Стойкость духа',
  meleeDamage: 'Урон (ближний бой)',

  hit_points: 'Очки здоровья',
  action_points: 'Очки действия',
  magic_power: 'Магическая сила',
  HP: 'ОЗ',
  AP: 'ОД',
  MP: 'МС',
  XP: 'ОО',

  range: 'Дальность',
  hp_cost: 'Расход ОЗ',
  ap_cost: 'Расход ОД',
  mp_cost: 'Расход МС',
  charges: 'Заряды',
  magic_damage: 'Магический урон',
  physical_damage: 'Физический урон',
  melee_damage: 'Урон (ближний бой)',
  bow_damage: 'Урон (дальний бой)',
  healing: 'Исцеление',
  potency: 'Эффективность',
  duration: 'Продолжительность',
  effect_range: 'Уровень дальности',
  ma_bonus: 'Бонус маны',
  mg_bonus: 'Бонус МС',

  ability_points: {
    full: 'Очки способностей',
    brief: 'ОС',
  },
  att_points: {
    full: 'Очки характеристик',
    brief: 'ОХ',
  },

  xp_bonus: 'Бонус к опыту',
  target_time_reduc: 'Эффективность',
  prayer_time_mod: 'Изменение времени молитвы',
  gold_price: 'Цена (золото)',
  pd_price: 'Цена (пыльца пикси)',
  hours: 'Часов',
  days: 'Дней',
  time_remaining: 'Осталось времени',
  alignment: 'Выравнивание',
};
