export default {
  unlock_a_hero:
    'Welcome to Dungeon Team! Start by unlocking your first hero...',
  basic_intro_1:
    "In Dungeon Team we'll build squads of heroes to fight in PvE and PvP auto-battles.",
  basic_intro_2:
    "When you're ready, you can play live PvP Arena matches where you control each hero.",
  nav_to_caverns_tip:
    "Let's start by heading to the Caverns to gain some XP fighting giant rats.",
  caverns_explained_1:
    'In the Caverns your heroes will fight monsters automatically.',
  caverns_explained_2:
    "When a unit's Action Points (AP) are full, they get to take a turn.",
  caverns_explained_3:
    'Once the room is cleared, you can move through doors:\n\n/\n\nor stairs:\n\n> (down)\n< (up)',
  caverns_explained_4:
    'When you\'re ready to end this run, click "Return to Surface."',
  nav_to_edit_hero: "Now let's equip this hero with some special abilities!",
  equip_ability_tip:
    'Clicking a plus sign will level up the ability and equip it.',
  nav_to_armory_tip:
    "Great! Now let's get your hero some companions to fight with. Head to the Armory.",
  unlock_2nd_hero_tip:
    'Here you can unlock new heroes to join your team.\n\nUnlocking your 3rd hero will open up the Inn for PvP auto-battles!',
  open_context_info_panel_tip:
    "You're ready to play. Just remember you can get helpful info for each area of the game up here. Good luck!",

  no_abilities_equipped: 'No abilities equipped!',

  wait_btn_tip:
    'The battle is paused.\n\n' +
    "It's your turn, but you have no Action Points (AP).\n\n" +
    "Click the 'Wait' button to end your turn and start recharging AP.",
  attack_tip: 'Click here then click an enemy to perform a basic attack.',
  move_tip: 'Click here then a click a tile to move.',
  zoom_tip: 'You can zoom in on the action using the mouse wheel. Try it now.',
  edit_hero_tip: 'Click your hero to add some abilities ----->',
  cycle_ability_trees_tip:
    "Click a panel to cycle through\nyour hero's ability trees.",
  final_tip:
    "That's it - you're ready to play! Don't be afraid to ask for help in the chat!\n" +
    "When you're done adding abilities, click the ARENA button to start your first real battle.\n" +
    'Good luck, and thanks for playing Dungeon Team!',
  tutorial_engine_error:
    "Something went wrong with the tutorial engine. We'll have to log you back in...",

  skip_tutorial: 'Skip tutorial',
};
