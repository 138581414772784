import * as PIXI from 'pixi.js';
import Audio from '~/Audio';
import GameItemIcon from '~/components/common/GameItemIcon';
import { Colors, Config } from '~/constants';
import { CurrencyStore, GameStateStore } from '~/flux/stores';
import { BattleDebriefActions, EditHeroActions } from '~/flux/actions';
import PlayerStore from '~/flux/stores/PlayerStore';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';

const ICON_SCALE = 54;

const BattleRewards = function (_debrief, parent_width) {
  PIXI.Container.call(this);

  let _bg;
  let _icons;
  let _claimButton;

  this.dispose = () => {
    CurrencyStore.removeListener(
      CurrencyStore.GOLD_REWARD_CLAIMED,
      onGoldRewardClaimed
    );
    CurrencyStore.removeListener(
      CurrencyStore.PD_REWARD_CLAIMED,
      onPDRewardClaimed
    );
    GameStateStore.removeListener(
      GameStateStore.ITEM_REWARD_CLAIMED,
      onItemRewardClaimed
    );

    destroyBG();
    destroyClaimButton();
    destroyIcons();

    this.destroy();
  };

  const destroyBG = () => {
    if (_bg) {
      _bg.tap = _bg.click = null;
      _bg.parent.removeChild(_bg);
    }
  };

  const destroyIcons = () => {
    if (_icons) {
      for (const icon of _icons) {
        icon.tap = icon.click = null;
        if (icon.dispose) {
          icon.dispose();
        }
        this.removeChild(icon);
      }
      _icons = null;
    }

    if (this.rewardsLabel) {
      this.removeChild(this.rewardsLabel);
      this.rewardsLabel = null;
    }
  };

  this.makeBG = () => {
    _bg = new PIXI.Graphics();
    _bg.beginFill(0x000000, 0.01);
    _bg.drawRect(0, 0, window.innerWidth, window.innerHeight);
    _bg.endFill();
    _bg.interactive = true;
    _bg.tap = _bg.click = () => {
      hideClaimButton();
      EditHeroActions.selectItem(null);
    };
    this.parent.addChildAt(_bg, 0);
  };

  const checkForInventoryRoom = () => {
    const inventory = GameStateStore.getAll().gameState.inventory;
    for (var i = 0; i < inventory.bags.length; ++i) {
      for (var j = 0; j < inventory.bags[i].items.length; ++j) {
        if (inventory.bags[i].items[j] === null) {
          return true;
        }
      }
    }
    return false;
  };

  const tweenOutIcon = (icon) => {
    if (!icon) {
      return;
    }

    TweenMax.to(icon, 0.7, {
      y: icon.y - window.innerHeight * 0.06,
      alpha: 0,
      ease: Quad.easeOut,
      onComplete: (deadIcon) => {
        if (deadIcon.dispose) {
          deadIcon.dispose();
        }

        if (deadIcon.parent) {
          deadIcon.parent.removeChild(deadIcon);
        }
      },
      onCompleteParams: [icon],
    });
  };

  const hideClaimButton = () => {
    if (_claimButton) {
      TweenMax.to(_claimButton.scale, 0.1, {
        x: 0,
        y: 0,
        onComplete: destroyClaimButton,
        onCompleteParams: [_claimButton],
      });
    }
  };

  const destroyClaimButton = (deadButton) => {
    if (deadButton && deadButton.parent) {
      deadButton.parent.removeChild(deadButton);
    }
  };

  const onItemRewardIconTouch = (event) => {
    if (checkForInventoryRoom()) {
      BattleDebriefActions.claimItemReward(
        _debrief.battle_id,
        event.target.getItem().uid
      );
    }
  };

  const onGoldRewardIconTouch = () => {
    BattleDebriefActions.claimGoldReward(_debrief.battle_id);
  };

  const onPixieDustRewardIconTouch = () => {
    BattleDebriefActions.claimPixieDustReward(_debrief.battle_id);
  };

  let pdIcon;
  let goldIcon;

  const makeRewardIcons = () => {
    destroyIcons();

    const { caverns_data } = _debrief;
    const rewards = caverns_data
      ? {
          gold: caverns_data.goldGains,
          items: caverns_data.equipmentGains,
          pixieDust: caverns_data.pdGains,
        }
      : _debrief.rewards[PlayerStore.getAll().loggedInPlayerId];

    _icons = [];

    if (rewards?.gold) {
      // pixie dust reward
      goldIcon = new PIXI.Sprite();
      goldIcon.texture = PIXI.utils.TextureCache['gold.png'];
      goldIcon.width = ICON_SCALE * 0.8;
      goldIcon.scale.y = goldIcon.scale.x;
      goldIcon.anchor.x = goldIcon.anchor.y = 0.5;

      const gold_txt = Tools.formatGameCurrency(rewards.gold);
      const valueText = new PIXI.Text(gold_txt, {
        fontFamily: 'Courier New',
        fontSize: `${ICON_SCALE * 1.1}px`,
        fontWeight: 'bold',
        fill: 0xffffff,
        dropShadow: true,
        dropShadowColor: 0,
        dropShadowDistance: 10,
      });
      valueText.x = Math.round(goldIcon.width * 0.95);
      valueText.y = Math.round(goldIcon.height * 0.95);
      goldIcon.addChild(valueText);

      goldIcon.interactive = true;
      if (!_debrief?.caverns_data) {
        goldIcon.buttonMode = true;
        goldIcon.tap = goldIcon.click = onGoldRewardIconTouch;
      }

      _icons.push(goldIcon);
      goldIcon.is_gold = true;
    }

    if (rewards?.pixieDust) {
      // pixie dust reward
      pdIcon = new PIXI.Sprite();
      pdIcon.texture = PIXI.utils.TextureCache['pixie_dust.png'];
      pdIcon.width = ICON_SCALE * 0.8;
      pdIcon.scale.y = pdIcon.scale.x;
      pdIcon.anchor.x = pdIcon.anchor.y = 0.5;

      const valueText = new PIXI.Text(rewards.pixieDust.toString(), {
        fontFamily: 'Courier New',
        fontSize: `${ICON_SCALE * 1.1}px`,
        fontWeight: 'bold',
        fill: 0xffffff,
        dropShadow: true,
        dropShadowColor: 0,
        dropShadowDistance: 10,
      });
      valueText.x = Math.round(pdIcon.width * 0.95);
      valueText.y = Math.round(pdIcon.height * 0.95);
      pdIcon.addChild(valueText);

      pdIcon.interactive = true;
      if (!_debrief?.caverns_data) {
        pdIcon.buttonMode = true;
        pdIcon.tap = pdIcon.click = onPixieDustRewardIconTouch;
      }

      _icons.push(pdIcon);
      pdIcon.is_pixie_dust = true;
    }

    const items = rewards?.items;
    if (items) {
      for (const prop in items) {
        const icon = new GameItemIcon(items[prop]);
        icon.setScale(ICON_SCALE);
        _icons.push(icon);
        icon.is_item = true;

        // for adjusting tooltip position
        icon.stage_rect = CanvasTools.getDomAnchor('modal_canvas');

        if (!_debrief?.caverns_data) {
          icon.tap = icon.click = onItemRewardIconTouch;
        }
      }
    }
  };
  makeRewardIcons();

  if (_icons.length) {
    // make label
    const rewardsLabelTxtSize = 16;
    this.rewardsLabel = new PIXI.Text(text('ui.rewards') + ':', {
      fontFamily: 'Courier New',
      fontSize: rewardsLabelTxtSize.toString() + 'px',
      fill: Colors.BRIGHT_YELLOW,
    });
    this.addChild(this.rewardsLabel);

    const pd_icon = _icons.find((icon) => icon.is_pixie_dust);
    if (pd_icon) {
      pd_icon.x = pd_icon.width / 2;
      pd_icon.y =
        this.rewardsLabel.y +
        this.rewardsLabel.height +
        pd_icon.height / 2 +
        10;
      this.addChild(pd_icon);
    }
    const gold_icon = _icons.find((icon) => icon.is_gold);
    if (gold_icon) {
      gold_icon.x =
        (pd_icon ? pd_icon.x + pd_icon.width / 2 + 20 : 0) +
        gold_icon.width / 2;
      gold_icon.y = pd_icon
        ? pd_icon.y
        : this.rewardsLabel.y +
          this.rewardsLabel.height +
          gold_icon.height / 2 +
          10;
      this.addChild(gold_icon);
    }

    const item_icons_container = new PIXI.Container();
    const item_icons = _icons.filter((icon) => icon.is_item);
    item_icons.forEach((icon, i) => {
      const column = i % 15;
      const row = Math.floor(i / 15);
      icon.x = ICON_SCALE / 2 + column * (ICON_SCALE + 6);
      icon.y = row * (ICON_SCALE + 6);
      TweenMax.from(icon, 1.5, { alpha: 0 }).delay(Math.random());
      item_icons_container.addChild(icon);
    });
    item_icons_container.y =
      (pd_icon
        ? pd_icon.y + pd_icon.height / 2 + 10
        : gold_icon
          ? gold_icon.y + gold_icon.height / 2 + 10
          : this.rewardsLabel.y + this.rewardsLabel.height + 10) +
      ICON_SCALE / 2;
    // scale to fit within max width & height
    item_icons_container.width = Math.min(
      item_icons_container.width,
      parent_width - 50
    );
    item_icons_container.scale.y = item_icons_container.scale.x;
    item_icons_container.height = Math.min(item_icons_container.height, 240);
    item_icons_container.scale.x = item_icons_container.scale.y;
    this.addChild(item_icons_container);

    if (!_debrief?.caverns_data && _icons.length > 1) {
      // make "Claim All" button
      const claimAllBtn = (_claimButton = new PIXI.Text(
        `[${text('ui.claim_all')}]`,
        {
          fontFamily: 'Courier New',
          fontSize: CanvasTools.dynamicFontSizeString(10),
          fill: Colors.BRIGHT_YELLOW,
        }
      ));
      claimAllBtn.y = Math.round(this.height + 5);

      claimAllBtn.interactive = claimAllBtn.buttonMode = true;
      claimAllBtn.tap = claimAllBtn.click = () => {
        BattleDebriefActions.claimAllRewards(_debrief.battle_id);
      };
      this.addChild(claimAllBtn);
    }
  }

  const onGoldRewardClaimed = (battle_id) => {
    if (battle_id === _debrief.battle_id) {
      tweenOutIcon(goldIcon);
    }
  };

  const onPDRewardClaimed = (battle_id) => {
    if (battle_id === _debrief.battle_id) {
      tweenOutIcon(pdIcon);
    }
  };

  const onItemRewardClaimed = (itemId) => {
    for (var i = 0; i < _icons.length; ++i) {
      if (_icons[i].getItem && _icons[i].getItem().uid === itemId) {
        tweenOutIcon(_icons[i]);
        break;
      }
    }
    Audio.play('equipment_storage');
  };

  CurrencyStore.on(CurrencyStore.GOLD_REWARD_CLAIMED, onGoldRewardClaimed);
  CurrencyStore.on(CurrencyStore.PD_REWARD_CLAIMED, onPDRewardClaimed);
  GameStateStore.on(GameStateStore.ITEM_REWARD_CLAIMED, onItemRewardClaimed);
};
BattleRewards.prototype = Object.create(PIXI.Container.prototype);
BattleRewards.prototype.constructor = BattleRewards;
export default BattleRewards;
