import * as PIXI from 'pixi.js';
import EquipmentDisplay from '~/components/common/EquipmentDisplay';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';
import Tools from '~/Tools';
import text from '~/text';

const wordWrapWidth = CanvasTools.TOOLTIP_WORD_WRAP_WIDTH;

const ItemTooltip = function (item) {
  try {
    PIXI.Container.call(this);

    // add the title sprite
    const _title = new PIXI.Text(EquipmentDisplay.getName(item), {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(18),
      fontWeight: 'bold',
      fill: Colors.BRIGHT_YELLOW,
      wordWrap: true,
      wordWrapWidth,
    });
    this.addChild(_title);

    // generate the blurb text
    const base_description = text(`equipment.descriptions.${item.type}`) || '';
    let prefix_description =
      item.prefix && text(`equipment.descriptions.${item.prefix}`);
    if (prefix_description && base_description) {
      prefix_description = '\n' + prefix_description;
    }
    let suffix_description =
      item.suffix && text(`equipment.descriptions.${item.suffix}`);
    if (suffix_description && (prefix_description || base_description)) {
      suffix_description = '\n' + suffix_description;
    }
    const blurb_text =
      base_description +
      (prefix_description || '') +
      (suffix_description || '');

    // add the blurb sprite
    const _blurb = new PIXI.Text(blurb_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      fontStyle: 'italic',
      fill: 0xbababa,
      wordWrap: true,
      wordWrapWidth,
    });
    _blurb.y = Math.round(
      _title.y + _title.height + DT_CANVAS_GLOBALS.spacing / 2
    );
    this.addChild(_blurb);

    // horizontal line under the blurb
    const hl = new PIXI.Graphics();
    hl.beginFill(Colors.BRIGHT_YELLOW, 0.2);
    hl.drawRect(0, 0, this.width, 2);
    hl.y =
      blurb_text && blurb_text.length
        ? Math.round(_blurb.y + _blurb.height + 12)
        : Math.round(_title.y + _title.height + 12);
    this.addChild(hl);

    if (item.slot === 'item_container') {
      // add the blurb sprite
      const container_space_text = new PIXI.Text(
        text('equipment.item_container_space')(item.size),
        {
          fontFamily: 'Courier New',
          fontSize: CanvasTools.dynamicFontSizeString(14),
          // fontStyle: 'italic',
          fill: 0xbababa,
          wordWrap: true,
          wordWrapWidth,
        }
      );
      container_space_text.y = Math.round(
        hl.y + hl.height + DT_CANVAS_GLOBALS.spacing / 2
      );
      this.addChild(container_space_text);
    }

    // generate attribute fields
    const _att_fields = [];
    const i = item; // just shorthand
    let meleeModTotal = 0;
    let bowModTotal = 0;

    if (i.slot === 'weapon') {
      meleeModTotal = i.meleeDamageMod
        ? i.meleeDamageMod + (i.damageMod || 0)
        : i.damageMod || 0;
    } else if (i.type === 'bow') {
      bowModTotal = i.bowDamageMod
        ? i.bowDamageMod + (i.damageMod || 0)
        : i.damageMod || 0;
    }

    // magic damage mod
    if (significantVal(i.magicDamageMod)) {
      _att_fields.push(
        makeAttributeField(
          text('game.magic_damage'),
          Math.floor(i.magicDamageMod * 100),
          '%'
        )
      );
    }

    // physical damage mod
    if (significantVal(i.physDamageMod)) {
      _att_fields.push(
        makeAttributeField(
          text('game.physical_damage'),
          Math.floor(i.physDamageMod * 100),
          '%'
        )
      );
    }

    // melee damage mod
    if (significantVal(meleeModTotal)) {
      _att_fields.push(
        makeAttributeField(
          text('game.melee_damage'),
          Math.floor(meleeModTotal * 100),
          '%'
        )
      );
    }

    // bow damage mod
    if (significantVal(bowModTotal)) {
      _att_fields.push(
        makeAttributeField(
          text('game.bow_damage'),
          Math.floor(bowModTotal * 100),
          '%'
        )
      );
    }

    // attributes
    const att_names = [
      // primary
      'armorClass',
      'learning',
      'perception',
      'mana',
      'toughness',
      'strength',
      'willpower',
      'dexterity',
      // scondary
      'dodgeChance',
      'physicalResil',
      'mentalResil',
      'regeneration',
      'speed',
      'magicalGravity',
    ];
    for (const att_name of att_names) {
      const val = item[att_name];
      if (significantVal(val)) {
        const add_percent = [
          'dodgeChance',
          'physicalResil',
          'mentalResil',
        ].includes(att_name)
          ? '%'
          : null;

        _att_fields.push(
          makeAttributeField(text(`game.${att_name}`), val, add_percent)
        );
      }
    }

    // xp bonus
    if (i.xp_bonus) {
      _att_fields.push(
        makeAttributeField(text('game.xp_bonus'), i.xp_bonus * 100, '%', false)
      );
    }

    // prayer candle god alignment
    if (i.alignment) {
      _att_fields.push(
        makeAttributeField(
          text('game.alignment'),
          text(`ui.gods.${i.alignment}`),
          null,
          false,
          Colors[i.alignment]
        )
      );
    }

    // prayer candle target effect
    if (i.target_time_reduc) {
      _att_fields.push(
        makeAttributeField(
          text('game.target_time_reduc'),
          Tools.millisecondsToHHMMSS(i.target_time_reduc),
          null,
          false,
          Colors.BRIGHT_YELLOW
        )
      );
    }

    // prayer candle sender's persistent prayer time reduc
    if (i.prayer_time_mod) {
      _att_fields.push(
        makeAttributeField(
          text('game.prayer_time_mod'),
          i.prayer_time_mod * 100,
          '%',
          false,
          Colors.BRIGHT_YELLOW
        )
      );
    }

    // duration
    if (i.duration) {
      _att_fields.push(
        makeAttributeField(
          text('game.duration'),
          Tools.millisecondsToHHMMSS(i.duration),
          null,
          false,
          Colors.BRIGHT_YELLOW
        )
      );
    }

    // add & position attribute fields
    if (_att_fields.length) {
      for (let i = 0; i < _att_fields.length; ++i) {
        const af = _att_fields[i];

        if (i === 0) {
          af.y = Math.round(hl.y + 14);
        } else {
          const prev_af = _att_fields[i - 1];
          af.y = Math.round(prev_af.y + prev_af.height + 3);
        }

        this.addChild(af);
      }
    }

    this.dispose = () => {
      if (_att_fields) {
        for (const af of _att_fields) {
          af.dispose();
        }
      }

      this.removeChildren();
    };
  } catch (err) {
    logError(
      err,
      Object.assign(
        {
          module: 'components/tooltips/ItemTooltip',
          func: 'constructor',
        },
        item
      )
    );
  }
};
ItemTooltip.prototype = Object.create(PIXI.Container.prototype);
ItemTooltip.prototype.constructor = ItemTooltip;
export default ItemTooltip;

const significantVal = (val) => {
  return val && (val <= -0.01 || val >= 0.01);
};

const makeAttributeField = (
  labelText,
  attValue,
  addedText = null,
  addPlus = true,
  color = null
) => {
  return new ItemAttributeField(
    `${labelText}: `,
    attValue,
    addedText,
    addPlus,
    color
  );
};

const ItemAttributeField = function (
  labelText,
  attValue,
  appendix = null,
  addPlus = true,
  color = null
) {
  try {
    PIXI.Container.call(this);

    let valueText =
      typeof attValue === 'string'
        ? attValue
        : Math.round(attValue) === attValue
          ? attValue.toString()
          : attValue.toFixed(2);

    if (attValue > 0 && addPlus) {
      valueText = '+'.concat(valueText);
    }

    if (appendix) {
      valueText += appendix;
    }

    const label = new PIXI.Text(labelText, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      lineHeight: window.innerWidth > 800 ? 13 : 9,
      fill: 0xffffff,
      align: 'right',
      padding: 2,
    });

    const value = new PIXI.Text(valueText, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      lineHeight: window.innerWidth > 800 ? 13 : 9,
      fill: color || (attValue > 0 ? Colors.AP_GREEN : Colors.HP_RED),
      padding: 2,
    });

    this.addChild(label);
    value.x = label.x + label.width;
    this.addChild(value);

    this.dispose = () => {
      this.removeChildren();
    };
  } catch (err) {
    logError(err, {
      module: 'components/tooltips/ItemTooltip',
      func: 'ItemAttributeField.constructor',
      labelText,
      attValue,
      appendix,
      addPlus,
      color,
    });
  }
};
ItemAttributeField.prototype = Object.create(PIXI.Container.prototype);
ItemAttributeField.prototype.constructor = ItemAttributeField;
