<template>
  <div
    id="caverns_battle_menu"
    class="mx-4 my-3 w-[calc(50%-30px)] h-[26vh] absolute bottom-0 right-0 bg-zinc-700/20 flex pointer-events-none"
  >
    <div class="p-2 grow h-full flex flex-col">
      <!-- controls  -->
      <div class="w-full flex gap-4 justify-center text-sm pointer-events-auto">
        <div class="flex gap-2">
          <label for="auto_explore"> {{ text('ui.auto_explore') }}: </label>
          <input
            id="auto_explore"
            type="checkbox"
            v-model="state.auto_explore"
            class="bg-neutral-200"
            @change="onAutoExploreChange"
          />
        </div>
        <div class="flex gap-2">
          <label
            for="target_depth"
            :class="{ 'text-neutral-400': !state.auto_explore }"
          >
            {{ text('ui.target_depth') }}:
          </label>
          <input
            id="target_depth"
            type="number"
            min="1"
            v-model="state.target_depth"
            :class="{
              'w-[50px] rounded-sm text-black': true,
              'bg-neutral-200': state.auto_explore,
              'bg-neutral-600': !state.auto_explore,
            }"
            @change="onTargetDepthChange"
          />
        </div>
      </div>
      <hr class="my-2 w-full border border-1 border-neutral-600" />

      <!-- looted item icons -->
      <div
        id="looted_equipment_list"
        class="relative w-full grow flex flex-wrap content-start overflow-x-visible overflow-y-auto pointer-events-auto"
      >
        <canvas
          id="loot_canvas"
          ref="loot_canvas"
          class="absolute z-0 w-full h-full"
        ></canvas>

        <div
          v-for="item of state.all_equipment_picked_up"
          :id="`looted_item_icon_${item.uid}`"
          :key="item.uid"
          :style="`width: ${GAME_ITEM_ICON_SIZE_PX}px; height: ${GAME_ITEM_ICON_SIZE_PX}px;`"
          class="relative m-2 pointer-events-none"
        >
          <div
            class="absolute -right-1 -top-3 text-lg font-bold text-red-700 cursor-pointer pointer-events-auto no-select"
            @click="discardLootItem(item.uid)"
          >
            x
          </div>
        </div>
      </div>
    </div>

    <!-- current caverns level, gold & pd totals, and inventory space -->
    <div class="min-w-[210px] h-full flex flex-col justify-between">
      <div
        id="caverns_quick_info"
        class="w-full p-2 pl-6 text-sm"
      >
        <div>{{ text('ui.depth') }}: {{ state.caverns_level }}</div>
        <div
          v-if="state.portal_level"
          class="text-pink-300"
        >
          {{ text('ui.portal_level') }}: {{ state.portal_level }}
        </div>
        <div class="text-yellow-300">
          {{ text('ui.gold') }}:
          {{ Tools.formatGameCurrency(state.total_gold_picked_up) }}
        </div>
        <div class="text-cyan-300">
          {{ text('ui.pixie_dust') }}:
          {{ Tools.formatGameCurrency(state.total_pd_picked_up) }}
        </div>
        <div class="text-white">
          {{ text('ui.inventory_space') }}:
          <span
            :class="
              state.inventory_space_remaining > 0
                ? 'text-green-400'
                : 'text-red-500'
            "
            >{{ state.inventory_space_remaining }}</span
          >
        </div>
      </div>
      <!-- return to surface btn -->
      <div class="w-full flex justify-end items-end">
        <DTButton
          type="danger"
          class="mr-4 mb-4 w-[165px] h-[60px] pointer-events-auto"
          :disabled="state.pending_return_to_surface"
          @click="
            () => {
              state.pending_return_to_surface = true;
              CavernsActions.returnToSurface();
            }
          "
        >
          <DTAnimatedElipsis
            v-if="state.pending_return_to_surface"
            class="text-2xl font-extrabold"
          />
          <span v-else>{{ text('ui.surface') }}</span>
        </DTButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  nextTick,
  onMounted,
  onBeforeUnmount,
  reactive,
  ref,
  watch,
} from 'vue';
import Audio from '~/Audio';
import { CavernsActions } from '~/flux/actions';
import { CavernsStore, FluxGetters, UIStore } from '~/flux/stores';
import text from '~/text';
import GameItemIcon from '~/components/common/GameItemIcon';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import { DTAnimatedElipsis, DTButton } from '~/view/components/common/DOM';

const GAME_ITEM_ICON_SIZE_PX = 48;
const loot_canvas = ref(null);
let _loot_pixi_app;
let _loot_icons;

watch(loot_canvas, (val) => {
  if (val && !_loot_pixi_app) {
    nextTick(() => {
      _loot_pixi_app = CanvasTools.makePixiRenderer({
        canvas_id: 'loot_canvas',
        canvas_ele: val,
      });
      makeLootIcons();
    });
  }
});

const { current_caverns_level, caverns_data } = CavernsStore.getAll();
const { current_game_submode } = UIStore.getAll();

const state = reactive({
  all_equipment_picked_up: [],
  caverns_level: current_caverns_level,
  pending_return_to_surface: false,
  portal_level: caverns_data[current_game_submode].portal_level,
  total_gold_picked_up: 0,
  total_pd_picked_up: 0,
  inventory_space_remaining: FluxGetters.getRemainingCavernsRunInventorySpace(),
  auto_explore: localStorage.getItem('dt_caverns_auto_explore') === 'true',
  target_depth:
    parseInt(
      localStorage.getItem(`dt_caverns_target_depth.${current_game_submode}`) ||
        0,
      10
    ) || caverns_data[current_game_submode].deepest_level,
});

onMounted(() => {
  CavernsStore.on(CavernsStore.BATTLE_INITIALIZED, onBattleInitialized);
  CavernsStore.on(CavernsStore.CAVERNS_PORTAL_LEVEL_SET, onPortalLevelSet);
  CavernsStore.on(CavernsStore.TOTAL_LOOT_PICKED_UP, onCavernsLootPickedUp);
});

onBeforeUnmount(() => {
  CavernsStore.removeListener(
    CavernsStore.BATTLE_INITIALIZED,
    onBattleInitialized
  );
  CavernsStore.removeListener(
    CavernsStore.CAVERNS_PORTAL_LEVEL_SET,
    onPortalLevelSet
  );
  CavernsStore.removeListener(
    CavernsStore.TOTAL_LOOT_PICKED_UP,
    onCavernsLootPickedUp
  );

  destroyLootIcons();
  _loot_pixi_app?.destroy();
  _loot_pixi_app = null;
});

function destroyLootIcons() {
  for (const icon of _loot_icons || []) {
    icon.dispose();
  }
  _loot_icons = null;
}

function makeLootIcons() {
  destroyLootIcons();
  _loot_icons = [];

  for (const loot_item of state.all_equipment_picked_up) {
    // make game item icon & add to stage
    const game_item_icon = new GameItemIcon(loot_item);
    game_item_icon.setScale(GAME_ITEM_ICON_SIZE_PX);
    const { offsetLeft, offsetTop, offsetWidth, offsetHeight } =
      document.getElementById(`looted_item_icon_${loot_item.uid}`);
    game_item_icon.x = offsetLeft + offsetWidth / 2;
    game_item_icon.y = offsetTop + offsetHeight / 2;
    _loot_pixi_app.stage.addChildAt(game_item_icon, 0); // add at 0 so as not to mask faerie spinners on equipment change

    // for adjusting tooltip position
    game_item_icon.stage_rect = CanvasTools.getDomAnchor('loot_canvas');

    // store icon data locally
    _loot_icons.push(game_item_icon);
  }
}

function onBattleInitialized(data) {
  state.caverns_level = CavernsStore.getAll().current_caverns_level;
}

function onCavernsLootPickedUp({ gold, pd, equipment }) {
  state.total_pd_picked_up = pd;
  state.total_gold_picked_up = gold;
  state.all_equipment_picked_up = equipment;

  state.inventory_space_remaining =
    FluxGetters.getRemainingCavernsRunInventorySpace();

  // resize the canvas and Pixi renderer to match the new loot container height
  nextTick(() => {
    const { scrollWidth, scrollHeight } = document.getElementById(
      'looted_equipment_list'
    );
    const loot_canvas = document.getElementById('loot_canvas');
    loot_canvas.style.height = `${scrollHeight}px`;
    _loot_pixi_app.renderer.resize(scrollWidth, scrollHeight);
    nextTick(makeLootIcons);
  });
}

function onPortalLevelSet(data) {
  state.portal_level = data.new_portal_level;
  Audio.play('level_up');
}

function discardLootItem(item_id) {
  CavernsActions.discardLootItem(item_id);
}

function onAutoExploreChange(event) {
  localStorage.setItem(
    'dt_caverns_auto_explore',
    event.target.checked ? 'true' : 'false'
  );
}

function onTargetDepthChange() {
  if (state.target_depth < 1) state.target_depth = 1;
  state.target_depth = Math.floor(state.target_depth);
  localStorage.setItem(
    `dt_caverns_target_depth.${UIStore.getAll().current_game_submode}`,
    state.target_depth
  );
}
</script>
