<!-- eslint-disable-line vue/multi-word-component-names -->
<template>
  <div
    id="caverns_battle_log"
    ref="caverns_battle_log"
    class="w-full max-h-[100%] pt-6 pl-3 overflow-y-auto pointer-events-auto text-xs text-slate-400"
    @scroll="onScroll"
  >
    <BattleLogEntry
      v-for="(entry, i) in state.log_entries"
      :entry_data="entry"
      :key="i"
      class="w-full text-center"
    />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, onUpdated, reactive, ref } from 'vue';
import { CavernsStore } from '~/flux/stores';
import {
  getBattleEventLogEntry,
  getBattleEventLogEntryForAbility,
  getBattleEventLogEntryForCondition,
  getCavernsNewTurnMessage,
} from '~/text';
import BattleLogEntry from './BattleLogEntry.vue';

let _entry_buff = [];
let _entry_buffer_interval;
let _is_scrolling = false;
let _last_ai_unit_id = null;
let _mouse_is_down = false;

const state = reactive({
  active_caverns_run: false,
  log_entries: [],
});

const caverns_battle_log = ref(null);

onMounted(() => {
  CavernsStore.on(CavernsStore.BATTLE_INITIALIZED, initialCinchScroll);
  CavernsStore.on(CavernsStore.AI_TURN, onAITurn);
  CavernsStore.on(CavernsStore.BATTLE_EVENT, onBattleEvent);
  CavernsStore.on(CavernsStore.UNIT_CONDITION, onUnitCondition);
  CavernsStore.on(CavernsStore.ABILITY_EXECUTED, onAbilityExecuted);

  _entry_buff = [];

  // update Vue state with message entries from the buffer at an interval
  _entry_buffer_interval = setInterval(() => {
    const entry = _entry_buff.shift();
    entry && state.log_entries.push(entry);

    // evict logs, but leave more space if the user is scrolling (avoid jumping off the mouse location)
    if (state.log_entries.length > (_is_scrolling ? 5000 : 1000)) {
      state.log_entries = state.log_entries.slice(100);
    }
  }, 50);
});

onBeforeUnmount(() => {
  CavernsStore.removeListener(
    CavernsStore.BATTLE_INITIALIZED,
    initialCinchScroll
  );
  CavernsStore.removeListener(CavernsStore.AI_TURN, onAITurn);
  CavernsStore.removeListener(CavernsStore.BATTLE_EVENT, onBattleEvent);
  CavernsStore.removeListener(CavernsStore.UNIT_CONDITION, onUnitCondition);
  CavernsStore.removeListener(CavernsStore.ABILITY_EXECUTED, onAbilityExecuted);

  clearInterval(_entry_buffer_interval);
});

onUpdated(cinchScroll);

function addEntry(newEntry) {
  !document.hidden && _entry_buff.push(newEntry);
}
function cinchScroll() {
  if (!_is_scrolling) {
    caverns_battle_log.value.scrollTop = caverns_battle_log.value.scrollHeight;
  }
}

function initialCinchScroll() {
  setTimeout(cinchScroll, 500);
}

function onAbilityExecuted(data) {
  if (data.abilityHandle === 'multi_shot' && data.phase === 'strikes') return; // for 2-phase multi-shot execution

  addEntry({
    text: getBattleEventLogEntryForAbility(
      data,
      CavernsStore.getAll().battleState.allPieces
    ),
  });
}

function onAITurn(data) {
  if (data.aiUnitId !== _last_ai_unit_id) {
    const ai_unit = data.allUnits[data.aiUnitId];
    addEntry({
      ...getCavernsNewTurnMessage({ ai_unit }),
      new_turn: true,
    });
  }
  _last_ai_unit_id = data.aiUnitId;
}

function onBattleEvent(data) {
  data.allPieces = CavernsStore.getAll().battleState.allPieces;
  const entry = getBattleEventLogEntry(data);
  if (entry) {
    TweenMax.delayedCall(0.16, addEntry, [entry]);
  }
}

function onScroll(event) {
  _is_scrolling = true;
  if (!caverns_battle_log.value) {
    return;
  }
  const { clientHeight, scrollHeight, scrollTop } = caverns_battle_log.value;
  if (Math.abs(scrollTop - scrollHeight) < clientHeight + 25) {
    _is_scrolling = false;
  }
}

function onUnitCondition(data) {
  if (!CavernsStore.getAll().battleState?.allPieces) {
    return;
  }

  TweenMax.delayedCall(0.16, addEntry, [
    {
      text: getBattleEventLogEntryForCondition(
        data,
        CavernsStore.getAll().battleState.allPieces
      ),
    },
  ]);
}
</script>
