<template>
  <div
    id="inn_defense_view"
    class="relative m-6 mr-0 mb-0 p-0 bg-white/5 rounded-md flex justify-between items-end"
  >
    <div
      id="inn_defense_title"
      :class="{
        'absolute -top-3 -left-3 h-full font-bold underline text-cyan-400 stroke-zinc-500 no-select': true,
        'text-xl': state.window_width >= 1000,
        'text-base': state.window_width < 1000,
      }"
    >
      {{ text(`ui.game_submodes.${state.current_game_submode}`) }}
      {{ text('ui.defense') }}
    </div>

    <!-- placeholder for canvas rendering -->
    <div
      id="inn_defense_squad_view"
      class="grow h-full bg-transparent"
    ></div>
  </div>
</template>

<script setup>
import { reactive, onBeforeUnmount, onMounted } from 'vue';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import Game from 'dt-common/constants/Game';
import { HeroBuildActions } from '~/flux/actions';
import { GameStateStore, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import {
  EngagedSquadView,
  SquadPuppetMouseoverPanel,
} from '~/view/components/common/canvas';

let _engaged_squad_view;
let _squad_puppet_mouseover_panel;

defineEmits(['edit-squad-btn-clicked']);

const state = reactive({
  current_game_submode: UIStore.getAll().current_game_submode,
  window_width: window.innerWidth,
});

onMounted(() => {
  makeEngagedSquadView();
  GameStateStore.on(GameStateStore.LOADOUT_UPDATED, onLoadoutUpdated);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
});

onBeforeUnmount(() => {
  destroyEngagedSquadView();
  destroySquadPuppetMouseoverPanel();
  GameStateStore.removeListener(
    GameStateStore.LOADOUT_UPDATED,
    onLoadoutUpdated
  );
  UIStore.removeListener(
    UIStore.GAME_SUBMODE_SELECTION,
    onGameSubmodeSelection
  );

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
});

const handleResize = Tools.debounce(() => {
  state.window_width = window.innerWidth;
  makeEngagedSquadView();
}, 300);

const destroyEngagedSquadView = () => {
  if (_engaged_squad_view) {
    DT_CANVAS_GLOBALS.stage.removeChild(_engaged_squad_view);
    _engaged_squad_view.dispose();
    _engaged_squad_view = null;
  }
};

function destroySquadPuppetMouseoverPanel() {
  _squad_puppet_mouseover_panel?.destroy();
  _squad_puppet_mouseover_panel = null;
}

const makeEngagedSquadView = ({ do_transition_in = true } = {}) => {
  destroyEngagedSquadView();

  const dom_anchor = document.getElementById('inn_defense_squad_view');
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();

  const { loadouts } = GameStateStore.getAll().gameState;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const num_heroes = getNumHeroesInSubmode(current_game_submode);
  const puppet_scale =
    (num_heroes < 3
      ? 1.7
      : num_heroes === 3
        ? 1.5
        : num_heroes === 5
          ? 1.3
          : 1.2) *
    window.innerHeight *
    0.001;

  const width_coefficient = window.innerWidth * 0.087;

  _engaged_squad_view = new EngagedSquadView({
    hero_builds: HeroBuildStore.getAll().hero_builds,
    loadout_squad_list: loadouts[current_game_mode][
      current_game_submode
    ].filter((hero) => !!hero.engagement),
    roster_heroes: GameStateStore.getAll().gameState.hero_roster,
    puppet_scale,
    do_transition_in,
    face_direction: Game.EAST,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      switch (num_heroes) {
        case 1:
        case 2:
          return (
            width_coefficient * -2.3 + puppet_index * puppet_scale * 100 + 30
          );
        case 3:
          return (
            width_coefficient * -2.0 + puppet_index * puppet_scale * 100 + 30
          );
        case 5: {
          const SCALE = 85;
          switch (puppet_index) {
            case 0:
              return (
                width_coefficient * -4 + SCALE + SCALE * 1.4 * puppet_scale
              );
            case 1:
              return (
                width_coefficient * -4 + SCALE + SCALE * 0.7 * puppet_scale
              );
            case 2:
              return width_coefficient * -4 + SCALE + 0 * puppet_scale;
            case 3:
              return (
                width_coefficient * -4 + SCALE - SCALE * 0.7 * puppet_scale
              );
            case 4:
              return (
                width_coefficient * -4 + SCALE - SCALE * 1.4 * puppet_scale
              );
          }
        }
        case 7: {
          const SCALE = 85;
          switch (puppet_index) {
            case 0:
              return (
                width_coefficient * -1.45 + SCALE - SCALE * 2.1 * puppet_scale
              );
            case 1:
              return (
                width_coefficient * -1.45 + SCALE - SCALE * 1.4 * puppet_scale
              );
            case 2:
              return (
                width_coefficient * -1.45 + SCALE - SCALE * 0.7 * puppet_scale
              );
            case 3:
              return width_coefficient * -1.45 + SCALE;
            case 4:
              return (
                width_coefficient * -1.45 + SCALE + SCALE * 0.7 * puppet_scale
              );
            case 5:
              return (
                width_coefficient * -1.45 + SCALE + SCALE * 1.4 * puppet_scale
              );
            case 6:
              return (
                width_coefficient * -1.45 + SCALE + SCALE * 2.1 * puppet_scale
              );
          }
        }
        default:
          throw new Error(`Unsupported num_heroes: $${num_heroes}`);
      }
    },
    getVerticalPuppetSpacing: ({ puppet_index, y_drift }) => {
      switch (num_heroes) {
        case 1:
        case 2:
        case 3:
        default:
          return DT_CANVAS_GLOBALS.spacing * 3.75 + Math.round(y_drift * 10);
        case 5: {
          switch (puppet_index) {
            case 0:
              return puppet_scale * 40;
            case 1:
              return puppet_scale * -15;
            case 2:
              return puppet_scale * 40;
            case 3:
              return puppet_scale * -15;
            case 4:
              return puppet_scale * 40;
          }
        }
        case 7: {
          switch (puppet_index) {
            case 0:
              return puppet_scale * 60;
            case 1:
              return puppet_scale * -10;
            case 2:
              return puppet_scale * 60;
            case 3:
              return puppet_scale * -10;
            case 4:
              return puppet_scale * 60;
            case 5:
              return puppet_scale * -10;
            case 6:
              return puppet_scale * 60;
          }
        }
      }
    },
  });
  // z-depth of nameplate puppets
  const puppets = _engaged_squad_view.getPuppets();
  puppets[0] && puppets[0].parent.addChild(puppets[0]);
  puppets[2] && puppets[2].parent.addChild(puppets[2]);
  puppets[4] && puppets[4].parent.addChild(puppets[4]);

  _engaged_squad_view.x = x + width * 0.4 - _engaged_squad_view._width / 2;
  _engaged_squad_view.y = y + height / 2 - DT_CANVAS_GLOBALS.spacing * 2;
  DT_CANVAS_GLOBALS.stage.addChild(_engaged_squad_view);

  // add squad puppet interaction listeners
  for (const puppet of puppets) {
    puppet.mouseover = puppet.touchstart = onSquadPuppetMouseover.bind(
      null,
      puppet
    );
    puppet.mouseout = puppet.touchend = onSquadPuppetMouseout;
  }
};

function makeSquadPuppetMouseoverPanel(puppet) {
  const out_build = puppet.actor;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const loadout =
    GameStateStore.getAll().gameState.loadouts[current_game_mode][
      current_game_submode
    ];

  _squad_puppet_mouseover_panel = new SquadPuppetMouseoverPanel({
    onHeroBuildSelected: ({ hero_build_id, hero_handle }) => {
      HeroBuildActions.engageHeroBuild({
        hero_build_id,
        hero_handle,
        game_mode: UIStore.getAll().current_game_mode,
        game_submode: UIStore.getAll().current_game_submode,
        target_slot_index: loadout.find(
          ({ hero_build_id: id }) => id === out_build._id
        ).engagement.slot,
      });
    },
    out_build,
  });
  _squad_puppet_mouseover_panel.y -= 5;
  puppet.addChild(_squad_puppet_mouseover_panel);
}

function onGameSubmodeSelection() {
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  if (
    current_game_mode !== Game.GAME_MODES.GAME_MODE_pvpAuto ||
    !current_game_submode.includes('pvp')
  ) {
    return;
  }

  state.current_game_submode = UIStore.getAll().current_game_submode;
  makeEngagedSquadView();
}

function onLoadoutUpdated() {
  makeEngagedSquadView();
}

function onSquadPuppetMouseover(puppet) {
  makeSquadPuppetMouseoverPanel(puppet);
}

function onSquadPuppetMouseout() {
  destroySquadPuppetMouseoverPanel();
}
</script>
