<template>
  <div class="font-bold break-keep whitespace-nowrap">
    <span>
      <span v-if="props.username === 'Wakefield Studios'">
        <span style="color: #555555 !important">W</span>
        <span style="color: #493e36 !important">a</span>
        <span style="color: #5f4836 !important">k</span>
        <span style="color: #6f4f26 !important">e</span>
        <span style="color: #835d1c !important">f</span>
        <span style="color: #a06b0e !important">i</span>
        <span style="color: #c17c04 !important">e</span>
        <span style="color: #c19b04 !important">l</span>
        <span style="color: #c19b04 !important">d</span>
        <span style="color: #e7be00 !important">{{ ' ' }} </span>
        <span style="color: #e7be00 !important">S</span>
        <span style="color: #c19b04 !important">t</span>
        <span style="color: #c17c04 !important">u</span>
        <span style="color: #835d1c !important">d</span>
        <span style="color: #5f4836 !important">i</span>
        <span style="color: #555555 !important">o</span>
        <span style="color: #333333 !important">s</span>
      </span>
      <span v-else-if="props.username === 'Duke Garland'">
        <span style="color: #4b4b4b !important">D</span>
        <span style="color: #4b4b4b !important">u</span>
        <span style="color: #4b4b4b !important">k</span>
        <span style="color: #4b4b4b !important">e</span>
        <span style="color: #e10000 !important">{{ ' ' }}</span>
        <span style="color: #e10000 !important">G</span>
        <span style="color: #e10000 !important">a</span>
        <span style="color: #e10000 !important">r</span>
        <span style="color: #e5a700 !important">l</span>
        <span style="color: #e5a700 !important">a</span>
        <span style="color: #e5a700 !important">n</span>
        <span style="color: #e5a700 !important">d</span>
      </span>
      <span v-else-if="props.username === 'Azusa'">
        <span style="color: #436261 !important">A</span>
        <span style="color: #3a7b79 !important">z</span>
        <span style="color: #319d99 !important">u</span>
        <span style="color: #23c9c3 !important">s</span>
        <span style="color: #09f5ed !important">a</span>
      </span>
      <span v-else-if="props.username === 'Ze German'">
        <span style="color: #4b4b4b !important">Z</span>
        <span style="color: #4b4b4b !important">e</span>
        <span style="color: #4b4b4b !important">{{ ' ' }}</span>
        <span style="color: #e10000 !important">G</span>
        <span style="color: #e10000 !important">e</span>
        <span style="color: #e10000 !important">r</span>
        <span style="color: #e5a700 !important">m</span>
        <span style="color: #e5a700 !important">a</span>
        <span style="color: #e5a700 !important">n</span>
      </span>
      <span v-else-if="props.username === 'Drac2019'">
        <span style="color: #747482 !important">D</span>
        <span style="color: #ffbb44 !important">r</span>
        <span style="color: #ffbb44 !important">a</span>
        <span style="color: #ffbb44 !important">c</span>
        <span style="color: #747482 !important">2</span>
        <span style="color: #ffbb44 !important">0</span>
        <span style="color: #ffbb44 !important">1</span>
        <span style="color: #ffbb44 !important">9</span>
      </span>
      <span
        v-else-if="props.username === 'Adisan'"
        style="color: #2a613e !important"
      >
        Adisan
      </span>
      <span v-else-if="props.username === 'Mr. Gentle'">
        <span style="color: red !important">M</span>
        <span style="color: orange !important">r</span>
        <span style="color: yellow !important">.</span>
        <span style="color: green !important">{{ ' ' }}</span>
        <span style="color: #0087f9 !important">G</span>
        <span style="color: purple !important">e</span>
        <span style="color: red !important">n</span>
        <span style="color: orange !important">t</span>
        <span style="color: yellow !important">l</span>
        <span style="color: green !important">e</span>
      </span>
      <span v-else-if="props.username === 'Smagh'">
        <span style="color: #e93cac !important">S</span>
        <span style="color: #af5cb9 !important">m</span>
        <span style="color: #757cc7 !important">a</span>
        <span style="color: #3a9cd4 !important">g</span>
        <span style="color: #00bce1 !important">h</span>
      </span>
      <span v-else-if="props.username === 'Draquonis2019'">
        <span style="color: #e00000 !important">D</span>
        <span style="color: #e00000 !important">r</span>
        <span style="color: #e00000 !important">a</span>
        <span style="color: #e00000 !important">q</span>
        <span style="color: #e00000 !important">u</span>
        <span style="color: #e00000 !important">o</span>
        <span style="color: #e00000 !important">n</span>
        <span style="color: #e00000 !important">i</span>
        <span style="color: #e00000 !important">s</span>
        <span style="color: #e00000 !important">2</span>
        <span style="color: #e00000 !important">0</span>
        <span style="color: #e00000 !important">1</span>
        <span style="color: #e00000 !important">9</span>
      </span>
      <span v-else-if="props.username === 'Trevor'">
        <span style="color: #cd75f0 !important">T</span>
        <span style="color: #c68df2 !important">r</span>
        <span style="color: #bfa6f3 !important">e</span>
        <span style="color: #b8bef5 !important">v</span>
        <span style="color: #b1d7f6 !important">o</span>
        <span style="color: #aaeff8 !important">r</span>
      </span>
      <span v-else-if="props.username === 'Trevor Redux'">
        <span style="color: #cd75f0 !important">T</span>
        <span style="color: #aaeff8 !important">r</span>
        <span style="color: #cd75f0 !important">e</span>
        <span style="color: #aaeff8 !important">v</span>
        <span style="color: #cd75f0 !important">o</span>
        <span style="color: #aaeff8 !important">r</span>
        <span style="color: #000000 !important">{{ ' ' }}</span>
        <span style="color: #cd75f0 !important">R</span>
        <span style="color: #aaeff8 !important">e</span>
        <span style="color: #cd75f0 !important">d</span>
        <span style="color: #aaeff8 !important">u</span>
        <span style="color: #cd75f0 !important">x</span>
      </span>
      <span v-else-if="props.username === 'lemon'">
        <span style="color: #ffff00 !important">l</span>
        <span style="color: #ffff00 !important">e</span>
        <span style="color: #ffff00 !important">m</span>
        <span style="color: #ffff00 !important">o</span>
        <span style="color: #ffff00 !important">n</span>
      </span>
      <span v-else>
        {{ props.username }}
      </span>
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  username: {
    type: String,
    required: false,
  },
});
</script>
