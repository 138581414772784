import { Dispatcher } from 'flux';

const TutorialDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'TUTORIAL_DISPATCHER',

  DESTROY_TUTORIAL_BOX: 'DESTROY_TUTORIAL_BOX',
  LOG_TUTORIAL_STEP_COMPLETED: 'LOG_TUTORIAL_STEP_COMPLETED',
  MAKE_TUTORIAL_BOX: 'MAKE_TUTORIAL_BOX',
  SKIP_TUTORIAL: 'SKIP_TUTORIAL',
  START_TUTORIAL_BATTLE_01: 'START_TUTORIAL_BATTLE_01',
  TUTORIAL_SPOTLIGHT_VISIBLE: 'TUTORIAL_SPOTLIGHT_VISIBLE',
  TUTORIAL_SPOTLIGHT_HIDDEN: 'TUTORIAL_SPOTLIGHT_HIDDEN',

  handleTutorialAction: function (action) {
    this.dispatch({
      source: TutorialDispatcher.NAME,
      action,
    });
  },
});
export default TutorialDispatcher;
