<template>
  <Dialog
    class="relative"
    :open="true"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col"
      >
        <div class="w-full p-6 flex flex-col items-center">
          <div
            class="border border-slate-400 rounded-sm bg-slate-700 hover:bg-slate-600 hover:text-yellow-300 px-6 py-3 flex items-center cursor-pointer"
            @click="logInWithCrazyGames"
          >
            <span>{{ text('ui.login_with') }}</span>
            <img
              class="ml-2"
              :src="`${DOM_IMG_PATH}/crazygames_logo_150x54.png`"
              alt="CrazyGames"
            />
          </div>
          <div
            class="mt-8 flex underline cursor-pointer hover:text-yellow-300"
            @click="doGuestLogin"
          >
            <span>{{ text('ui.play_as_guest') }}</span>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { Dialog, DialogPanel } from '@headlessui/vue';
import waitFor from 'dt-common/isomorphic-helpers/waitFor';
import { AccountActions } from '~/flux/actions';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';

const { ASSETS_PATH = 'assets' } = process.env;
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

async function logInWithCrazyGames() {
  await waitFor(() => window?.CrazyGames?.SDK?.is_dt_initialized);
  try {
    await CrazyGames.SDK.user.showAuthPrompt();
  } catch (err) {
    err.code === 'userCancelled' && doGuestLogin();
  }
  props.onClose();
}

function doGuestLogin() {
  AccountActions.doGuestLogin();
  props.onClose();
}
</script>
