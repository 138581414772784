import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';
import { InnStore } from '~/flux/stores';

const InnNotifications = function () {
  PIXI.Container.call(this);

  this.dispose = () => {
    InnStore.removeListener(InnStore.GOT_INN_LOGS, onGotInnLogs);
    _valueField?.destroy();
    this.destroy();
  };

  this._width = this._height = 20;
  CanvasTools.addBackFill(this, Colors.RED);

  const _valueField = new PIXI.Text('?', {
    fontFamily: 'Courier New',
    fontStyle: 'bold',
    fontSize: CanvasTools.dynamicFontSizeString(12),
    fill: 0xffffff,
    dropShadow: true,
    dropShadowDistance: 1,
    dropShadowColor: 0x000000,
  });
  _valueField.x = Math.round(this._width / 2 - _valueField.width / 2);
  _valueField.y = Math.round(this._height / 2 - _valueField.height / 2);
  this.addChild(_valueField);

  const onGotInnLogs = () => {
    const num = Object.values(InnStore.getAll().inn_logs || {}).length;
    if (num === 0) {
      this.visible = false;
      return;
    } else {
      this.visible = true;
      _valueField.text = num;
      _valueField.x = this._width / 2 - _valueField.width / 2;
    }
  };
  onGotInnLogs();
  InnStore.on(InnStore.GOT_INN_LOGS, onGotInnLogs);
};
InnNotifications.prototype = Object.create(PIXI.Container.prototype);
InnNotifications.prototype.constructor = InnNotifications;
export default InnNotifications;
