<template>
  <Dialog
    class="relative"
    :open="true"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col"
      >
        <div
          v-if="Config.EMPHASIZE_GUEST_LOGIN"
          class="px-6"
        >
          <div
            class="mt-8 w-full flex justify-center"
            @click="doGuestLogin"
          >
            <span
              class="underline cursor-pointer text-green-400 hover:text-yellow-300 text-3xl font-bold"
              >{{ text('ui.play_as_guest') }}</span
            >
          </div>
          <hr class="my-6 border border-zinc-700" />
        </div>
        <div class="p-6">
          <div class="email-login-header">
            <h4 class="text-yellow-300 no-select">
              {{ text('ui.login_with_email') }}
            </h4>
          </div>
          <form
            class="mt-4 w-full flex flex-col items-center"
            @submit="submitEmailForm"
          >
            <div class="text-sm">
              <input
                id="email"
                type="text"
                name="email"
                class="w-64 pl-2 text-black"
                :placeholder="text('ui.enter_email')"
                v-model="state.input.email"
              />

              <div class="h-2 text-xs text-red-500">
                {{ state.errors.email }}
              </div>
            </div>

            <div class="mt-2 text-sm">
              <input
                id="password"
                type="password"
                name="password"
                class="w-64 pl-2 text-black"
                :placeholder="text('ui.enter_password')"
                v-model="state.input.password"
              />

              <div class="h-2 text-red-500 text-xs">
                {{ state.errors.password }}
              </div>
            </div>

            <div
              v-if="state.is_creating_new_account"
              class="mt-2 text-sm"
            >
              <input
                id="confirm_password"
                type="password"
                name="confirm_password"
                class="w-64 pl-2 text-black"
                placeholder="confirm password"
                v-model="state.input.confirm_password"
              />

              <div class="h-2 text-red-500 text-xs">
                {{ state.errors.confirm_password }}
              </div>
            </div>

            <div
              v-if="state.is_creating_new_account"
              class="mt-2 w-full flex justify-center"
            >
              <DTButton
                type="success"
                class="w-[100px] px-3 py-1"
                :disabled="state.pending_login_request"
                @click="submitEmailForm"
              >
                <div
                  v-if="state.pending_login_request"
                  class="spinner_wrapper"
                >
                  <div
                    class="dt-spinner"
                    style="font-size: 16px"
                  ></div>
                </div>
                <span v-else>{{ text('ui.log_in') }}</span>
              </DTButton>
              <DTButton
                type="error"
                class="ml-4 px-3 py-1"
                @click="toggleCreateNewAcctButton(false)"
              >
                {{ text('ui.cancel') }}
              </DTButton>
            </div>
            <div v-else>
              <div class="mt-2 w-full flex justify-center">
                <DTButton
                  :disabled="state.pending_login_request"
                  type="success"
                  class="w-[100px] px-3 py-1"
                  @click="submitEmailForm"
                >
                  <div
                    v-if="state.pending_login_request"
                    class="spinner_wrapper"
                  >
                    <div
                      class="dt-spinner"
                      style="font-size: 16px"
                    ></div>
                  </div>
                  <span v-else>{{ text('ui.log_in') }}</span>
                </DTButton>
                <DTButton
                  :disabled="state.pending_login_request"
                  type="info"
                  class="ml-4 px-3 py-1"
                  @click="toggleCreateNewAcctButton(true)"
                >
                  <span class="text-base">{{
                    text('ui.create_new_account')
                  }}</span>
                </DTButton>
              </div>
              <div class="mt-1 w-full text-center">
                <a
                  class="cursor-pointer text-xs"
                  @click="onResetPasswordClick"
                >
                  {{ text('ui.reset_password') }}
                </a>
              </div>
            </div>
            <div class="mt-4 text-red-500 text-xs">
              {{ state.errors.firebase }}
            </div>
          </form>
          <div
            v-if="Config.PLATFORM !== 'game_distribution'"
            class="oath-section"
          >
            <hr class="my-6 border border-zinc-700" />
            <h4 class="text-yellow-300 no-select">
              {{
                Config.PLATFORM === 'yandex'
                  ? text('ui.or_login_with_yandex')
                  : text('ui.or_use_one_of_these')
              }}
            </h4>
            <div class="py-3 flex w-full justify-center items-center">
              <button
                v-if="Config.PLATFORM === 'yandex'"
                class="btn btn-success w-[56px] h-[56px] mr-[8px]"
                @click="logInWithYandex"
              >
                <img :src="`${DOM_IMG_PATH}/yandex_logo_circle_100x100.png`" />
              </button>
              <button
                v-if="Config.PLATFORM !== 'yandex'"
                class="btn btn-success oauth-btn"
                @click="logInWithGoogle"
              >
                <img :src="`${DOM_IMG_PATH}/google-logo-circle.png`" />
              </button>
              <button
                v-if="Config.PLATFORM !== 'yandex'"
                class="btn btn-success oauth-btn"
                @click="logInWithFacebook"
              >
                <img :src="`${DOM_IMG_PATH}/f_logo_RGB-Blue_144.png`" />
              </button>
            </div>
          </div>
          <div v-if="!Config.EMPHASIZE_GUEST_LOGIN">
            <hr class="my-6 border border-zinc-700" />
            <div
              class="mt-8 w-full flex justify-center"
              @click="doGuestLogin"
            >
              <span
                class="underline cursor-pointer text-neutral-300 hover:text-yellow-300"
                >{{ text('ui.play_as_guest') }}</span
              >
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { reactive } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import firebase from 'firebase/compat/app';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';
import Config from '~/constants/Config';
import { AccountActions } from '~/flux/actions';

const { ASSETS_PATH = 'assets' } = process.env;
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  is_creating_new_account: false,
  input: {
    email: '',
    password: '',
    confirm_password: '',
  },
  errors: {},
});

async function onResetPasswordClick() {
  if (validateEmailForm(true)) {
    try {
      const { email } = state.input;
      await firebase.auth().sendPasswordResetEmail(email);
      state.errors = {
        firebase: `A password reset link has been mailed to ${email}`,
      };
    } catch (err) {
      state.errors = {
        firebase: err.message,
      };
    }
  }
}

function toggleCreateNewAcctButton(val) {
  state.is_creating_new_account = val;
}

async function submitEmailForm(event) {
  event.preventDefault();

  if (validateEmailForm()) {
    const { email, password } = state.input;

    state.pending_login_request = true;
    state.input = {
      email: '',
      password: '',
      confirm_password: '',
    };

    try {
      if (state.is_creating_new_account) {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        props.onClose();
      } else {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        props.onClose();
      }
    } catch (err) {
      state.errors = {
        firebase: err.message,
      };
      logError(err);
    } finally {
      state.pending_login_request = false;
    }
  }
}

function validateEmailForm(email_only) {
  let input = state.input;
  let errors = {};
  let isValid = true;

  if (!input['email']) {
    isValid = false;
    errors['email'] = 'Please enter your email Address.';
  }

  if (typeof input['email'] !== 'undefined') {
    const pattern = new RegExp(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
    );
    if (!pattern.test(input['email'])) {
      isValid = false;
      errors['email'] = 'Please enter a valid email address.';
    }
  }

  if (!email_only) {
    if (!input['password']) {
      isValid = false;
      errors['password'] = 'Please enter your password.';
    }

    if (typeof input['password'] !== 'undefined') {
      if (input['password'].length < 6) {
        isValid = false;
        errors['password'] = 'Please add at least 6 characters.';
      }
    }

    if (state.is_creating_new_account) {
      if (!input['confirm_password']) {
        isValid = false;
        errors['confirm_password'] = 'Please enter your confirm password.';
      }

      if (
        typeof input['password'] !== 'undefined' &&
        typeof input['confirm_password'] !== 'undefined'
      ) {
        if (input['password'] != input['confirm_password']) {
          isValid = false;
          errors['password'] = "Passwords don't match.";
        }
      }
    }
  }

  state.errors = errors;
  return isValid;
}

async function logInWithFacebook() {
  try {
    const fb_auth_provider = new firebase.auth.FacebookAuthProvider();
    await firebase.auth().signInWithPopup(fb_auth_provider);
  } catch (err) {
    state.errors = {
      firebase: err.message,
    };
    console.error(err);
  }
  props.onClose();
}

async function logInWithGoogle() {
  try {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(googleAuthProvider);
  } catch (err) {
    state.errors = {
      firebase: err.message,
    };
    console.error(err);
  }
  props.onClose();
}

async function logInWithYandex() {
  try {
    await ysdk?.auth?.openAuthDialog();
    AccountActions.authenticate();
  } catch (err) {
    state.errors = {
      firebase: err.message,
    };
    console.error(err);
  }
  props.onClose();
}

function doGuestLogin() {
  AccountActions.doGuestLogin();
  props.onClose();
}
</script>

<style>
.email-login-header {
  width: 100%;
  text-align: center;
}

.email-login-btn {
  border-radius: 5px;
}

.oath-section {
  width: 100%;
  text-align: center;
}

.oauth-btn {
  width: 52px;
  height: 48px;
  border-radius: 24px !important;
  border: none !important;
  outline: none !important;
  margin-right: 10px;
  padding: 0;
}
.oauth-btn > img {
  width: 100%;
  height: 100%;
}

.spinner_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
