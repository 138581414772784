import * as PIXI from 'pixi.js';
import { Colors, Config } from '~/constants';
import CanvasTools from '~/view/CanvasTools';
import Screens from '~/constants/Screens';
import { TutorialActions, UIActions } from '~/flux/actions';
import {
  BattleStore,
  CavernsStore,
  CurrencyStore,
  GameStateStore,
  InnStore,
  UIStore,
  TutorialStore,
} from '~/flux/stores';
import text from '~/text';

const NavMenu = function (myWidth) {
  PIXI.Container.call(this);

  this.width = myWidth;
  var myHeight = (this.height = window.innerHeight * 0.26);
  const BTN_WIDTH = myWidth / 7;

  CanvasTools.addBackFill(this, Colors.ALMOST_BLACK);

  CanvasTools.addBorder(
    this,
    'menu_top_border.png',
    'window_border_vert.png',
    null,
    'window_border_vert.png',
    0
  );

  const onNavBtnSelected = (event) => {
    // disallow navigation while battles are waiting to start
    if (
      CavernsStore.getAll().pending_caverns_travel ||
      BattleStore.getAll().pending_battle_init
    )
      return;

    // change screens
    const { screen_id } = event.target;
    UIActions.uiNav({ screen_id });
  };

  const makeBtn = (
    texPath,
    xPosIndex,
    { screen_id },
    labelString,
    highlightColor
  ) => {
    const btn = new PIXI.Sprite();
    btn.highlightColor = highlightColor;
    btn.texture = PIXI.utils.TextureCache[texPath];

    var label = (btn.label = new PIXI.Sprite());
    label.texture = PIXI.utils.TextureCache['footer/nav_menu/menu_banner.png'];
    var labelText = (btn.label.labelText = new PIXI.Text(labelString, {
      fontFamily: 'Courier New',
      fontStyle: 'bold',
      fontSize: '52px',
      fill: 0x000000,
      dropShadow: true,
      dropShadoowColor: 0x454545,
      dropShadowDistance: 4,
    }));

    labelText.x = Math.round(label.width / 2 - labelText.width / 2);
    labelText.y = Math.round(label.height * 0.61 - labelText.height / 2);
    label.addChild(labelText);

    label.width = btn.width;
    label.scale.y = label.scale.x;
    label.x = Math.round(btn.width / 2 - label.width / 2);
    label.y = Math.round(
      btn.height * (screen_id === Screens.SHOP ? 1.24 : 1.07)
    );
    btn.addChild(label);

    btn.interactive = btn.buttonMode = true;
    btn.tap = btn.click = onNavBtnSelected;
    btn.width = BTN_WIDTH;
    btn.height = Math.min(myHeight * 0.6, btn.height * btn.scale.x);
    btn.scale.x = btn.scale.y;
    btn.x = Math.round(myWidth * (xPosIndex / 6) - btn.width / 2);
    btn.y =
      (xPosIndex - 0.5) % 2 === 1
        ? Math.round(myHeight * 0.375 - btn.height / 2)
        : Math.round(myHeight * 0.575 - btn.height / 2);
    this.addChild(btn);

    btn.mouseover = () => {
      btn.tint_buf = btn.tint;
      btn.label.tint_buf = btn.label.tint;
      btn.tint = btn.label.tint = 0xbeffff;
    };
    btn.mouseout = () => {
      btn.tint = btn.tint_buf;
      btn.label.tint = btn.label.tint_buf;
    };

    btn.screen_id = screen_id;
    return btn;
  };

  let _heroBuildsBtn;
  let _pvpAutoBtn;
  let _pvpLiveBtn;
  let _cavernsBtn;
  let _shopBtn;
  let _altarBtn;

  const destroyButtons = () => {
    if (_heroBuildsBtn) {
      this.removeChild(_heroBuildsBtn);
      _heroBuildsBtn.tap = _heroBuildsBtn.click = null;
    }
    if (_pvpAutoBtn) {
      this.removeChild(_pvpAutoBtn);
      _pvpAutoBtn.tap = _pvpAutoBtn.click = null;
    }
    if (_pvpLiveBtn) {
      this.removeChild(_pvpLiveBtn);
      _pvpLiveBtn.tap = _pvpLiveBtn.click = null;
    }
    if (_cavernsBtn) {
      this.removeChild(_cavernsBtn);
      _cavernsBtn.tap = _cavernsBtn.click = null;
    }
    if (_altarBtn) {
      this.removeChild(_altarBtn);
      _altarBtn.tap = _altarBtn.click = null;
    }
    if (_shopBtn) {
      this.removeChild(_shopBtn);
      _shopBtn.tap = _shopBtn.click = null;
    }
    _heroBuildsBtn = null;
    _pvpAutoBtn = null;
    _pvpLiveBtn = null;
    _cavernsBtn = null;
    _shopBtn = null;
    _altarBtn = null;
  };

  const makeButtons = () => {
    destroyButtons();

    _heroBuildsBtn = makeBtn(
      'footer/nav_menu/caverns_icon.png',
      0.5,
      { screen_id: Screens.HERO_BUILDS },
      text('ui.HERO_BUILDS'),
      0x3d46a5
    );
    _pvpAutoBtn = makeBtn(
      'footer/nav_menu/armory_icon.png',
      1.5,
      { screen_id: Screens.INN },
      text('ui.INN'),
      0x0b9c1c
    );
    _pvpLiveBtn = makeBtn(
      'footer/nav_menu/arena_icon.png',
      2.5,
      { screen_id: Screens.ARENA_LOBBY },
      text('ui.ARENA'),
      0xff0000
    );
    _cavernsBtn = makeBtn(
      'footer/nav_menu/shop_icon.png',
      3.5,
      { screen_id: Screens.CAVERNS },
      text('ui.CAVERNS'),
      Colors.BRIGHT_YELLOW
    );
    _altarBtn = makeBtn(
      'footer/nav_menu/altar_icon.png',
      4.5,
      { screen_id: Screens.TEMPLE },
      text('ui.TEMPLE'),
      0xb23aff
    );
    _shopBtn = makeBtn(
      'shop/shopkeeper_portrait.png',
      5.5,
      { screen_id: Screens.SHOP },
      text('ui.SHOP'),
      Colors.PIXIE_DUST
    );

    const lockedTextStyle = {
      fontFamily: 'Courier New',
      fontSize: 52,
      fontStyle: 'bold',
      fill: 0xffffff,
      dropShadow: true,
      dropShadowColor: 0x000000,
      dropShadowDistance: 4,
    };
    const lockedTint = 0x444444;

    // disable buttons for tutorial situations
    const {
      entered_caverns,
      equipped_an_ability,
      unlocked_2nd_hero,
      opened_context_info,
      skipped_all,
    } = TutorialStore.getCompletedTutorialSteps();
    // on nav to caverns step, disable all but caverns button
    if (!entered_caverns && !skipped_all)
      _heroBuildsBtn.interactive =
        _pvpAutoBtn.interactive =
        _pvpLiveBtn.interactive =
        _altarBtn.interactive =
        _shopBtn.interactive =
          false;
    // on unlock 2nd hero step, disable all but hero builds button
    else if (equipped_an_ability && !unlocked_2nd_hero && !skipped_all)
      _pvpAutoBtn.interactive =
        _pvpLiveBtn.interactive =
        _cavernsBtn.interactive =
        _altarBtn.interactive =
        _shopBtn.interactive =
          false;
    // all disabled unless context info step has been completed
    else if (!opened_context_info && !skipped_all)
      _heroBuildsBtn.interactive =
        _pvpAutoBtn.interactive =
        _pvpLiveBtn.interactive =
        _cavernsBtn.interactive =
        _altarBtn.interactive =
        _shopBtn.interactive =
          false;

    // check Inn access
    if (GameStateStore.getAll().num_unlocked_heroes < 3 && !Config.DEMO_MODE) {
      _pvpAutoBtn.buttonMode = _pvpAutoBtn.interactive = false;
      _pvpAutoBtn.tint = _pvpAutoBtn.label.tint = lockedTint;

      let need3heroes = new PIXI.Text(
        text('ui.need_3_heroes'),
        lockedTextStyle
      );
      need3heroes.anchor.x = need3heroes.anchor.y = 0.5;
      need3heroes.rotation = -Math.PI / 5;
      need3heroes.x = _pvpAutoBtn.width / 2 / _pvpAutoBtn.scale.x;
      need3heroes.y = _pvpAutoBtn.height / 2 / _pvpAutoBtn.scale.y;
      _pvpAutoBtn.addChild(need3heroes);
    }

    // check Arena access
    if (InnStore.getAll().max_inn_tier_reached < 2 && !Config.DEMO_MODE) {
      _pvpLiveBtn.buttonMode = _pvpLiveBtn.interactive = false;
      _pvpLiveBtn.tint = _pvpLiveBtn.label.tint = lockedTint;

      const needInnTier2 = new PIXI.Text(
        text('ui.need_inn_tier_2'),
        lockedTextStyle
      );
      needInnTier2.anchor.x = needInnTier2.anchor.y = 0.5;
      needInnTier2.rotation = -Math.PI / 5;
      needInnTier2.x = _pvpLiveBtn.width / 2 / _pvpLiveBtn.scale.x;
      needInnTier2.y = _pvpLiveBtn.height / 2 / _pvpLiveBtn.scale.y;
      _pvpLiveBtn.addChild(needInnTier2);
    }

    // check altar access
    if (!CurrencyStore.getAll().altarAccess && !Config.DEMO_MODE) {
      // TODO!!! should be have i EVER accumulated 5k
      _altarBtn.buttonMode = _altarBtn.interactive = false;
      _altarBtn.tint = _altarBtn.label.tint = lockedTint;

      const need5kgold = new PIXI.Text(
        text('ui.need_5k_gold'),
        lockedTextStyle
      );
      need5kgold.anchor.x = need5kgold.anchor.y = 0.5;
      need5kgold.rotation = -Math.PI / 5;
      need5kgold.x = _altarBtn.width / 2 / _altarBtn.scale.x;
      need5kgold.y = _altarBtn.height / 2 / _altarBtn.scale.y;
      _altarBtn.addChild(need5kgold);
    }
  };
  makeButtons();

  // tutorial step 1 - guide the player to the caverns screen
  const { entered_caverns, skipped_all } =
    TutorialStore.getCompletedTutorialSteps();
  if (
    !entered_caverns &&
    UIStore.getAll().current_ui_screen !== Screens.CAVERNS &&
    !skipped_all
  ) {
    setTimeout(() => {
      TutorialActions.makeTutorialBox({
        text: text('tutorial.basic_intro_1'),
        x: window.innerWidth * 0.5,
        y: window.innerHeight * 0.5,
        onOkClick: () => {
          TutorialActions.makeTutorialBox({
            text: text('tutorial.basic_intro_2'),
            x: window.innerWidth * 0.5,
            y: window.innerHeight * 0.5,
            do_spotlight_transition: false,
            onOkClick: () => {
              TutorialActions.makeTutorialSpotlight({
                radius: 128,
                x: window.innerWidth * 0.793,
                y: window.innerHeight * 0.86,
                onTransitionInComplete: () => {
                  TutorialActions.makeTutorialBox({
                    arrow: 'down',
                    text: text('tutorial.nav_to_caverns_tip'),
                    x: window.innerWidth * 0.785,
                    y: window.innerHeight * 0.78,
                    width: 300,
                  });
                },
              });
            },
          });
        },
      });
    }, 900);
  }

  let _highlightedBtn = null;
  const highlightBtn = (btn, keep_interactive = false) => {
    if (btn) {
      if (!keep_interactive) btn.interactive = false;
      btn.tint = btn.highlightColor;
      btn.label.tint = btn.highlightColor;
      btn.label.labelText.style.fill = 0xffffff;
      btn.label.labelText.style.dropShadoowColor = 0x000000;
      _highlightedBtn = btn;
    }
  };

  const removeButtonHighlight = () => {
    if (_highlightedBtn) {
      _highlightedBtn.interactive = true;
      _highlightedBtn.label.tint = 0xffffff;
      _highlightedBtn.label.labelText.style.fill = 0x000000;
      _highlightedBtn.label.labelText.style.dropShadoowColor = 0x454545;
    }
  };

  const onUINav = () => {
    removeButtonHighlight();

    const { current_ui_screen } = UIStore.getAll();
    switch (current_ui_screen) {
      case Screens.TEMPLE:
        highlightBtn(_altarBtn);
        break;
      case Screens.ARENA_LOBBY:
        highlightBtn(_pvpLiveBtn);
        break;
      case Screens.CAVERNS:
        highlightBtn(_cavernsBtn);
        break;
      case Screens.INN:
        highlightBtn(_pvpAutoBtn);
        break;
      case Screens.HERO_BUILDS:
        highlightBtn(_heroBuildsBtn);
        break;
      case Screens.EDIT_HERO:
        highlightBtn(_heroBuildsBtn, true);
        break;
      case Screens.SHOP:
        highlightBtn(_shopBtn);
        break;
    }

    TutorialActions.destroyTutorialBox(false);
    TutorialActions.destroyTutorialSpotlight(false);
  };
  onUINav();

  CurrencyStore.on(CurrencyStore.CURRENCY_CHANGE, refreshButtons);
  GameStateStore.on(GameStateStore.HERO_LEVEL_UP, refreshButtons);
  GameStateStore.on(GameStateStore.HERO_UNLOCKED, refreshButtons);
  InnStore.on(InnStore.PASSIVE_TOURNEY_RESOLVED, refreshButtons);
  TutorialStore.on(TutorialStore.TUTORIAL_STEP_COMPLETE, refreshButtons);
  UIStore.on(UIStore.UI_NAV, onUINav);

  function refreshButtons() {
    makeButtons();
    onUINav();
  }

  this.dispose = () => {
    this.destroy();
    destroyButtons();
    removeListeners();
    TutorialActions.destroyTutorialBox(false);
    TutorialActions.destroyTutorialSpotlight(false);
  };
  function removeListeners() {
    CurrencyStore.removeListener(CurrencyStore.CURRENCY_CHANGE, refreshButtons);
    GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, refreshButtons);
    GameStateStore.removeListener(GameStateStore.HERO_LEVEL_UP, refreshButtons);
    InnStore.removeListener(InnStore.PASSIVE_TOURNEY_RESOLVED, refreshButtons);
    TutorialStore.removeListener(
      TutorialStore.TUTORIAL_STEP_COMPLETE,
      refreshButtons
    );
    UIStore.removeListener(UIStore.UI_NAV, onUINav);
  }
};
NavMenu.prototype = Object.create(PIXI.Container.prototype);
NavMenu.prototype.constructor = NavMenu;
export default NavMenu;
