import Game from 'dt-common/constants/Game';
import { Config } from '~/constants';
import ApplicationDispatcher from '~/flux/dispatchers/ApplicationDispatcher';

const { QUALITY_I, QUALITY_II, QUALITY_III, QUALITY_IV, QUALITY_V } =
  Game.ItemQuality;

export const awaitSocket = (callback) => {
  ApplicationDispatcher.register((payload) => {
    if (payload.action.actionType === ApplicationDispatcher.SOCKET_CONNECTED) {
      callback(payload.action.socket);
    }
    return true;
  });
};

export const debounce = (func, delay = 100) => {
  let timer;
  return (event) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(func, delay, event);
  };
};

export const formatGameCurrency = (val) =>
  Intl.NumberFormat(Config.LOCALE, {
    maximumFractionDigits: val < 10000 ? 0 : 1,
    notation: val < 10000 ? 'standard' : 'compact',
  }).format(val);

export const getQualityTag = (quality) => {
  if (typeof quality !== 'number' || quality < 0) {
    logError(new Error('bad item quality value', { quality }));
    quality = 0;
  }

  if (quality < QUALITY_I) {
    return 'i';
  } else if (quality < QUALITY_II) {
    return 'ii';
  } else if (quality < QUALITY_III) {
    return 'iii';
  } else if (quality < QUALITY_IV) {
    return 'iv';
  } else if (quality <= QUALITY_V) {
    return 'v';
  } else {
    logError(new Error('Exceeded maximum item quality.', { quality }));
    return 'v';
  }
};

export const millisecondsToHHMMSS = (t) => {
  const sec = t / 1000;
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);
  return (
    (h > 0 ? h + ':' + (m < 10 ? '0' : '') : '') +
    m +
    ':' +
    (s < 10 ? '0' : '') +
    s
  );
};

export function ObjectPool(createObjFunc, resetObjFunc, startingNum) {
  let poolResult;
  let pool = [];

  for (let i = 0; i < startingNum; ++i) {
    pool.push(createObjFunc());
  }

  this.get = (data) => {
    if (pool.length > 0) {
      poolResult = pool.pop();
      resetObjFunc(poolResult, data);
    } else {
      poolResult = createObjFunc(data);
    }

    return poolResult;
  };

  this.put = (obj) => {
    pool.push(obj);
  };
}

export const registerDispatchHandlers = (handlers) => (payload) => {
  try {
    const { action } = payload;
    const { actionType } = action;

    if (handlers[actionType]) {
      handlers[actionType](action);
    }
    return true;
  } catch (err) {
    logError(err, {
      module: 'Tools',
      func: 'registerDispatchHandlers',
      handlers,
      payload,
    });
  }
};

export const LINK_REGEX =
  /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/gi;

export const linkifyText = (text, blank_target = true) =>
  text.replace(
    LINK_REGEX,
    `<a href="$1" ${blank_target ? (target = '_blank') : ''}>$1</a>`
  );

const Tools = {
  awaitSocket,
  debounce,
  formatGameCurrency,
  getQualityTag,
  LINK_REGEX,
  linkifyText,
  millisecondsToHHMMSS,
  ObjectPool,
  registerDispatchHandlers,
};
export default Tools;
