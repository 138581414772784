<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div
      class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90"
    >
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black"
      >
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.social_modal_title') }}
        </DialogTitle>

        <div class="w-full flex overflow-y-auto">
          <div class="w-full p-4 flex flex-col items-center overflow-y-visible">
            <!-- email signup link -->
            <!-- <div class="w-full text-center no-select">
              <EmailSignupForm />
            </div> -->
            <!-- <hr class="w-full my-4 border border-1 border-grey" /> -->
            <iframe
              v-if="Config.PLATFORM !== 'yandex'"
              id="discord_embed"
              :src="
                Config.LOCALE === 'ru'
                  ? 'https://discord.com/widget?id=1320537408177180783&theme=dark'
                  : 'https://discord.com/widget?id=1003381121792364604&theme=dark'
              "
              width="650"
              height="400"
              allowtransparency="true"
              frameborder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
            <!-- moar shit 1st column here -->
          </div>
          <!-- <div class="grow p-4 flex flex-col items-center overflow-y-visible">
            <iframe
              id="reddit_embed"
              src="https://www.redditmedia.com/r/dungeon_team/comments/12ey1jq/intro_to_temple/?ref\\\_source=embed\\\&amp;ref=share\\\&amp;embed=true"
              sandbox="allow-scripts allow-same-origin allow-popups"
              style="border: none;"
              width="400"
              height="180"
              scrolling="yes"
              class="mt-4 rounded-md min-h-[180px]"
            ></iframe>
            <iframe
              id="reddit_embed"
              src="https://www.redditmedia.com/r/dungeon_team/comments/112bo0k/dungeon_team_hero_builds_demo/?ref\\\_source=embed\\\&amp;ref=share\\\&amp;embed=true"
              sandbox="allow-scripts allow-same-origin allow-popups"
              style="border: none;"
              width="400"
              height="370"
              scrolling="yes"
              class="mt-4 rounded-md min-h-[370px]"
            ></iframe>
            <iframe
              id="reddit_embed"
              src="https://www.redditmedia.com/r/dungeon_team/comments/113rbsu/dungeon_team_on_steam/?ref\\\_source=embed\\\&amp;ref=share\\\&amp;embed=true"
              sandbox="allow-scripts allow-same-origin allow-popups"
              style="border: none;"
              width="400"
              height="150"
              scrolling="yes"
              class="mt-4 rounded-md min-h-[150px]"
            ></iframe>
          </div> -->
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { reactive } from 'vue';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import Config from '~/constants/Config';
import text from '~/text';
import { EmailSignupForm } from '~/view/components/common/DOM';

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});
</script>
