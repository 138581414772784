import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import Game from 'dt-common/constants/Game';
import UIDispatcher from '~/flux/dispatchers/UIDispatcher';
import {
  AccountStore,
  BattleStore,
  CavernsStore,
  ChatStore,
  UIStore,
} from '~/flux/stores';
import Screens from '~/constants/Screens';

var UIActions = {
  focusHero(hero_handle) {
    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.SET_FOCUSED_HERO,
      hero_handle,
    });
  },

  focusHeroBuild({ hero_handle, build_id }) {
    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.SET_FOCUSED_HERO_BUILD,
      hero_handle,
      build_id,
    });
  },

  onModalClosed() {
    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.MODAL_CLOSED,
    });
  },

  selectGameMode(gameModeKey) {
    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.SELECT_GAME_MODE,
      gameModeKey,
    });
  },

  selectGameSubmode(gameSubmodeKey) {
    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.SELECT_GAME_SUBMODE,
      current_game_mode: UIStore.getAll().current_game_mode,
      gameSubmodeKey,
    });
  },

  showModal({ modal_key, modal_props }) {
    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.SHOW_MODAL,
      modal_key,
      modal_props,
    });
  },

  uiNav({ screen_id, focused_hero, force = false }) {
    // select any according game mode & submode
    const num_heroes = getNumHeroesInSubmode(
      UIStore.getAll().current_game_submode
    );
    switch (screen_id) {
      case Screens.CAVERNS:
        UIActions.selectGameMode(Game.GAME_MODES.GAME_MODE_caverns);
        UIActions.selectGameSubmode(
          Game.GAME_SUBMODES[`GAME_SUBMODE_caverns${num_heroes}man`]
        );
        break;
      case Screens.INN:
        UIActions.selectGameMode(Game.GAME_MODES.GAME_MODE_pvpAuto);
        UIActions.selectGameSubmode(
          Game.GAME_SUBMODES[`GAME_SUBMODE_pvp${num_heroes}v${num_heroes}`]
        );
        break;
      case Screens.ARENA_LOBBY:
        UIActions.selectGameMode(Game.GAME_MODES.GAME_MODE_pvpLive);
        UIActions.selectGameSubmode(
          Game.GAME_SUBMODES[`GAME_SUBMODE_pvp${num_heroes}v${num_heroes}`]
        );
        break;
    }

    UIDispatcher.handleUIAction({
      actionType: UIDispatcher.UI_NAV,
      current_game_mode: UIStore.getAll().current_game_mode,
      screen_id,
      focused_hero,
      force,
    });
  },
};
export default UIActions;

AccountStore.on(AccountStore.FIREBASE_AUTH_NOT_LOGGED_IN, () => {
  UIActions.showModal({ modal_key: 'AuthModal' });
});

AccountStore.on(AccountStore.CRAZY_GAMES_AUTH_NOT_LOGGED_IN, () => {
  UIActions.showModal({ modal_key: 'CrazyGamesLoginModal' });
});

AccountStore.on(AccountStore.KONGREGATE_AUTH_NOT_LOGGED_IN, () => {
  UIActions.showModal({ modal_key: 'KongregateLoginModal' });
});

BattleStore.on(BattleStore.BATTLE_INITIALIZED, () => {
  UIActions.uiNav({ screen_id: Screens.BATTLE });
});

BattleStore.on(BattleStore.ENGINE_RECOVERY_FAILED, () => {
  UIActions.uiNav({ screen_id: Screens.SHOP });
});

CavernsStore.on(
  CavernsStore.RETURNED_TO_SURFACE,
  (completed_caverns_run_data) => {
    UIActions.selectGameMode(Game.GAME_MODES.GAME_MODE_caverns);
    UIActions.selectGameSubmode(completed_caverns_run_data.game_submode);
    UIActions.showModal({
      modal_key: 'BattleDebriefModal',
      modal_props: {
        completed_caverns_run_data,
      },
    });
  }
);

ChatStore.on(ChatStore.SHOW_CHAT_TEXT_COLOR_PICKER, () => {
  UIActions.showModal({
    modal_key: 'ChatTextColorPicker',
  });
});
