import { Dispatcher } from 'flux';

const InnDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'INN_DISPATCHER',

  FETCH_INN_LOGS: 'FETCH_INN_LOGS',
  FETCH_RANDOM_INN_OPPONENTS: 'FETCH_RANDOM_INN_OPPONENTS',
  FETCH_PLAYER_INN_SUBMODE_STATE: 'FETCH_PLAYER_INN_SUBMODE_STATE',
  START_ATTACK_BATTLE: 'START_ATTACK_BATTLE',
  START_ATTACK_PREP: 'START_ATTACK_PREP',
  SWAP_ATTACK_HERO: 'SWAP_ATTACK_HERO',

  handleInnAction: function (action) {
    this.dispatch({
      source: InnDispatcher.NAME,
      action,
    });
  },
});
export default InnDispatcher;
