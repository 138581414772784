import { EventEmitter } from 'events';
import { v4 as uuidv4 } from 'uuid';
import Game from 'dt-common/constants/Game';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import placePlayerCavernsUnits from 'dt-common/isomorphic-helpers/placePlayerCavernsUnits';
import Audio from '~/Audio';
import CavernsWorker from '~/caverns-worker?worker';
import Screens from '~/constants/Screens';
import {
  ApplicationDispatcher,
  CavernsDispatcher,
  PlayerDispatcher,
  SettingsDispatcher,
  UIDispatcher,
} from '~/flux/dispatchers';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import GameStateStore from '~/flux/stores/GameStateStore';
import text from '~/text';

let playerId;
let _socket;
let _worker;
let _current_game_submode;
let _last_caverns_travel_at = 0;
let _execute_caverns_travel_timeout;

// the stuff we serve:
let caverns_data;
let pending_caverns_travel;
let current_caverns_level;
let battleState;
let goldPickedUp;
let pdPickedUp;
let equipmentPickedUp = [];
let actingAIUnit;

const CavernsStore = Object.assign({}, EventEmitter.prototype, {
  ABILITY_EXECUTED: 'ABILITY_EXECUTED',
  AI_TURN: 'AI_TURN',
  BATTLE_EVENT: 'BATTLE_EVENT',
  BATTLE_INITIALIZED: 'BATTLE_INITIALIZED',
  BATTLE_STATE: 'BATTLE_STATE',
  CAVERNS_BREAKING_ERROR: 'CAVERNS_BREAKING_ERROR',
  CAVERNS_PORTAL_LEVEL_SET: 'CAVERNS_PORTAL_LEVEL_SET',
  CAVERNS_TRAVEL_STARTED: 'CAVERNS_TRAVEL_STARTED',
  CAVERNS_TRAVEL_SUCCESS: 'CAVERNS_TRAVEL_SUCCESS',
  ENTERED_CAVERNS: 'ENTERED_CAVERNS',
  GOT_UNIT_STATE: 'GOT_UNIT_STATE',
  PASSIVE_YIELDS_CLAIMED: 'PASSIVE_YIELDS_CLAIMED',
  RETURNED_TO_SURFACE: 'RETURNED_TO_SURFACE',
  ROOM_IS_CLEAR: 'ROOM_IS_CLEAR',
  STAT_COSTS_EXACTED: 'STAT_COSTS_EXACTED',
  TOTAL_LOOT_PICKED_UP: 'TOTAL_LOOT_PICKED_UP',
  UNIT_CONDITION: 'UNIT_CONDITION',
  UNIT_CONDITION_EXPIRED: 'UNIT_CONDITION_EXPIRED',

  getAll() {
    return {
      caverns_data,
      pending_caverns_travel,
      current_caverns_level,
      battleState,
      goldPickedUp,
      pdPickedUp,
      equipmentPickedUp,
      actingAIUnit,
    };
  },
});
export default CavernsStore;

ApplicationDispatcher.register((payload) => {
  if (
    payload.action.actionType === ApplicationDispatcher.UI_NAV &&
    battleState
  ) {
    returnToSurface();
  }
  return true;
});
PlayerDispatcher.register(
  registerDispatchHandlers({
    [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
  })
);
CavernsDispatcher.register(
  registerDispatchHandlers({
    [CavernsDispatcher.CLAIM_PASSIVE_YIELDS]: claimPassiveYields,
    [CavernsDispatcher.INIT_NEW_CAVERNS_MAP]: initNewCavernsMap,
    [CavernsDispatcher.LOOT_TILE]: lootTile,
    [CavernsDispatcher.DOOR_SPRITE_CLICK]: onDoorSpriteClick,
    [CavernsDispatcher.RETURN_TO_SURFACE]: returnToSurface,
    [CavernsDispatcher.SET_PORTAL_LEVEL_TO_CURRENT_DEPTH]:
      setPortalLevelToCurrentDepth,
    [CavernsDispatcher.START_CAVERNS_BATTLE_ENGINE]: startCavernsBattleEngine,
    [CavernsDispatcher.DISCARD_LOOT_ITEM]: discardLootItem,
  })
);
UIDispatcher.register(
  registerDispatchHandlers({
    [UIDispatcher.UI_NAV]: onUINav,
  })
);

SettingsDispatcher.register(
  registerDispatchHandlers({
    [SettingsDispatcher.CHANGE_BROWSER_LANGUAGE]: onBrowserLanguageChanged,
  })
);

awaitSocket(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_CavernsStore_listeners) {
      socket.on('api_server_error', onApiServerError);
      _socket.on(
        'caverns_mobs_spawned',
        proxyToWorker.bind(null, 'caverns_mobs_spawned')
      );
      _socket.on(
        'caverns_passive_yield_claimed',
        onPassiveCavernsYieldsClaimed
      );
      _socket.on('caverns_portal_level_set', onCavernsPortalLevelSet);
      _socket.on('caverns_travel_success', onCavernsTravelSuccess);
      _socket.on('discarded_caverns_loot_item', onDiscardedCavernsLootItem);
      _socket.on('entered_caverns', onEnteredCaverns);
      _socket.on('loot_dropped', proxyToWorker.bind(null, 'loot_dropped'));
      _socket.on('missing_caverns_run_data', onMissingCavernsRunData);
      _socket.on('returnedToSurface', onReturnedToSurface);
      _socket.on('totalLootPickedUp', onTotalLootPickedUp);

      _socket.has_CavernsStore_listeners = true;
    }
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'onSocketConnected',
    });
  }
}

async function onPlayerLoggedIn(action) {
  try {
    const { player } = action;
    playerId = player._id;
    caverns_data = player.gameState.caverns_data;
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function claimPassiveYields(action) {
  _socket.emit('claim_passive_caverns_yields', {
    playerId,
    game_submode: action.game_submode,
  });
}

function battleEngineCleanup() {
  _worker?.postMessage({ message_name: 'cleanup' });

  goldPickedUp = 0;
  pdPickedUp = 0;
  equipmentPickedUp = [];
  battleState = null;
}

function initNewCavernsMap({ startingLevel, game_submode }) {
  pending_caverns_travel = true;
  _socket.emit('init_new_caverns_map', {
    playerId,
    startingLevel: parseInt(startingLevel, 10),
    game_submode,
  });
}

function onEnteredCaverns(data) {
  _current_game_submode = data.game_submode;
  CavernsStore.emit(CavernsStore.ENTERED_CAVERNS, data);
}

function startCavernsBattleEngine({
  active_caverns_loadout,
  game_submode,
  new_caverns_data,
  white_team,
}) {
  try {
    battleEngineCleanup();

    pending_caverns_travel = false;

    if (game_submode !== _current_game_submode)
      throw new Error(
        `game_submode mismatch: ${{ game_submode, _current_game_submode }}`
      );

    // initialize the player's heroes & starting tiles
    const room = new_caverns_data.startingRoom;

    // generate unit battle state objects for each member of the active loadout
    const allUnits = active_caverns_loadout.reduce((result, loadout_member) => {
      const unit_handle = loadout_member.hero_handle || loadout_member.handle;
      const unit_state = getUnitState({
        roster_hero: GameStateStore.getAll().gameState.hero_roster[unit_handle],
        unit_build: white_team.builds[unit_handle],
        unit_loadout: loadout_member,
      });
      unit_state.uid = uuidv4();
      unit_state.team = 'white';
      unit_state.unit = true;
      unit_state.hero = true;

      result[unit_state.uid] = unit_state;
      return result;
    }, {});

    const allTiles = room.tiles.reduce((result, tile) => {
      tile.uid = uuidv4();
      result[tile.uid] = tile;
      return result;
    }, {});

    placePlayerCavernsUnits({
      units: Object.values(allUnits),
      entranceDoor: room.doors[Math.floor(Math.random() * room.doors.length)],
      tiles: room.tiles,
    });

    _worker = new CavernsWorker();

    _worker.onerror = logError;

    const WORKER_MSG_HANDLERS = {
      caverns_level_initialized: (message_data) => {
        current_caverns_level = message_data.caverns_level;
        CavernsStore.emit(CavernsStore.BATTLE_INITIALIZED);
      },
      failed_mob_spawns: (message_data, message_name) => {
        // just proxying to socket
        _socket.emit(message_name, {
          playerId,
          ...message_data,
        });
      },
      caverns_mob_death: (message_data, message_name) => {
        // just proxying to socket
        _socket.emit(message_name, {
          playerId,
          ...message_data,
        });
      },
      abilityExecuted: onAbilityExecuted,
      aiTurn: onAITurn,
      battleEngineError: onBattleEngineError,
      battleEvent: onBattleEvent,
      gotUnitState: onUnitState,
      spawn_new_enemy: requestMobSpawn,
      statCostsExacted: onStatCostsExacted,
      unitCondition: onUnitCondition,
      unitConditionExpired: onUnitConditionExpired,
      cleaned_up: () => {
        _worker?.terminate();
      },
    };

    _worker.onmessage = (event) => {
      const { message_name, message_data, battle_state } = event.data;
      if (battle_state && message_name !== 'statCostsExacted') {
        battleState = {
          ...(battleState || {}),
          ...battle_state,
        };
      }

      const handler = WORKER_MSG_HANDLERS[message_name];
      handler && handler(message_data, message_name);
    };

    _worker.postMessage(
      JSON.parse(
        JSON.stringify({
          // avoid cloning errors
          message_name: 'start_battle_engine',
          message_data: {
            battle_engine_constructor_arg: {
              battleState: {
                allTiles,
                allUnits,
                black_team: [],
                doors: room.doors,
                walls: room.walls,
                white_team,
                portal: room.portal,
              },
              battle_id: `caverns-${new_caverns_data.startingLevel}-${playerId}`,
              blackPlayerId: null,
              game_mode: Game.GAME_MODES.GAME_MODE_caverns,
              game_submode,
              whitePlayerId: playerId,
            },
            new_caverns_data,
          },
        })
      )
    );
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'startCavernsBattleEngine',
      new_caverns_data,
    });
    returnToSurface();
  }
}

function proxyToWorker(message_name, message_data) {
  _worker.postMessage({
    message_name,
    message_data,
  });
}

function requestMobSpawn() {
  _socket.emit('request_caverns_mob_spawn', { playerId });
}

function onStatCostsExacted(data) {
  if (!document.hidden) {
    CavernsStore.emit(CavernsStore.STAT_COSTS_EXACTED, data);
  }
}

function onAbilityExecuted(data) {
  if (!document.hidden) {
    CavernsStore.emit(CavernsStore.ABILITY_EXECUTED, data);
  }
}

function onBattleEvent(data) {
  if (!document.hidden) {
    CavernsStore.emit(CavernsStore.BATTLE_EVENT, data);
  }

  if (data.event.eventHandle === 'death') {
    // remove the dead unit from the battleState
    // after a delay to allow death rendering & battle log to work
    TweenMax.delayedCall(0.1, () => {
      if (!battleState || !battleState.allPieces) {
        return;
      }

      const victimId = data.event.victimId;
      delete battleState.allPieces[victimId];
      delete battleState.allUnits[victimId];
    });

    // check if the room is clear
    let room_is_clear = true;
    const all_units_array = Object.values(battleState.allUnits);
    for (const { team, dead, inPlay } of all_units_array)
      if (team === 'black' && !dead && inPlay) {
        room_is_clear = false;
        break;
      }

    if (room_is_clear) {
      CavernsStore.emit(CavernsStore.ROOM_IS_CLEAR);
      setTimeout(() => {
        Audio.play('level_up');

        // check for auto-explore
        if (localStorage.getItem('dt_caverns_auto_explore') === 'true') {
          const target_depth = parseInt(
            localStorage.getItem(
              `dt_caverns_target_depth.${_current_game_submode}`
            ) || current_caverns_level,
            10
          );
          let exits = battleState.doors.map((d) => d.direction);
          if (target_depth > current_caverns_level && exits.includes('DOWN')) {
            onDoorSpriteClick({
              direction: 'DOWN',
              game_submode: _current_game_submode,
            });
          } else if (
            target_depth < current_caverns_level &&
            exits.includes('UP')
          ) {
            onDoorSpriteClick({
              direction: 'UP',
              game_submode: _current_game_submode,
            });
          } else {
            exits = exits.filter((d) => d !== 'DOWN' && d !== 'UP');
            onDoorSpriteClick({
              direction: exits[Math.floor(Math.random() * exits.length)],
              game_submode: _current_game_submode,
            });
          }
        }
      }, 500);
    }
  }
}

function onAITurn(data) {
  actingAIUnit = data.aiUnitId;
  CavernsStore.emit(CavernsStore.AI_TURN, data);
}

function onPassiveCavernsYieldsClaimed(data) {
  caverns_data = data.caverns_data;
  CavernsStore.emit(CavernsStore.PASSIVE_YIELDS_CLAIMED, data);
}

function onUnitCondition(condition) {
  if (!document.hidden) {
    CavernsStore.emit(CavernsStore.UNIT_CONDITION, condition);
  }
}

function onUnitConditionExpired(data) {
  if (!document.hidden) {
    CavernsStore.emit(CavernsStore.UNIT_CONDITION_EXPIRED, data);
  }
}

function onUnitState(unit) {
  if (!document.hidden) {
    CavernsStore.emit(CavernsStore.GOT_UNIT_STATE, unit);
  }
}

function lootTile(action) {
  _socket.emit('loot_caverns_tile', {
    playerId,
    tile_id: action.tileId,
  });
}

function onDoorSpriteClick(action) {
  clearTimeout(_execute_caverns_travel_timeout);

  // throttle caverns travel 1 per 6 seconds
  const travel_delta = Date.now() - _last_caverns_travel_at;
  _execute_caverns_travel_timeout = setTimeout(
    executeCavernsTravel.bind(null, action),
    travel_delta < 6000 ? 6000 - travel_delta : 0
  );
}

function executeCavernsTravel(action) {
  try {
    if (pending_caverns_travel) {
      return;
    }

    const all_units_array = Object.values(battleState.allUnits || {});

    // the player can't change rooms if there are any living mobs in the current room
    for (const { team, dead, inPlay } of all_units_array) {
      if (team === 'black' && !dead && inPlay) {
        $addMessageLogMessage(text('ui.enemies_in_play'), 0xff0000);
        return;
      }
    }

    pending_caverns_travel = true;
    // handle case where we just don't get an API response
    setTimeout(() => {
      pending_caverns_travel = false;
    }, 4000);

    _worker.postMessage({ message_name: 'pause' });

    _socket.emit('caverns_travel', {
      playerId,
      direction: action.direction,
      game_submode: action.game_submode,
      survivingWhiteUnits: all_units_array.filter(
        ({ team, dead, inPlay }) => team === 'white' && !dead && inPlay
      ),
    });

    CavernsStore.emit(CavernsStore.CAVERNS_TRAVEL_STARTED);

    _last_caverns_travel_at = Date.now();
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'onDoorSpriteClick',
      action,
    });
  }
}

function onTotalLootPickedUp(data) {
  try {
    const { gold, pd, equipment, single_tile_looted_id } = data;

    goldPickedUp = gold;
    pdPickedUp = pd;
    equipmentPickedUp = equipment;

    if (single_tile_looted_id) {
      proxyToWorker('tile_looted', { single_tile_looted_id });
    }

    CavernsStore.emit(CavernsStore.TOTAL_LOOT_PICKED_UP, data);
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'onTotalLootPickedUp',
      data,
    });
  }
}

function returnToSurface(action) {
  try {
    battleEngineCleanup();
    _socket.emit('caverns_return_to_surface', {
      playerId,
    });
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'returnToSurface',
    });
  }
}

function onReturnedToSurface(data) {
  try {
    CavernsStore.emit(CavernsStore.RETURNED_TO_SURFACE, {
      ...data,
      battleState: JSON.parse(JSON.stringify(battleState || {})),
    });
    battleEngineCleanup();
  } catch (err) {
    logError(err, {
      module: 'CavernsStore',
      func: 'onReturnedToSurface',
      data,
    });
  }
}

function onBattleEngineError(data) {
  logError(data, {
    module: 'CavernsStore',
    func: 'onBattleEngineError',
  });
}

function onMissingCavernsRunData() {
  battleEngineCleanup();
  CavernsStore.emit(CavernsStore.CAVERNS_BREAKING_ERROR);
}

function setPortalLevelToCurrentDepth(action) {
  _socket.emit('set_portal_level_to_current_depth', { playerId, ...action });
}

function onCavernsPortalLevelSet(data) {
  caverns_data[data.game_submode].portal_level = data.new_portal_level;
  CavernsStore.emit(CavernsStore.CAVERNS_PORTAL_LEVEL_SET, data);
}

function onUINav({ screen_id }) {
  if (screen_id === Screens.CAVERNS) {
    returnToSurface();
  }
}

proxyToWorker.bind(null, 'caverns_travel_success');

function onCavernsTravelSuccess(data) {
  pending_caverns_travel = false;

  if (data.caverns_data) {
    caverns_data = data.caverns_data;
  }
  CavernsStore.emit(CavernsStore.CAVERNS_TRAVEL_SUCCESS);
  proxyToWorker('caverns_travel_success', data);
}

function onApiServerError() {
  pending_caverns_travel = false;
}

function onBrowserLanguageChanged() {
  returnToSurface();
}

function discardLootItem({ item_id }) {
  _socket.emit('discard_caverns_loot_item', { playerId, item_id });
}

function onDiscardedCavernsLootItem({ item_id }) {
  equipmentPickedUp = equipmentPickedUp.filter(({ uid }) => uid !== item_id);
  CavernsStore.emit(CavernsStore.TOTAL_LOOT_PICKED_UP, {
    gold: goldPickedUp,
    pd: pdPickedUp,
    equipment: equipmentPickedUp,
  });
  Audio.play('equipment_storage');
}
