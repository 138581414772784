<template>
  <div
    id="inn_attack_prep_root"
    class="flex pb-3"
  >
    <div
      id="inn_attack_prep_body"
      class="grow h-full p-8 flex flex-col pointer-events-none justify-center"
    >
      <div
        id="inn_opponent_view"
        class="h-48 max-h-[25vh] w-full mb-4 p-4 pt-12 bg-red-500/5 rounded-md text-right"
      >
        <div class="no-select flex justify-end">
          <div>
            <div
              :class="{
                'font-bold text-cyan-400 underline': true,
                'text-xl': state.window_width >= 1000,
                'text-base': state.window_width < 1000,
              }"
            >
              {{ text('ui.opponent') }}:
            </div>
            <UserNameplate
              :username="state.opponent.player_display_name || 'Anonymous'"
              class="ml-3"
            />
          </div>
        </div>
        <div
          :class="{
            'text-yellow-500': true,
            'text-base': state.window_width >= 1000,
            'text-sm': state.window_width < 1000,
          }"
        >
          {{ text('ui.rank') }}:
          <span class="font-bold"
            >#{{ state.opponent.player_inn_submode_state.current_rank }}</span
          >
        </div>
        <div
          :class="{
            'text-base': state.window_width >= 1000,
            'text-xs': state.window_width < 1000,
          }"
        >
          {{ text('ui.peanuts_to_steal') }}:
          {{ state.tickers.num_peanuts_to_steal }}
        </div>
      </div>
      <div
        id="engaged_heroes_view"
        class="h-48 max-h-[25vh] w-full p-4 bg-white/5 rounded-md"
      >
        <div
          :class="{
            'max-w-[12vw] font-bold underline text-red-400 no-select': true,
            'text-xl': state.window_width >= 1000,
            'text-base': state.window_width < 1000,
          }"
        >
          {{
            text(`ui.game_submodes.${UIStore.getAll().current_game_submode}`)
          }}
          {{ text('ui.attack_loadout') }}:
        </div>
      </div>
      <div
        class="w-full h-16 -mt-[20vh] px-[1vw] flex justify-end items-center"
      >
        <DTButton
          type="error"
          class="w-[130px] ml-3 py-1 px-5"
          :disabled="state.pending_fight_request"
          @click="
            () => {
              state.pending_fight_request = true;
              InnActions.startAttackBattle({
                game_submode: UIStore.getAll().current_game_submode,
              });
            }
          "
        >
          <DTAnimatedElipsis
            v-if="state.pending_fight_request"
            class="text-2xl font-extrabold"
          />
          <span v-else>{{ text('ui.fight') }}</span>
        </DTButton>
        <DTButton
          type="neutral"
          class="w-[130px] ml-3 py-1 px-5"
          :disabled="state.pending_fight_request"
          @click="$emit('cancel')"
        >
          {{ text('ui.go_back') }}
        </DTButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  nextTick,
  reactive,
} from 'vue';
import Balance from 'dt-common/constants/Balance';
import Game from 'dt-common/constants/Game';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import '~/index.css';
import Config from '~/constants/Config';
import { InnActions } from '~/flux/actions';
import {
  ApplicationStore,
  GameStateStore,
  HeroBuildStore,
  InnStore,
  UIStore,
} from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import {
  EngagedSquadView,
  SquadPuppetMouseoverPanel,
} from '~/view/components/common/canvas';
import {
  DTAnimatedElipsis,
  DTButton,
  UserNameplate,
} from '~/view/components/common/DOM';

let _engaged_squad_view;
let _opponent_squad_view;
let _squad_puppet_mouseover_panel;
let _tickers_interval;

defineEmits(['cancel']);

const state = reactive({
  opponent: null,
  pending_fight_request: false,
  selected_engaged_hero: null,
  selected_hero_build: null,
  tickers: {},
  window_width: window.innerWidth,
});

onBeforeMount(() => {
  state.opponent = InnStore.getAll().opponent_to_attack;
  _tickers_interval = setInterval(calcTickers, 1000);
  calcTickers();
});

function calcTickers() {
  const { current_rank, last_num_peanuts, last_rank_change_at } =
    InnStore.getAll().opponent_to_attack.player_inn_submode_state;
  const now = Date.now();

  // calc & rendernum peanuts
  const ms_elapsed_since_last_rank_change = now - last_rank_change_at;
  const num = Math.floor(
    last_num_peanuts +
      ms_elapsed_since_last_rank_change *
        Balance.getInnPeanutsPerMSByRank(current_rank)
  );
  state.tickers.num_peanuts_to_steal = Tools.formatGameCurrency(num / 10);
}

onMounted(() => {
  makeEngagedSquadView();
  makeOpponentSquadView();

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);

  ApplicationStore.on(ApplicationStore.API_ERROR, onAPIError);
  GameStateStore.on(GameStateStore.ILLEGAL_GAME_STATE_UPDATE, onAPIError);
  GameStateStore.on(GameStateStore.LOADOUT_UPDATED, onLoadoutUpdated);
  InnStore.on(InnStore.ATTACK_SQUAD_UPDATED, onAttackSquadUpdated);
});

onBeforeUnmount(() => {
  destroyEngagedSquadView();
  destroyOpponentSquadView();
  destroySquadPuppetMouseoverPanel();

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);

  ApplicationStore.removeListener(ApplicationStore.API_ERROR, onAPIError);
  GameStateStore.removeListener(
    GameStateStore.ILLEGAL_GAME_STATE_UPDATE,
    onAPIError
  );
  GameStateStore.removeListener(
    GameStateStore.LOADOUT_UPDATED,
    onLoadoutUpdated
  );
  InnStore.removeListener(InnStore.ATTACK_SQUAD_UPDATED, onAttackSquadUpdated);

  clearInterval(_tickers_interval);
});

const handleResize = Tools.debounce(() => {
  state.window_width = window.innerWidth;
  makeEngagedSquadView();
  makeOpponentSquadView();
}, 300);

function destroyEngagedSquadView() {
  _engaged_squad_view?.dispose();
  _engaged_squad_view = null;
}

function destroyOpponentSquadView() {
  _opponent_squad_view?.dispose();
  _opponent_squad_view = null;
}

function destroySquadPuppetMouseoverPanel() {
  _squad_puppet_mouseover_panel?.destroy();
  _squad_puppet_mouseover_panel = null;
}

function makeEngagedSquadView({ do_transition_in = true } = {}) {
  destroyEngagedSquadView();

  const { current_game_submode } = UIStore.getAll();
  const num_heroes = getNumHeroesInSubmode(current_game_submode);
  const puppet_scale =
    (num_heroes < 5 ? 1.75 : num_heroes > 5 ? 1.25 : 1.5) *
    window.innerHeight *
    0.0013;

  _engaged_squad_view = new EngagedSquadView({
    hero_builds: HeroBuildStore.getAll().hero_builds,
    loadout_squad_list: InnStore.getAll().attack_loadout.filter(
      (hero) => !!hero.engagement
    ),
    roster_heroes: GameStateStore.getAll().gameState.hero_roster,
    puppet_scale,
    face_direction: Game.NORTH,
    do_transition_in,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      const width_coefficient = window.innerWidth * 0.087;
      switch (num_heroes) {
        case 1:
          return (
            width_coefficient * -2.4 + puppet_index * puppet_scale * 100 + 15
          );
        case 2:
          return (
            width_coefficient * -1.6 + puppet_index * puppet_scale * 100 + 15
          );
        case 3:
          return (
            width_coefficient * -1.2 + puppet_index * puppet_scale * 100 + 15
          );
        case 5: {
          const SCALE = 120;
          switch (puppet_index) {
            case 0:
              return (
                width_coefficient * -5 + SCALE + SCALE * 1.8 * puppet_scale
              );
            case 1:
              return (
                width_coefficient * -5 + SCALE + SCALE * 1.35 * puppet_scale
              );
            case 2:
              return (
                width_coefficient * -5 + SCALE + SCALE * 0.9 * puppet_scale
              );
            case 3:
              return (
                width_coefficient * -5 + SCALE + SCALE * 0.45 * puppet_scale
              );
            case 4:
              return width_coefficient * -5 + SCALE;
          }
        }
        case 7: {
          const SCALE = 105;
          switch (puppet_index) {
            case 0:
              return (
                width_coefficient * -1.4 + SCALE - SCALE * 1.35 * puppet_scale
              );
            case 1:
              return (
                width_coefficient * -1.4 + SCALE - SCALE * 0.9 * puppet_scale
              );
            case 2:
              return (
                width_coefficient * -1.4 + SCALE - SCALE * 0.45 * puppet_scale
              );
            case 3:
              return width_coefficient * -1.4 + SCALE;
            case 4:
              return (
                width_coefficient * -1.4 + SCALE + SCALE * 0.45 * puppet_scale
              );
            case 5:
              return (
                width_coefficient * -1.4 + SCALE + SCALE * 0.9 * puppet_scale
              );
            case 6:
              return (
                width_coefficient * -1.4 + SCALE + SCALE * 1.35 * puppet_scale
              );
          }
        }
      }
    },
    getVerticalPuppetSpacing: ({ puppet_index, y_drift }) => {
      const width_coefficient = window.innerWidth * 0.087;
      switch (num_heroes) {
        case 1:
        case 2:
        case 3:
        default:
          return DT_CANVAS_GLOBALS.spacing * 3.75 + Math.round(y_drift * 10);
        case 5: {
          switch (puppet_index) {
            case 0:
              return puppet_scale * 40;
            case 1:
              return puppet_scale * -15;
            case 2:
              return puppet_scale * 40;
            case 3:
              return puppet_scale * -15;
            case 4:
              return puppet_scale * 40;
          }
        }
        case 7: {
          switch (puppet_index) {
            case 0:
              return puppet_scale * 40;
            case 1:
              return puppet_scale * -15;
            case 2:
              return puppet_scale * 40;
            case 3:
              return puppet_scale * -15;
            case 4:
              return puppet_scale * 40;
            case 5:
              return puppet_scale * -15;
            case 6:
              return puppet_scale * 40;
          }
        }
      }
    },
  });
  // z-depth of nameplate puppets
  const puppets = _engaged_squad_view.getPuppets();
  puppets[0] && puppets[0].parent.addChild(puppets[0]);
  puppets[2] && puppets[2].parent.addChild(puppets[2]);
  puppets[4] && puppets[4].parent.addChild(puppets[4]);
  puppets[6] && puppets[6].parent.addChild(puppets[6]);

  const dom_anchor = document.getElementById('engaged_heroes_view');
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();
  _engaged_squad_view.x = x + width * 0.4 - _engaged_squad_view._width / 2;
  _engaged_squad_view.y = y + height / 2 - DT_CANVAS_GLOBALS.spacing * 2;
  DT_CANVAS_GLOBALS.stage.addChild(_engaged_squad_view);

  // add squad puppet interaction listeners
  for (const puppet of puppets) {
    puppet.mouseover = onSquadPuppetMouseover.bind(null, puppet);
    puppet.mouseout = onSquadPuppetMouseout;
  }
}

function makeOpponentSquadView({ do_transition_in = true } = {}) {
  destroyOpponentSquadView();

  const { opponent_to_attack } = InnStore.getAll();

  const num_heroes = getNumHeroesInSubmode(
    UIStore.getAll().current_game_submode
  );
  const puppet_scale =
    (num_heroes < 3
      ? 1.55
      : num_heroes === 3
        ? 1.35
        : num_heroes === 5
          ? 1.15
          : 1.0) *
    window.innerHeight *
    0.0013;

  _opponent_squad_view = new EngagedSquadView({
    hero_builds: opponent_to_attack.hero_builds,
    loadout_squad_list: opponent_to_attack.loadout.filter(
      (hero) => !!hero.engagement
    ),
    roster_heroes: opponent_to_attack.roster_heroes,
    puppet_scale,
    do_transition_in,
    is_opponent: true,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      const width_coefficient = window.innerWidth * 0.087;
      switch (num_heroes) {
        case 1:
          return puppet_scale * 5;
        case 2:
          return width_coefficient * 1.4 - puppet_index * puppet_scale * 95 + 5;
        case 3:
          return width_coefficient * 1.4 - puppet_index * puppet_scale * 70 + 5;
        case 5: {
          const SCALE = 55;
          switch (puppet_index) {
            case 0:
              return (
                width_coefficient * 0.3 + SCALE + SCALE * 1.8 * puppet_scale
              );
            case 1:
              return (
                width_coefficient * 0.3 + SCALE + SCALE * 0.9 * puppet_scale
              );
            case 2:
              return width_coefficient * 0.3 + SCALE;
            case 3:
              return (
                width_coefficient * 0.3 + SCALE - SCALE * 0.9 * puppet_scale
              );
            case 4:
              return (
                width_coefficient * 0.3 + SCALE - SCALE * 1.8 * puppet_scale
              );
          }
        }
        case 7: {
          const SCALE = 110;
          switch (puppet_index) {
            case 0:
              return (
                width_coefficient * -1 + SCALE + SCALE * 1.35 * puppet_scale
              );
            case 1:
              return (
                width_coefficient * -1 + SCALE + SCALE * 0.9 * puppet_scale
              );
            case 2:
              return (
                width_coefficient * -1 + SCALE + SCALE * 0.45 * puppet_scale
              );
            case 3:
              return width_coefficient * -1 + SCALE;
            case 4:
              return (
                width_coefficient * -1 + SCALE - SCALE * 0.45 * puppet_scale
              );
            case 5:
              return (
                width_coefficient * -1 + SCALE - SCALE * 0.9 * puppet_scale
              );
            case 6:
              return (
                width_coefficient * -1 + SCALE - SCALE * 1.35 * puppet_scale
              );
          }
        }
        default:
          return puppet_index * puppet_scale * 60;
      }
    },
    getVerticalPuppetSpacing: ({ puppet_index, y_drift }) => {
      switch (num_heroes) {
        case 1:
        case 2:
        case 3:
        default:
          return DT_CANVAS_GLOBALS.spacing * 4 - Math.round(y_drift * 25);
        case 5: {
          switch (puppet_index) {
            case 0:
              return puppet_scale * -20;
            case 1:
              return puppet_scale * 40;
            case 2:
              return puppet_scale * -20;
            case 3:
              return puppet_scale * 40;
            case 4:
              return puppet_scale * -20;
          }
        }
        case 7: {
          switch (puppet_index) {
            case 0:
              return puppet_scale * -10;
            case 1:
              return puppet_scale * 60;
            case 2:
              return puppet_scale * -10;
            case 3:
              return puppet_scale * 60;
            case 4:
              return puppet_scale * -10;
            case 5:
              return puppet_scale * 60;
            case 6:
              return puppet_scale * -10;
          }
        }
      }
    },
  });
  // z-depth of nameplate puppets
  const puppets = _opponent_squad_view.getPuppets();
  puppets[0] && puppets[0].parent.addChild(puppets[0]);
  puppets[1] && puppets[1].parent.addChild(puppets[1]);

  const dom_anchor = document.getElementById('inn_opponent_view');
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();
  _opponent_squad_view.x = x + width * 0.55 - _opponent_squad_view._width / 2;
  _opponent_squad_view.y = y + height * 0.7 - DT_CANVAS_GLOBALS.spacing * 6;
  DT_CANVAS_GLOBALS.stage.addChild(_opponent_squad_view);
}

function makeSquadPuppetMouseoverPanel(puppet) {
  const out_build = puppet.actor;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const loadout =
    GameStateStore.getAll().gameState.loadouts[current_game_mode][
      current_game_submode
    ];

  _squad_puppet_mouseover_panel = new SquadPuppetMouseoverPanel({
    onHeroBuildSelected: ({ hero_build_id, hero_handle }) => {
      // const hero_build_to_swap_in =  state.selected_hero_build;
      InnActions.swapAttackHero({
        hero_handle_in: hero_handle,
        hero_build_id_to_swap_in: hero_build_id,
        hero_build_id_to_swap_out: out_build._id,
      });
    },
    out_build,
  });
  puppet.addChild(_squad_puppet_mouseover_panel);
}

function onAttackSquadUpdated() {
  nextTick(makeEngagedSquadView);
}

function onLoadoutUpdated() {
  makeEngagedSquadView();
}

function onSquadPuppetMouseover(puppet) {
  makeSquadPuppetMouseoverPanel(puppet);
}

function onSquadPuppetMouseout() {
  destroySquadPuppetMouseoverPanel();
}

function onAPIError() {
  state.pending_fight_request = false;
}
</script>
