import * as PIXI from 'pixi.js';
import { Colors } from '~/constants';
import { CavernsActions } from '~/flux/actions';
import { CavernsStore } from '~/flux/stores';
import CanvasTools from '~/view/CanvasTools';
import ActionRenderer from '~/view/game-screens/battle/canvas/game_board/action_renderings/ActionRenderer';
import CavernsTileSprite from './CavernsTileSprite';
import CavernsUnitSprite from './CavernsUnitSprite';

const DOOR_SPRITE_SCALE = { x: 0.6, y: 0.6 };
let _extro_interval;

const CavernsBattleView = function (battleState, options) {
  PIXI.Container.call(this);

  if (!battleState) {
    return;
  }

  this.can_update = true;
  this.getAllPieceSprites = () => {
    return _allPieceSprites;
  };
  this.getTileSprites = () => {
    return _tileSprites;
  };

  const _actionRenderer = (this.actionRenderer = new ActionRenderer(
    this,
    true
  ));

  let _allPieceSprites = {};
  let _doorSprites = [];
  let _portal_sprite;
  let _tileSprites = {};
  let _unitSprites = {};
  let _unitSpritesArray;
  let _wallSprites = [];

  const updateCollections = () => {
    if (!this.can_update || !battleState?.allPieces) return;

    _unitSpritesArray = [];

    for (const prop of Object.keys(battleState.allPieces)) {
      let piece = battleState.allPieces[prop];

      if (_allPieceSprites[prop]) {
        _allPieceSprites[prop].gamePiece = piece;
      } else {
        // make a new piece sprite
        if (piece.tile) {
          let ts = new CavernsTileSprite(piece);
          _allPieceSprites[prop] = ts;
          this.addChildAt(ts, 0);

          ts.tileImg.scale = { x: 0.55, y: 0.55 };
          if (piece.occupied) {
            ts.tileImg.visible = false;
          }
        } else if (piece.unit) {
          let us = new CavernsUnitSprite(piece, options);
          _allPieceSprites[prop] = us;
          this.addChild(us);
        }

        _allPieceSprites[prop]?.snapToBoardPosition(piece.x, piece.y);
      }

      if (piece.unit) {
        _unitSprites[prop] = _allPieceSprites[prop];
        _unitSpritesArray.push(_unitSprites[prop]);
        _unitSprites[prop].updateUnit(piece);
        _unitSprites[prop].tileImg.visible = true;
      } else if (piece.tile) {
        _tileSprites[prop] = _allPieceSprites[prop];
        if (!piece.occupied && !piece.loot) {
          _tileSprites[prop].tileImg.visible = true;
        }
      }
    }

    // sort unit sprites for z-depth
    _unitSpritesArray.sort((a, b) => a.gamePiece.y - b.gamePiece.y);
    for (const us of _unitSpritesArray) {
      this.addChild(us);
    }
  };

  const makeDoorSprites = () => {
    for (const ds of _doorSprites) {
      ds?.removeChildren();
      ds?.highlight?.destroy();
      ds?.dispose();
    }
    _doorSprites = [];

    if (typeof battleState?.doors === 'object') {
      battleState.doors = Object.values(battleState.doors);
    }

    for (const door of battleState?.doors || []) {
      const doorSprite = new CavernsTileSprite(door);
      doorSprite.tileImg.text = door.stairs
        ? door.direction === 'UP'
          ? '<'
          : '>'
        : '/';
      doorSprite.tileImg.tint = door.stairs ? 0xcccccc : 0xa48457;
      doorSprite.scale = { ...DOOR_SPRITE_SCALE };
      doorSprite.snapToBoardPosition(door.x, door.y);
      this.addChild(doorSprite);
      _doorSprites.push(doorSprite);

      // red/green highlight around exits, we paint a translucent white circle that will be tinted by `updateCollections()`
      doorSprite.highlight = new PIXI.Graphics();
      doorSprite.highlight.beginFill(0xffffff, 0.15);
      doorSprite.highlight.drawCircle(0, 0, 42);
      doorSprite.highlight.endFill();
      doorSprite.addChild(doorSprite.highlight);
      // start out red
      doorSprite.highlight.tint = 0xff0000;

      doorSprite.interactive =
        doorSprite.interactiveChildren =
        doorSprite.buttonMode =
          true;
      doorSprite.tap = doorSprite.click = onDoorSpriteClick;
      doorSprite.mouseover = doorSprite.pointerover = onDoorSpriteMouseover;
      doorSprite.mouseout = doorSprite.pointerout = onDoorSpriteMouseout;
      doorSprite.hitArea = new PIXI.Circle(0, 0, 42);
    }
  };

  const makePortalSprite = () => {
    if (!battleState?.portal) {
      return;
    }

    const { portal } = battleState;
    _portal_sprite = new CavernsTileSprite(portal);
    _portal_sprite.tileImg.text = 'Ω';
    _portal_sprite.tileImg.tint = Colors.CAVERNS_PORTAL;
    _portal_sprite.scale = { ...DOOR_SPRITE_SCALE };
    _portal_sprite.tileImg.anchor.x = _portal_sprite.tileImg.anchor.y = 0.5;
    _portal_sprite.snapToBoardPosition(portal.x, portal.y);
    this.addChild(_portal_sprite);

    _portal_sprite.highlight = new PIXI.Graphics();
    _portal_sprite.highlight.beginFill(Colors.dreamsmith, 0.15);
    _portal_sprite.highlight.drawCircle(0, 0, 42);
    _portal_sprite.highlight.endFill();
    _portal_sprite.addChild(_portal_sprite.highlight);

    _portal_sprite.interactive =
      _portal_sprite.interactiveChildren =
      _portal_sprite.buttonMode =
        true;
    _portal_sprite.tap = _portal_sprite.click = onPortalSpriteClick;
    _portal_sprite.mouseover = _portal_sprite.pointerover =
      onDoorSpriteMouseover;
    _portal_sprite.mouseout = _portal_sprite.pointerout = onDoorSpriteMouseout;
    _portal_sprite.hitArea = new PIXI.Circle(0, 0, 42);
  };

  const makeWallSprites = () => {
    for (const ws of _wallSprites) {
      ws.dispose();
    }
    _wallSprites = [];

    if (typeof battleState?.walls === 'object') {
      battleState.walls = Object.values(battleState.walls);
    }

    for (const wall of battleState.walls || []) {
      const wallsSprite = new CavernsTileSprite(wall);
      wallsSprite.tileImg && wallsSprite.txtPool.put(wallsSprite.tileImg);
      wallsSprite.tileImg = wallsSprite.txtPool.get();
      wallsSprite.tileImg.text = '#';
      wallsSprite.tileImg.scale = { x: 0.5, y: 0.5 };
      wallsSprite.tileImg.tint = 0x777777;
      wallsSprite.addChild(wallsSprite.tileImg);
      wallsSprite.interactive = wallsSprite.interactiveChildren = false;

      wallsSprite.snapToBoardPosition(wall.x, wall.y);
      this.addChild(wallsSprite);
      _wallSprites.push(wallsSprite);
    }
  };

  const onDoorSpriteMouseover = (event) => {
    try {
      const target = event.target ? event.target : event.currentTarget;

      if (target && target.tileImg) {
        target.scale.x = DOOR_SPRITE_SCALE.x * 1.25;
        target.scale.y = DOOR_SPRITE_SCALE.y * 1.25;
      }
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onDoorSpriteMouseout',
        event,
      });
    }
  };

  const onDoorSpriteMouseout = (event) => {
    try {
      const target = event.target ? event.target : event.currentTarget;

      if (target && target.tileImg) {
        target.scale = { ...DOOR_SPRITE_SCALE };
      }
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onDoorSpriteMouseout',
        event,
      });
    }
  };

  const onDoorSpriteClick = (event) => {
    CavernsActions.doorSpriteClick(event.target.gamePiece.direction);
  };

  const onCavernsTravelStarted = () => {
    // disable doors
    for (const ds of _doorSprites)
      if (ds) ds.interactive = ds.interactiveChildren = false;
    // slowly zoom out until the travel call is complete
    this.scale_buf = { x: this.scale.x, y: this.scale.y };
    _extro_interval = setInterval(() => {
      this.scale.x *= 0.995;
      this.scale.y *= 0.995;
    }, 33);
    // but handle the case that we just don't get a response from API (reset the view)
    setTimeout(() => {
      if (this.disposed) return;
      clearInterval(_extro_interval);
      TweenMax.to(this.scale, 0.5, {
        x: this.scale_buf.x,
        y: this.scale_buf.y,
      });
      for (const ds of _doorSprites || [])
        if (ds) ds.interactive = ds.interactiveChildren = true;
    }, 4000);
  };

  const onPortalSpriteClick = (event) => {
    CavernsActions.setPortalLevelToCurrentDepth();
    CanvasTools.makeFaerieSpinners(_portal_sprite);
  };

  const onStatCostsExacted = (data) => {
    try {
      updateCollections();
      _actionRenderer.renderAbility(data);
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onStatCostsExacted',
        data,
      });
    }
  };

  const onAbilityExecuted = (data) => {
    _actionRenderer.renderAbilityResult(data);
  };

  const onBattleEvent = (data) => {
    try {
      if (this.can_update) {
        battleState = CavernsStore.getAll().battleState;
      }
      if (!battleState) {
        return;
      }

      _actionRenderer.renderEvent(data.event);

      if (
        data.event.eventHandle === 'unit_spawned' &&
        data.event.unit.team === 'black'
      ) {
        // the player can't change rooms if there are any living mobs in the current room
        for (const ds of _doorSprites) {
          ds.highlight.tint = 0xff0000;
        }
      } else if (data.event.eventHandle === 'death') {
        TweenMax.delayedCall(0.1, () => {
          const victimId = data.event.victimId;
          delete _allPieceSprites[victimId];
          delete _unitSprites[victimId];
        });
      }

      updateCollections();
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onBattleEvent',
        data,
      });
    }
  };

  function onRoomIsClear() {
    // tint door highlights green
    for (const ds of _doorSprites) {
      ds.highlight.tint = 0x00ff00;
    }
  }

  const onUnitCondition = (condition) => {
    try {
      if (!battleState?.allUnits || !_unitSprites) {
        return;
      }

      let unit = battleState.allUnits[condition.ownerId];
      let unitSprite = _unitSprites[unit.uid];
      unitSprite.updateUnit(unit);

      _actionRenderer.renderCondition(condition);
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onUnitCondition',
        condition,
      });
    }
  };

  const onUnitConditionExpired = (data) => {
    _actionRenderer.stopRenderingCondition(data);
  };

  const onUnitState = (unit) => {
    try {
      const unitSprite = _unitSprites[unit.uid];
      unitSprite?.updateUnit(unit);
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onUnitState',
        unit,
      });
    }
  };

  const onTotalLootPickedUp = () => {
    if (this.can_update) {
      battleState = CavernsStore.getAll().battleState;
    }
    updateCollections();
  };

  CavernsStore.on(CavernsStore.STAT_COSTS_EXACTED, onStatCostsExacted);
  CavernsStore.on(CavernsStore.ABILITY_EXECUTED, onAbilityExecuted);
  CavernsStore.on(CavernsStore.BATTLE_EVENT, onBattleEvent);
  CavernsStore.on(CavernsStore.UNIT_CONDITION, onUnitCondition);
  CavernsStore.on(CavernsStore.UNIT_CONDITION_EXPIRED, onUnitConditionExpired);
  CavernsStore.on(CavernsStore.GOT_UNIT_STATE, onUnitState);
  CavernsStore.on(CavernsStore.TOTAL_LOOT_PICKED_UP, onTotalLootPickedUp);
  CavernsStore.on(CavernsStore.CAVERNS_TRAVEL_STARTED, onCavernsTravelStarted);
  CavernsStore.on(CavernsStore.ROOM_IS_CLEAR, onRoomIsClear);

  this.dispose = () => {
    this.disposed = true;
    this.stopUpdating();

    clearInterval(_extro_interval);

    for (const ps of Object.values(_allPieceSprites)) {
      ps.dispose();
    }
    _actionRenderer.dispose();
    _allPieceSprites = null;
    _tileSprites = null;
    _unitSprites = null;
    battleState = null;
    this.removeChildren();
    this.parent?.removeChild(this);

    for (const ds of _doorSprites) {
      ds.mouseover = ds.pointerover = ds.mouseout = ds.pointerout = null;
      ds?.removeChildren();
      ds?.highlight?.destroy();
      ds?.dispose();
    }
    _doorSprites = null;

    for (const ws of _wallSprites) {
      ws.dispose();
    }
    _wallSprites = null;

    if (_portal_sprite) {
      _portal_sprite.mouseover =
        _portal_sprite.pointerover =
        _portal_sprite.mouseout =
        _portal_sprite.pointerout =
          null;
      _portal_sprite.removeChildren();
      _portal_sprite.highlight?.destroy();
      _portal_sprite.dispose();
      _portal_sprite = null;
    }
  };

  this.stopUpdating = () => {
    this.can_update = false;
    CavernsStore.removeListener(
      CavernsStore.STAT_COSTS_EXACTED,
      onStatCostsExacted
    );
    CavernsStore.removeListener(
      CavernsStore.ABILITY_EXECUTED,
      onAbilityExecuted
    );
    CavernsStore.removeListener(CavernsStore.BATTLE_EVENT, onBattleEvent);
    CavernsStore.removeListener(CavernsStore.UNIT_CONDITION, onUnitCondition);
    CavernsStore.removeListener(
      CavernsStore.UNIT_CONDITION_EXPIRED,
      onUnitConditionExpired
    );
    CavernsStore.removeListener(CavernsStore.GOT_UNIT_STATE, onUnitState);
    CavernsStore.removeListener(
      CavernsStore.TOTAL_LOOT_PICKED_UP,
      onTotalLootPickedUp
    );
    CavernsStore.removeListener(
      CavernsStore.CAVERNS_TRAVEL_STARTED,
      onCavernsTravelStarted
    );
    CavernsStore.removeListener(CavernsStore.ROOM_IS_CLEAR, onRoomIsClear);
  };

  // init
  makeDoorSprites();
  makeWallSprites();
  updateCollections();
  makePortalSprite();
  this.pivot.x = (this.width / 2 - 20) / this.scale.x;
  this.pivot.y = (this.height / 2 - 32) / this.scale.y;
  // huge hit area for view panning
  this.hitArea = new PIXI.Circle(this.width / 2, this.height / 2, 5000);
  // render any extant loot drops
  for (const tile of Object.values(battleState.allTiles)) {
    const { loot, occupied, uid: tileId } = tile;
    if (loot) {
      _actionRenderer.renderEvent({
        eventHandle: 'loot_spawn',
        tileId,
        tile,
      });

      if (occupied && _tileSprites[tileId]?.lootImg) {
        _tileSprites[tileId].lootImg.visible = false;
      }
    }
  }
  // make sure we render any conditions that already exist in the battleState we constructed with
  for (let unitId in battleState.allUnits) {
    for (let conditionId in battleState.allUnits[unitId].conditions) {
      _actionRenderer.renderCondition(
        battleState.allUnits[unitId].conditions[conditionId]
      );
    }
  }
};
CavernsBattleView.prototype = Object.create(PIXI.Container.prototype);
CavernsBattleView.prototype.constructor = CavernsBattleView;
export default CavernsBattleView;
