<!-- eslint-disable-line vue/multi-word-component-names -->
<template>
  <InnAttackPrepView
    v-if="state.prepping_attack"
    @cancel="onAttackPrepCancelled"
  />
  <div
    v-else
    id="inn_root"
    class="flex pl-[50px] pr-[2vw] pt-[62px] pb-[3vh] bg-transparent"
  >
    <div
      id="inn_sidebar"
      class="relative w-[25vw] px-2 py-6 border border-zinc-500 rounded-sm"
    >
      <div
        id="peanuts_view_anchor"
        class="absolute -mx-3 -my-8 z-0 w-full h-full"
      ></div>

      <div
        v-if="state.pending_inn_state_data"
        class="w-full h-full flex justify-center items-center"
      >
        <DTSpinner />
      </div>
      <div
        v-else
        class="w-full h-full flex"
      >
        <InnTourneyProgressView
          class="-ml-10 w-6 h-full"
          :submode_state="state.submode_state"
        />
        <div
          class="grow h-full pl-5 flex flex-col justify-between items-center text-shadow shadow-black"
        >
          <div class="w-full text-center">
            <!-- tier title -->
            <div
              :class="{
                'font-bold underline no-select text-green-500': true,
                'text-xl': state.window_width > 1000,
                'text-base': state.window_width <= 1000,
              }"
            >
              {{ text(`ui.game_submodes.${state.current_game_submode}`) }}
              {{
                text(`ui.inn_tier_titles.${state.submode_state.current_tier}`)
              }}
            </div>

            <!-- rank view -->
            <div
              id="inn_rank_view"
              :class="{
                'text-yellow-500': true,
                'text-base': state.window_width > 1000,
                'text-sm': state.window_width <= 1000,
              }"
            >
              {{ text('ui.rank') }}: #{{ state.submode_state.current_rank }}
            </div>
          </div>

          <div class="my-4 text-center">
            <!-- peanut numbers view -->
            <div
              id="inn_peanuts_view"
              :class="{
                'grow text-center text-brown-200': true,
                'text-base': state.window_width > 1000,
                'text-sm': state.window_width <= 1000,
              }"
            >
              <div>
                {{ text('ui.peanuts') }}:
                {{ state.tickers.current_num_peanuts }}
              </div>
              <div>
                {{ text('ui.peanuts_per_second') }}: {{ getPPS().toFixed(0) }}
              </div>
            </div>

            <!-- time remaining view -->
            <div
              id="inn_time_remaining_view"
              class="font-bold text-blue-700 text-shadow shadow-black"
            >
              {{ text('ui.time_left') }}: {{ state.tickers.time_left }}
            </div>

            <!-- challenges remaining view -->
            <div
              id="inn_challenges_left_view"
              :class="{
                'font-bold text-red-700 text-shadow shadow-black': true,
                'text-2xl': state.window_width > 1000,
                'text-base': state.window_width <= 1000,
              }"
            >
              {{ text('ui.challenges_left') }}:
              {{ state.submode_state.challenges_left }}
            </div>
          </div>

          <!-- history menu button -->
          <!-- <DTButton
            id="inn_history_button"
            type="neutral"
            class="py-1 px-2 "
          >
            History
          </DTButton> -->
        </div>
      </div>
    </div>
    <div
      id="inn_body"
      class="grow bg-transparent flex flex-col"
    >
      <div
        v-if="state.submode_state?.current_rank === 1"
        class="grow flex justify-center items-center text-sm"
      >
        {{ text('ui.inn_rank_1_congrats') }}
      </div>
      <InnOpponentsView
        v-show="state.submode_state?.current_rank !== 1"
        class="grow"
      />
      <InnDefenseView class="h-[20vh]" />
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, onBeforeUnmount, reactive } from 'vue';
import Balance from 'dt-common/constants/Balance';
import Game from 'dt-common/constants/Game';
import Audio from '~/Audio';
import { InnActions } from '~/flux/actions';
import { InnStore, UIStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import { GameSubmodeSelector } from '~/view/components/common/canvas';
import { DTButton, DTSpinner } from '~/view/components/common/DOM';
import InnAttackPrepView from './InnAttackPrepView.vue';
import InnDefenseView from './InnDefenseView.vue';
import InnOpponentsView from './InnOpponentsView.vue';
import InnTourneyProgressView from './InnTourneyProgressView.vue';
import PeanutsView from '../canvas/PeanutsView';

const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

let _gameSubmodeSelector;
let _peanuts_view;
let _tickersInterval;

const state = reactive({
  current_game_submode: UIStore.getAll().current_game_submode,
  pending_inn_state_data: true,
  submode_state: null,
  tickers: {},
  window_width: window.innerWidth,
});

function getPPS() {
  return Math.floor(
    Balance.getInnPeanutsPerMSByRank(state.submode_state.current_rank) * 1000
  );
}

function calcTickers() {
  if (!state.submode_state) {
    return;
  }

  const {
    current_rank,
    last_num_peanuts,
    last_rank_change_at,
    tourney_entered_at,
  } = state.submode_state;
  const now = Date.now();

  // calc & rendernum peanuts
  const ms_elapsed_since_last_rank_change = now - last_rank_change_at;
  const num = Math.floor(
    last_num_peanuts +
      ms_elapsed_since_last_rank_change *
        Balance.getInnPeanutsPerMSByRank(current_rank)
  );
  state.tickers.current_num_peanuts = Tools.formatGameCurrency(num);

  // calc & render time left
  state.tickers.time_left = Tools.millisecondsToHHMMSS(
    tourney_entered_at + TWENTY_FOUR_HOURS - now
  );

  // calc peanuts / upgrade threshold
}

onMounted(() => {
  makeGameSubmodeSelector();
  InnActions.fetchPlayerInnSubmodeState({
    game_submode: UIStore.getAll().current_game_submode,
  });
  InnStore.on(InnStore.GOT_PLAYER_INN_SUBMODE_STATE, onGotSubmodeState);
  InnStore.on(InnStore.ATTACK_PREP_STARTED, onAttackPrepStarted);
  InnStore.on(InnStore.PASSIVE_TOURNEY_RESOLVED, onPassiveTourneyResolved);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);
  _tickersInterval = setInterval(calcTickers, 1000);

  Audio.setBGTrack('menu_music');

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
});

onBeforeUnmount(() => {
  destroyGameSubmodeSelector();
  destroyPeanutsView();
  InnStore.removeListener(
    InnStore.GOT_PLAYER_INN_SUBMODE_STATE,
    onGotSubmodeState
  );
  InnStore.removeListener(InnStore.ATTACK_PREP_STARTED, onAttackPrepStarted);
  InnStore.removeListener(
    InnStore.PASSIVE_TOURNEY_RESOLVED,
    onPassiveTourneyResolved
  );
  UIStore.removeListener(
    UIStore.GAME_SUBMODE_SELECTION,
    onGameSubmodeSelection
  );
  clearInterval(_tickersInterval);

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
});

const handleResize = Tools.debounce(() => {
  state.window_width = window.innerWidth;
  makeGameSubmodeSelector();
  makePeanutsView();
}, 300);

function destroyGameSubmodeSelector() {
  if (_gameSubmodeSelector) {
    DT_CANVAS_GLOBALS.stage.removeChild(_gameSubmodeSelector);
    _gameSubmodeSelector.dispose();
    _gameSubmodeSelector = null;
  }
}

function destroyPeanutsView() {
  _peanuts_view?.dispose();
  _peanuts_view = null;
}

function makeGameSubmodeSelector() {
  destroyGameSubmodeSelector();
  _gameSubmodeSelector = new GameSubmodeSelector();
  _gameSubmodeSelector.x =
    window.innerWidth / 2 - _gameSubmodeSelector.width / 2;
  _gameSubmodeSelector.y = 42;
  DT_CANVAS_GLOBALS.stage.addChild(_gameSubmodeSelector);
}

function makePeanutsView() {
  destroyPeanutsView();

  if (state.prepping_attack) {
    return;
  }

  const dom_anchor = document.getElementById('peanuts_view_anchor');
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();
  _peanuts_view = new PeanutsView({
    width,
    height,
    peanuts_canvas_ele: document.getElementById('peanuts_canvas'),
  });
  _peanuts_view.x = x;
  _peanuts_view.y = y;
  DT_CANVAS_GLOBALS.stage.addChild(_peanuts_view);
}

function onAttackPrepStarted() {
  state.prepping_attack = true;
  destroyPeanutsView();
  _gameSubmodeSelector.muteButtons();
}

function onAttackPrepCancelled() {
  state.prepping_attack = false;
  nextTick(() => {
    makeGameSubmodeSelector();
    makePeanutsView();
  }); // unmute buttons
}

function onGameSubmodeSelection() {
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  if (
    current_game_mode !== Game.GAME_MODES.GAME_MODE_pvpAuto ||
    !current_game_submode.includes('pvp')
  ) {
    return;
  }

  state.current_game_submode = current_game_submode;
  InnActions.fetchPlayerInnSubmodeState({
    game_submode: current_game_submode,
  });
}

function onGotSubmodeState({ player_inn_submode_state, tourney_instance }) {
  state.submode_state = player_inn_submode_state;
  state.tourney_instance = tourney_instance;
  state.pending_inn_state_data = false;

  nextTick(makePeanutsView);
}

function onPassiveTourneyResolved({ game_submode }) {
  game_submode === UIStore.getAll().current_game_submode &&
    InnActions.fetchPlayerInnSubmodeState({ game_submode });
}
</script>
