import TutorialDispatcher from '~/flux/dispatchers/TutorialDispatcher';
import {
  TutorialBox,
  TutorialSpotlight,
} from '~/view/components/common/canvas';
import { UIStore } from '~/flux/stores';
import Tools from '~/Tools';

let _tutorial_box;
let _tutorial_spotlight;
let _box_params_buf;
let _spotlight_params_buf;

const TutorialActions = {
  destroyTutorialBox(dispatch_action = true) {
    _tutorial_box?.dispose();
    _tutorial_box = null;

    if (dispatch_action)
      TutorialDispatcher.handleTutorialAction({
        actionType: TutorialDispatcher.DESTROY_TUTORIAL_BOX,
      });
  },

  destroyTutorialSpotlight(dispatch_action = true) {
    _tutorial_spotlight?.dispose();
    _tutorial_spotlight = null;

    if (dispatch_action)
      TutorialDispatcher.handleTutorialAction({
        actionType: TutorialDispatcher.TUTORIAL_SPOTLIGHT_HIDDEN,
      });
  },

  hideTutorialBox() {
    _tutorial_box &&
      TweenMax.to(_tutorial_box, 0.5, {
        alpha: 0,
        onComplete: (dead_box) => dead_box?.destroy(),
        onCompleteParams: [_tutorial_box],
      });
  },

  logTutorialStepCompleted(step_name, onboard_step) {
    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.LOG_TUTORIAL_STEP_COMPLETED,
      step_name,
      onboard_step,
    });
  },

  makeTutorialBox(params) {
    if (UIStore.getAll().showing_modal) {
      _box_params_buf = { ...params };
      return;
    }
    _box_params_buf = null;

    TutorialActions.destroyTutorialBox(false);

    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.MAKE_TUTORIAL_BOX,
    });

    const {
      text,
      arrow,
      width,
      x,
      y,
      do_spotlight_transition = true,
      onOkClick,
    } = params;

    // when there's an OK button, obscure the UI behind the box
    if (onOkClick)
      TutorialActions.makeTutorialSpotlight({
        radius: 0,
        x,
        y,
        do_spotlight_transition,
      });

    _tutorial_box = new TutorialBox(text, arrow, width, onOkClick);
    _tutorial_box.params = params;
    _tutorial_box.x = Math.round(x);
    _tutorial_box.y = Math.round(y);
    TweenMax.from(_tutorial_box, 0.35, { alpha: 0 });
    DT_CANVAS_GLOBALS.overlay_stage.addChild(_tutorial_box);
    return _tutorial_box;
  },

  makeTutorialSpotlight(params) {
    if (UIStore.getAll().showing_modal) {
      _spotlight_params_buf = { ...params };
      return;
    }
    _spotlight_params_buf = null;

    const {
      radius,
      x,
      y,
      onTransitionInComplete,
      do_spotlight_transition = true,
    } = params;

    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.TUTORIAL_SPOTLIGHT_VISIBLE,
    });

    this.destroyTutorialSpotlight(false);
    _tutorial_spotlight = new TutorialSpotlight(radius);
    _tutorial_spotlight.params = params;
    _tutorial_spotlight.x = x;
    _tutorial_spotlight.y = y;
    DT_CANVAS_GLOBALS.overlay_stage.addChild(_tutorial_spotlight);
    if (do_spotlight_transition)
      _tutorial_spotlight.transitionIn(onTransitionInComplete);
    else if (onTransitionInComplete) onTransitionInComplete();
    return _tutorial_spotlight;
  },

  skipTutorial() {
    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.SKIP_TUTORIAL,
    });
  },

  startTutorialBattle01() {
    TutorialDispatcher.handleTutorialAction({
      actionType: TutorialDispatcher.START_TUTORIAL_BATTLE_01,
    });
  },
};
export default TutorialActions;

UIStore.on(
  UIStore.SHOW_MODAL,
  Tools.debounce(() => {
    if (_tutorial_box) _box_params_buf = _tutorial_box.params;
    if (_tutorial_spotlight) _spotlight_params_buf = _tutorial_spotlight.params;
    TutorialActions.destroyTutorialBox();
    TutorialActions.destroyTutorialSpotlight();
  }, 50)
);

UIStore.on(UIStore.MODAL_CLOSED, () => {
  _box_params_buf && TutorialActions.makeTutorialBox(_box_params_buf);
  _spotlight_params_buf &&
    TutorialActions.makeTutorialSpotlight(_spotlight_params_buf);
});
